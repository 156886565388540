import { Col, Flex, Tabs, TabsProps, Typography } from 'antd';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { CompatibilitiesWidget } from 'widgets/compatibilities';
import { Transactions } from 'widgets/Transactions';
import { DeleteClassifierRecord } from 'features/classifiers/ClassifierRecords/DeleteClassifierRecord';
import { EditClassifierRecord } from 'features/classifiers/ClassifierRecords/EditClassifierRecord';
import { clearBreadcrumbs, setBreadcrumbs } from 'entities/breadcrumbs';
import { routes } from 'shared/config';
import { useAppDispatch, useEnumStateViaSearchParam, useTypedTranslation } from 'shared/hooks';
import { DetailFooter } from 'shared/ui';

enum ClassifierRecordDetailPageTab {
	Values = 'Values',
	Compatibilities = 'Compatibilities',
}

export const RecordDetailPageUi: React.FC = () => {
	const { t } = useTypedTranslation();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const [ activeTab, setActiveTab ] = useEnumStateViaSearchParam(
		ClassifierRecordDetailPageTab,
		'tab',
		ClassifierRecordDetailPageTab.Values,
	);

	const { classifierItemId, classifierGroupId } = useParams();

	const items: TabsProps['items'] = [
		{
			key: ClassifierRecordDetailPageTab.Values,
			label: t((l) => l.attributes.attributesTabTitle),
			children: <EditClassifierRecord/>,
		},
		{
			key: ClassifierRecordDetailPageTab.Compatibilities,
			label: t((l) => l.compatibilities.tabTitle),
			children: (
				<CompatibilitiesWidget entityId={classifierItemId}
									   entityType="CLASSIFIER_ITEM"
				/>
			),
		},
	];

	useEffect(() => {
		dispatch(
			setBreadcrumbs({
				displayName: t((l) => l.classifier.common.classifiersList),
				url: `${routes.classifiers.main}`,
				child: {
					displayName: t((l) => l.classifier.common.classifierFilling),
					url: `${routes.classifiers.main}/${classifierGroupId}/${routes.classifiers.records}`,
					child: {
						displayName: t((l) => l.classifier.common.editRecord),
						url: `${routes.classifiers.main}/${classifierGroupId}/${routes.classifiers.records}/${classifierItemId}/${routes}`,
					},
				},
			}),
		);
	}, [ classifierItemId, classifierGroupId ]);

	useEffect(() => {
		return () => {
			dispatch(clearBreadcrumbs());
		};
	}, []);

	const handleBackClick = () => {
		navigate(`/${routes.classifiers.main}/${classifierGroupId}/${routes.classifiers.records}`);
	};

	return (
		<>
			<Flex vertical gap={12} style={{ marginBottom: 65 }}>
				<Flex justify="space-between">
					<Flex vertical gap={12} justify="start">
						<Typography.Title level={1}>
							{t((l) => l.classifier.common.recordCard)}
						</Typography.Title>
					</Flex>

					<Transactions/>
				</Flex>
				<Col xl={18} xs={24} sm={24} md={24} lg={18}>
					<Tabs
						items={items}
						destroyInactiveTabPane
						defaultActiveKey={activeTab}
						activeKey={activeTab}
						onChange={(key) => setActiveTab(key as ClassifierRecordDetailPageTab)}
					/>
				</Col>
			</Flex>

			<DetailFooter customHandleBack={handleBackClick}>
				<DeleteClassifierRecord recordId={classifierItemId} isButton/>
			</DetailFooter>
		</>
	);
};

export const ClassifierRecordDetailPage = React.memo(RecordDetailPageUi);
