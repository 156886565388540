import { RiAddCircleLine } from '@remixicon/react';
import { Button, Flex, Modal } from 'antd';
import React, { memo, useCallback, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { useCreateContextNameMutation } from 'shared/api/generatedApi/mdmgApi';
import { routes } from 'shared/config';
import { useTypedTranslation } from 'shared/hooks';
import { EditContextNameForm, EditContextNameFormPublicApi } from '../EditContextNameForm';

const CreateContextName = memo(() => {
	const { t } = useTypedTranslation();
	const navigate = useNavigate();

	const formRef = useRef<EditContextNameFormPublicApi>();
	const [ isModalOpen, setIsModalOpen ] = useState(false);

	const [ createContextName, { isLoading } ] = useCreateContextNameMutation();

	const handleOk = useCallback(async () => {
		const form = formRef.current.getFormData();
		const contextName = await createContextName({
			createContextNameRequest: {
				...form,
			},
		}).unwrap();
		navigate(`/${routes.metadata.main}/${routes.metadata.compatibilities}/${contextName.id}`);
	}, []);

	const closeModal = useCallback(() => setIsModalOpen(false), []);
	const openModal = useCallback(() => setIsModalOpen(true), []);

	const footerButtons = useMemo(() => (
			<Flex key="footer" justify="flex-end" gap={4}>
				<Button type="text"
						onClick={closeModal}
				>
					{t((l) => l.common.buttons.cancel)}
				</Button>

				<Button type="primary"
						loading={isLoading}
						onClick={handleOk}
				>
					{t((l) => l.common.buttons.create)}
				</Button>
			</Flex>
		),
		[ isLoading ],
	);

	return (
		<>
			<Button
				type="default"
				icon={<RiAddCircleLine/>}
				onClick={openModal}
			>
				{t((l) => l.compatibilities.contextNames.create)}
			</Button>

			<Modal
				title="Контекстная связь"
				open={isModalOpen}
				onOk={handleOk}
				onCancel={closeModal}
				footer={[ footerButtons ]}
				closeIcon={null}
				width={600}
			>
				<Flex vertical gap={24}>
					<EditContextNameForm ref={formRef}/>
				</Flex>
			</Modal>
		</>
	);
});

export {
	CreateContextName,
};