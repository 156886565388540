import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Flex, Form } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { CompatibilityConditionAttributes } from 'entities/compatibilities';
import { useTypedTranslation } from 'shared/hooks';
import { DropdownSelect } from 'shared/ui';
import {
	CompatibilityCondition,
} from '../CompatibilityCondition';

interface CompatibilityConditionGroupProps {
	root?: boolean | null;
	formItemPath?: Array<string | number>;
	firstAttributes: CompatibilityConditionAttributes[];
	secondAttributes: CompatibilityConditionAttributes[];
	onGroupEmpty?: () => void;
}

interface CompatibilityCondition {
	type: 'simple';
	firstDeclarationId?: string | null;
	secondDeclarationId?: string | null;
	condition?: string;
	logicalOperator?: 'AND' | 'OR';
}

interface CompatibilityConditionGroup {
	type: 'group';
	items: Array<CompatibilityConditionFormItem>;
	logicalOperator?: 'AND' | 'OR';
}

export type CompatibilityConditionFormItem = CompatibilityCondition | CompatibilityConditionGroup;

const CompatibilityConditionGroup = (
	{
		root,
		formItemPath = [],
		firstAttributes,
		secondAttributes,
		onGroupEmpty,
	}: CompatibilityConditionGroupProps) => {

	const { t } = useTypedTranslation();

	const formListName = formItemPath.length > 0
		? [ formItemPath[formItemPath.length - 1], 'items' ]
		: 'items';

	const conditionOptions = useMemo(() => [
		{
			label: t(l => l.common.filters.and).toUpperCase(),
			value: 'AND',
		},
		{
			label: t(l => l.common.filters.or).toUpperCase(),
			value: 'OR',
		},
	], [ t ]);

	const controlButtons = useCallback((add) => <Flex>
		<Button type="text"
				icon={<PlusCircleOutlined/>}
				onClick={() => add({ type: 'simple' })}
		>
			Добавить условие
		</Button>

		<Button type="text"
				icon={<PlusCircleOutlined/>}
				onClick={() => add({ type: 'group', items: [ { type: 'simple' } ] })}
		>
			Добавить группу условий
		</Button>
	</Flex>, []);

	return (
		<div style={!root ? { borderLeft: '2px solid #4760CB', paddingLeft: 16 } : {}}>
			<Form.List name={formListName}>
				{(fields, { add, remove }) => (
					<Flex vertical={true} gap={10}>

						{root && controlButtons(add)}

						{fields.map(({ key, name }, index) => {
							return (
								<Flex key={key} vertical gap={24}>
									<Form.Item noStyle shouldUpdate>
										{({ getFieldValue }) => {
											return (getFieldValue([ ...formItemPath, 'items', name, 'type' ]) === 'group' ? (
												<CompatibilityConditionGroup
													formItemPath={[ ...formItemPath, 'items', name ]}
													firstAttributes={firstAttributes}
													secondAttributes={secondAttributes}
													onGroupEmpty={() => remove(name)}
												/>
											) : (
												<Flex key={key}
													  align="baseline"
													  gap={8}
													  style={root ? {
														  borderLeft: '2px solid #E97B2D',
														  paddingLeft: 16,
														  flex: 1,
													  } : {}}
												>
													<CompatibilityCondition
														formItemPath={[ ...formItemPath, 'items', name ]}
														firstAttributes={firstAttributes}
														secondAttributes={secondAttributes}
													/>

													<Button type="text"
															icon={<DeleteOutlined/>}
															onClick={() => {
																remove(name);
																if (!(getFieldValue([ ...formItemPath, 'items' ]) as Array<any>).length) {
																	onGroupEmpty?.();
																}
															}}
													/>
												</Flex>
											));
										}}
									</Form.Item>
									{index !== fields.length - 1 && (
										<div style={{ width: 80 }}>
											<Form.Item name={[ name, 'logicalOperator' ]}>
												<DropdownSelect options={conditionOptions}/>
											</Form.Item>
										</div>
									)}
								</Flex>
							);
						})}

						{!root && controlButtons(add)}

					</Flex>
				)}
			</Form.List>
		</div>
	);
};

export {
	CompatibilityConditionGroup,
};