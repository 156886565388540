import { Result } from 'antd';
import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import 'shared/styles/index.scss';
import AdminPage from 'pages/AdminPage';
import {
	ApplicationPageTab,
	ApplicationsMainPage,
	tabSearchParamKey as ApplicationMainPageTabSearchParamKey,
} from 'pages/applications/MainPage';
import { ApplicationTemplatePage } from 'pages/applications/TemplatePage';
import { CatalogGroupPage } from 'pages/CatalogPage/CatalogGroupPage';
import { DuplicatesPage } from 'pages/CatalogPage/DuplicatesPage';
import { CatalogPage } from 'pages/CatalogPage/MainPage';
import { RecordDetailPage } from 'pages/CatalogPage/RecordDetailPage';
import { ClassifierGroupPage } from 'pages/ClassifierPage/ClassifierGroupPage';
import { ClassifierListPage } from 'pages/ClassifierPage/ClassifierListPage';
import { ClassifierPage } from 'pages/ClassifierPage/MainPage';
import { ClassifierRecordDetailPage } from 'pages/ClassifierPage/RecordDetailPage';
import { CreateCompatibilityPage, CompatibilityPage } from 'pages/compatibilities';
import { DataQualityPage } from 'pages/DataQualityPage';
import {
	MessagePage,
	IntegrationsPage,
	IntegrationDetailPage,
	ExchangeConfigurationDetailPage,
	CreateNewIntegrationPage,
	CreateExchangeConfigurationPage,
} from 'pages/integrations';
import { AttributeDetailPage } from 'pages/MetadataPage/AttributeDetailPage';
import { ConstraintTableCardPage } from 'pages/MetadataPage/ConstraintTableCardPage';
import { ConstraintTableRowPage } from 'pages/MetadataPage/ConstraintTableRowPage';
import { ContextNameCardPage } from 'pages/MetadataPage/ContextNameCardPage';
import { CreateAttributePage } from 'pages/MetadataPage/CreateAttributePage';
import { DetailMeasureGroupPage } from 'pages/MetadataPage/DetailMeasureGroupPage';
import { DetailMeasureUnitPage } from 'pages/MetadataPage/DetailMeasureUnitPage';
import { MetadataPage } from 'pages/MetadataPage/MainPage';
import SettingPage from 'pages/SettingsPage';
import { routes } from 'shared/config/navigation/routes';
import PageLayout from '../layouts/PageLayout/PageLayout';

export const AppRouter = () => (
	<BrowserRouter>
		<Routes>
			<Route path="/" element={<PageLayout/>}>
				<Route index element={<Navigate to={`${routes.catalogs.main}`}/>}/>
				<Route
					path={routes.main.main}
					element={<Navigate to={`${routes.catalogs.main}`}/>}
				/>

				{/*		Catalogs	*/}
				<Route path={routes.catalogs.main} element={<CatalogPage/>}/>
				<Route path={`${routes.catalogs.main}/:catalogGroupId`} element={<CatalogPage/>}/>
				<Route
					path={`${routes.catalogs.main}/:catalogGroupId/edit`}
					element={<CatalogGroupPage/>}
				/>
				<Route
					path={`${routes.catalogs.main}/:catalogGroupId/record/:recordId`}
					element={<RecordDetailPage/>}
				/>
				<Route
					path={`${routes.catalogs.main}/:catalogGroupId/${routes.catalogs.deduplication}/:deduplicationOptionId`}
					element={<DuplicatesPage/>}
				/>

				{/*		Compatibility	*/}
				<Route
					path={`${routes.compatibility.main}/${routes.compatibility.create}/:entityType/:entityId`}
					element={<CreateCompatibilityPage/>}
				/>
				<Route path={`${routes.compatibilities.main}/:compatibilityId`}
					   element={<CompatibilityPage/>}
				/>

				{/*		Metadata	*/}
				<Route path={routes.metadata.main} element={<MetadataPage/>}/>
				<Route
					path={`${routes.metadata.main}/${routes.metadata.group}`}
					element={<MetadataPage/>}
				/>
				<Route
					path={`${routes.metadata.main}/${routes.metadata.group}/:metaAttributeGroupId`}
					element={<MetadataPage/>}
				/>
				<Route
					path={`${routes.metadata.main}/${routes.metadata.group}/:attributeGroupId/${routes.metadata.attribute}/${routes.metadata.create}`}
					element={<CreateAttributePage/>}
				/>
				<Route
					path={`${routes.metadata.main}/${routes.metadata.group}/:metaAttributeGroupId/${routes.metadata.attribute}/:metaAttributeId`}
					element={<AttributeDetailPage/>}
				/>
				<Route path={`${routes.metadata.main}/measures`} element={<MetadataPage/>}/>
				<Route
					path={`${routes.metadata.main}/measures/:metaMeasureGroupId`}
					element={<DetailMeasureGroupPage/>}
				/>
				<Route
					path={`${routes.metadata.main}/${routes.metadata.measures}/:metaMeasureGroupId/unit/:metaMeasureUnitId`}
					element={<DetailMeasureUnitPage/>}
				/>
				<Route
					path={`${routes.metadata.main}/${routes.metadata.constraintTables}`}
					element={<MetadataPage/>}
				/>
				<Route
					path={`${routes.metadata.main}/${routes.metadata.constraintTables}/:constraintTableId`}
					element={<ConstraintTableCardPage/>}
				/>
				<Route
					path={`${routes.metadata.main}/${routes.metadata.constraintTables}/:constraintTableId/:constraintTableRowId`}
					element={<ConstraintTableRowPage/>}
				/>
				<Route
					path={`${routes.metadata.main}/${routes.metadata.compatibilities}`}
					element={<MetadataPage/>}
				/>
				<Route
					path={`${routes.metadata.main}/${routes.metadata.compatibilities}/:contextNameId`}
					element={<ContextNameCardPage/>}
				/>

				{/*		Classifiers	*/}
				<Route path={`${routes.classifiers.main}`} element={<ClassifierPage/>}/>
				<Route
					path={`${routes.classifiers.main}/:classifierGroupId`}
					element={<ClassifierGroupPage/>}
				/>
				<Route
					path={`${routes.classifiers.main}/:classifierGroupId/${routes.classifiers.records}`}
					element={<ClassifierListPage/>}
				/>
				<Route
					path={`${routes.classifiers.main}/:classifierGroupId/${routes.classifiers.records}/:classifierItemId`}
					element={<ClassifierListPage/>}
				/>
				<Route
					path={`${routes.classifiers.main}/:classifierGroupId/${routes.classifiers.records}/:classifierItemId/${routes.classifiers.edit}`}
					element={<ClassifierRecordDetailPage/>}
				/>

				{/*		Applications	*/}
				<Route path={`${routes.applications.main}`} element={<ApplicationsMainPage/>}/>
				<Route
					path={`${routes.applications.main}/templates`}
					element={
						<Navigate
							to={`/${routes.applications.main}?${ApplicationMainPageTabSearchParamKey}=${ApplicationPageTab.Templates}`}
							replace
						/>
					}
				/>
				<Route
					path={`${routes.applications.main}/templates/:templateId`}
					element={<ApplicationTemplatePage/>}
				/>

				{/*		Data quality	*/}
				<Route path={`${routes.dataQuality.main}`} element={<DataQualityPage/>}/>

				{/*		Admin	*/}
				<Route path={routes.admin.main} element={<AdminPage/>}/>
				<Route
					path={`${routes.admin.main}/${routes.admin.groups}`}
					element={<AdminPage/>}
				/>
				<Route
					path={`${routes.admin.main}/${routes.admin.users}`}
					element={<AdminPage/>}
				/>
				<Route
					path={`${routes.admin.main}/${routes.admin.journal}`}
					element={<AdminPage/>}
				/>

				{/* 	Integrations	*/}
				<Route path={routes.integrations.main} element={<IntegrationsPage/>}/>
				<Route
					path={`${routes.integrations.main}/${routes.integrations.create}`}
					element={<CreateNewIntegrationPage/>}
				/>
				<Route
					path={`${routes.integrations.main}/${routes.integrations.create}/:integrationId`}
					element={<CreateNewIntegrationPage/>}
				/>
				<Route
					path={`${routes.integrations.main}/${routes.integrations.create}/:integrationId/${routes.integrations.exchangeConfigs}/:exchangeConfigId`}
					element={<CreateNewIntegrationPage/>}
				/>
				<Route
					path={`${routes.integrations.main}/${routes.integrations.detail}/:integrationId/${routes.integrations.exchangeConfigs}/:exchangeConfigId`}
					element={<ExchangeConfigurationDetailPage/>}
				/>
				<Route
					path={`${routes.integrations.main}/${routes.integrations.detail}/:integrationId/${routes.integrations.exchangeConfigs}`}
					element={<CreateExchangeConfigurationPage/>}
				/>
				<Route
					path={`${routes.integrations.main}/${routes.integrations.detail}/:integrationId/${routes.integrations.message}/:messageId`}
					element={<MessagePage/>}
				/>
				<Route
					path={`${routes.integrations.main}/${routes.integrations.detail}/:integrationId`}
					element={<IntegrationDetailPage/>}
				/>

				{/*		Settings	*/}
				<Route path={routes.settings.main} element={<SettingPage/>}/>
				<Route
					path={`${routes.settings.main}/${routes.settings.basic}`}
					element={<SettingPage/>}
				/>
				<Route
					path={`${routes.settings.main}/${routes.settings.integration}`}
					element={<SettingPage/>}
				/>
				<Route
					path={`${routes.settings.main}/${routes.settings.debug}`}
					element={<SettingPage/>}
				/>
				<Route
					path={`${routes.settings.main}/${routes.settings.plugins}`}
					element={<SettingPage/>}
				/>
				<Route
					path={`${routes.settings.main}/${routes.settings.db}`}
					element={<SettingPage/>}
				/>
				<Route
					path={`${routes.settings.main}/${routes.settings.utilities}`}
					element={<SettingPage/>}
				/>
				<Route
					path={`${routes.settings.main}/${routes.settings.test}`}
					element={<SettingPage/>}
				/>
				<Route
					path={`${routes.settings.main}/${routes.settings.gen}`}
					element={<SettingPage/>}
				/>

				{/*		Error	*/}
				<Route
					path="*"
					element={<Result status="404" title="Error!" subTitle="404 Page not found"/>}
				/>
			</Route>
		</Routes>
	</BrowserRouter>
);
