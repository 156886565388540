import { App, Spin } from 'antd';
import React from 'react';
import { ExchangeConfigForm } from 'entities/integrations';
import { useCreateConfigurationMutation } from 'shared/api/generatedApi/integrationsApi';
import { errorHelper } from 'shared/helpers';

interface CreateExchangeConfigProps {
	integrationId: string;
	onCreate?: (id: string) => void;
}

const CreateExchangeConfig = (
	{
		integrationId,
		onCreate,
	}: CreateExchangeConfigProps,
) => {
	const { notification } = App.useApp();

	const [ createConfig, { isLoading } ] = useCreateConfigurationMutation();

	const onSubmit = async (state) => {
		try {
			const { configuration_id: configId } = await createConfig({
				connectionId: integrationId,
				configurationCreateRequest: {
					name: state.name,
					description: state.description,
					status: state.status,
					execution_type: state.execution_type,
					schedule_interval: state.schedule_interval,
					on_delete_action: state.on_delete_action,
					extraction_targets: state.extraction_targets,
					destination: state.destination,
					replication_direction: state.replication_direction,
				},
			})
				.unwrap();

			onCreate?.(configId);
		} catch (e) {
			errorHelper(
				'Ошибка при создании конфигурации обмена!',
				e,
				notification,
			);
		}
	};

	return (
		<Spin spinning={isLoading}>
			<ExchangeConfigForm integrationId={integrationId}
								onSubmit={onSubmit}
			/>
		</Spin>
	);
};

export {
	CreateExchangeConfig,
};