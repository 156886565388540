import { RiEdit2Line } from '@remixicon/react';
import { Flex } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { AddNewCatalog } from 'features/catalogs/AddNewCatalog';
import { DeleteCatalog } from 'features/catalogs/DeleteCatalog';
import { DisplayTreeNode, useCatalogGroups } from 'entities/catalogs/catalogGroups/catalog.model';
import { setCatalogGroups } from 'entities/catalogs/catalogGroups/catalog.store';
import { CatalogResponse, CatalogTreeNodeResponse } from 'shared/api/generatedApi/mdmgApi';
import { routes } from 'shared/config';
import { truncateString } from 'shared/helpers';
import { findCatalogById } from 'shared/helpers/findCatalogById';
import { CatalogItem } from 'shared/helpers/types';
import { useAppDispatch, useAppSelector, useTypedTranslation } from 'shared/hooks';
import { DropdownLink, AddNewNestedGroup } from 'shared/ui';
import ItemActions from 'shared/ui/components/ItemActions';

export const useCatalogGroupTree = () => {
	const { t } = useTypedTranslation();

	const [selectedTreeItem, setSelectedTreeItem] = useState<DisplayTreeNode | null>(null);
	const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);

	const { catalogList, selectedCatalog, getCatalogs, getGroups, error, loading } =
		useCatalogGroups();
	const { catalogGroupId } = useParams();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [searchParams] = useSearchParams();

	const { transactionRollback } = useAppSelector((state) => state.entities.transactions);

	const addToExpanded = (addedKeys: Array<React.Key>) => {
		const keys = new Set<React.Key>(expandedKeys);
		addedKeys.forEach((key) => keys.add(key));
		setExpandedKeys(Array.from(keys));
	};

	const handleExpand = (_, { expanded: isExpanded, node }) => {
		const catalog = node as CatalogTreeNodeResponse;

		if (isExpanded) {
			addToExpanded([catalog.id as React.Key]);
		} else {
			setExpandedKeys(expandedKeys.filter((key) => key !== catalog.id));
		}
	};

	const formatToTree = (data: Array<CatalogResponse | CatalogTreeNodeResponse>) =>
		data?.map((catalog) => ({
			...catalog,
			key: catalog.id,
			title: truncateString(catalog?.displayName, 30),
			isLeaf: !catalog.parent,
			...(catalog.parent &&
				'children' in catalog && {
				children: formatToTree(catalog.children),
			}),
		}));

	const selectCurrentCatalog = (catalog: DisplayTreeNode | null) => {
		const searchValue = searchParams.get('searchParentValue');
		if (catalog) {
			navigate(
				`/${routes.catalogs.main}/${catalog.id}?${searchValue ? `searchParentValue=${searchValue}` : ''}`
			);
			const foundItem = findCatalogById(formatToTree(catalogList), catalog.id);
			setSelectedTreeItem(foundItem);
		} else {
			navigate(
				`/${routes.catalogs.main}?${searchValue ? `searchParentValue=${searchValue}` : ''}`
			);
			setSelectedTreeItem(null);
		}
	};

	const addMenuToTreeNodeItems = (arr: Array<CatalogItem>) => {
		return arr.map((item) => ({
			...item,
			title: (
				<Flex justify="space-between" align="center" style={{ width: '100%' }}>
					{truncateString(item?.displayName, 30)}

					<ItemActions
						items={[
							{
								label: <AddNewCatalog parentId={item.id}
									isNested
									isDropdown
									icon={<AddNewNestedGroup size={16} />}
									title={t(l => l.catalogs.groups.createNestedGroup)} />,
								key: '0',
							},
							{
								label: (
									<DropdownLink
										icon={<RiEdit2Line size={16} />}
										title={t((l) => l.common.buttons.edit)}
										onClick={() =>
											navigate(`/${routes.catalogs.main}/${item.id}/edit`)
										}
									/>
								),
								key: '1',
							},
							{
								label: <DeleteCatalog id={item.id} />,
								key: '2',
							},
						]}
					/>
				</Flex>
			),
			...(item.children && { children: addMenuToTreeNodeItems(item.children) }),
		}));
	};

	const findExpandedKeys = (treeData, targetKey: string): React.Key[] => {
		let keys: React.Key[] = [];

		const traverse = (nodes, parentKeys = []) => {
			for (const node of nodes) {
				const currentKeys = [...parentKeys, node.key];
				if (node.key === targetKey) {
					keys = currentKeys;
					return true;
				}
				if (node.children && traverse(node.children, currentKeys)) {
					return true;
				}
			}
			return false;
		};

		traverse(treeData);
		return keys;
	};

	const findExpandedSearchKeys = (treeData) => {
		const keys = [];

		const traverse = (nodes) => {
			for (const node of nodes) {
				if (node.children) {
					keys.push(node.key);
					traverse(node.children);
				}
			}
			return false;
		};

		traverse(treeData);
		return keys;
	};

	const onExpandTree = async (parentId: string, hasChildren: boolean = false) => {
		if (hasChildren) return;
		await getCatalogs(parentId);
	};

	useEffect(() => {
		if (catalogList?.length) {
			const formattedTree = formatToTree(catalogList);
			if (searchParams.get('searchParentValue')) {
				addToExpanded(findExpandedSearchKeys(formattedTree));
				selectedCatalog && setSelectedTreeItem(formatToTree([selectedCatalog])[0]);
			} else if (selectedCatalog) {
				setSelectedTreeItem(formatToTree([selectedCatalog])[0]);
				addToExpanded(findExpandedKeys(formattedTree, selectedCatalog.id));
			}
		}
	}, [catalogList]);

	useEffect(() => {
		if (!catalogGroupId) {
			setSelectedTreeItem(null);
		}
	}, [catalogGroupId, selectedTreeItem]);

	useEffect(() => {
		if (catalogGroupId) {
			const foundItem = findCatalogById(formatToTree(catalogList), catalogGroupId);
			setSelectedTreeItem(foundItem);

			const formattedTree = formatToTree(catalogList);
			addToExpanded(findExpandedKeys(formattedTree, catalogGroupId));
		}
	}, [catalogGroupId]);

	useEffect(() => {
		return () => {
			dispatch(setCatalogGroups(null));
		};
	}, []);

	useEffect(() => {
		getGroups();
	}, [transactionRollback]);

	return {
		selectedTreeItem,
		selectCurrentCatalog,
		onExpandTree,
		loading,
		error,
		formatToTree,
		addMenuToTreeNodeItems,
		catalogList,
		expandedKeys,
		handleExpand,
	};
};
