import { RiArrowUpDownFill } from '@remixicon/react';
import { Flex, Skeleton, Typography, Result } from 'antd';
import React, { memo } from 'react';
import { useGetCompatibilitiesQuery } from 'shared/api/generatedApi/mdmgApi';
import { useTypedTranslation } from 'shared/hooks';
import { colors } from 'shared/styles';
import { Chip } from 'shared/ui';
import { CompatibilityUrl } from '../CompatibilityUrl';

interface UsingContextNameProps {
	contextNameId: string;
}

const UsingContextName = memo(({ contextNameId }: UsingContextNameProps) => {

	const { t } = useTypedTranslation();

	const {
		data: usingCompatibilityList,
		isLoading: usingCompatibilityListLoading,
		error: usingCompatibilityListError,
	} = useGetCompatibilitiesQuery({
		contextNameId: contextNameId,
	});

	return (
		<Flex vertical gap={12}>
			<Typography.Title>
				{t((l) => l.compatibilities.contextNames.usingContextName)}
			</Typography.Title>
			<Flex gap={4} wrap="wrap">
				{usingCompatibilityListLoading && (
					<Skeleton/>
				)}
				{!usingCompatibilityListLoading && usingCompatibilityListError && (
					<Result
						status="error"
						title={t((l) => l.compatibilities.error.usingCompatibilityListError)}
						subTitle={JSON.stringify(usingCompatibilityListError)}
					/>
				)}
				{!usingCompatibilityListLoading && !usingCompatibilityListError && usingCompatibilityList?.length == 0 && (
					<Result status="info"
							title="Данная контекстная связь не используется"
							style={{ width: '100%' }}
					/>
				)}
				{usingCompatibilityList?.length > 0 &&
					usingCompatibilityList.map((item) => (
						<Chip color={colors.primaryBg}
							  icon={null}
							  key={item.id}
							  style={{ color: colors.primary }}
						>
							<CompatibilityUrl id={item?.firstEntity.id}
											  type={item?.firstEntity.type}
											  title={item?.firstEntity.displayName}
							/>
							<RiArrowUpDownFill size={16}
											   style={{ transform: 'rotate(270deg)' }}
											   color={`${colors.warning}`}
							/>
							<CompatibilityUrl id={item?.secondEntity.id}
											  type={item?.secondEntity.type}
											  title={item?.secondEntity.displayName}
							/>
						</Chip>

					))}
			</Flex>
		</Flex>
	);
});

export {
	UsingContextName,
};