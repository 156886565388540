import { CompatibilityConditionAttributes } from 'entities/compatibilities';
import {
	CompatibilityElementResponse,
	useGetCatalogAttributeDeclarationsQuery,
	useGetCatalogItemQuery,
	useGetClassifierAttributeDeclarationsQuery,
	useGetClassifierItemQuery,
} from 'shared/api/generatedApi/mdmgApi';

interface GetEntityAttributeDeclarationsHookProps {
	entity?: CompatibilityElementResponse | null,
	skip: boolean,
}

interface GetEntityAttributeDeclarationsHookState {
	data: CompatibilityConditionAttributes[] | null;
	isFetching: boolean;
}

type GetEntityAttributeDeclarationsHook = (props: GetEntityAttributeDeclarationsHookProps) => GetEntityAttributeDeclarationsHookState;

export const useGetEntityAttributeDeclarations: GetEntityAttributeDeclarationsHook = (
	{
		entity,
		skip,
	}) => {
	const { data: catalogItem } = useGetCatalogItemQuery({
		id: entity?.id,
	}, {
		skip: skip || entity?.type !== 'CATALOG_ITEM',
	});

	const {
		data: catalogAttributeDeclarations,
		isFetching: isCatalogAttributeDeclarationsFetching,
	} = useGetCatalogAttributeDeclarationsQuery({
		catalogId: entity?.type === 'CATALOG'
			? entity?.id
			: catalogItem?.catalogId,
	}, {
		skip: skip || !(entity?.type == 'CATALOG' || (entity?.type == 'CATALOG_ITEM' && catalogItem?.catalogId)),
	});

	const { data: classifierItem } = useGetClassifierItemQuery({
		id: entity?.id,
	}, {
		skip: skip || entity?.type !== 'CLASSIFIER_ITEM',
	});

	const {
		data: classifierAttributeDeclarations,
		isFetching: isClassifierAttributeDeclarationsFetching,
	} = useGetClassifierAttributeDeclarationsQuery({
		classifierId: entity?.type === 'CLASSIFIER'
			? entity?.id
			: classifierItem?.classifierId,
	}, {
		skip: skip || !(entity?.type == 'CLASSIFIER' || (entity?.type == 'CLASSIFIER_ITEM' && classifierItem?.classifierId)),
	});

	return ((entity?.type === 'CATALOG' || entity?.type === 'CATALOG_ITEM') ? {
		data: catalogAttributeDeclarations,
		isFetching: isCatalogAttributeDeclarationsFetching,
	} : {
		data: classifierAttributeDeclarations,
		isFetching: isClassifierAttributeDeclarationsFetching,
	});
};