import React, { memo, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import { changeSearchParam } from 'shared/helpers';
import { Search } from 'shared/ui';

const SearchContextNames = memo(() => {

	const [ searchValue, setSearchValue ] = useState<string>('');
	const [ searchParams, setSearchParams ] = useSearchParams();

	const searchHandler = useDebouncedCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchParams(
			changeSearchParam(searchParams, 'searchValue', e.target.value),
		);
	}, 1000);

	const searchInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(e.target.value);
		searchHandler(e);
	};

	useEffect(() => {
		if (searchParams.get('searchValue')) {
			setSearchValue(searchParams.get('searchValue'));
		}
	}, []);

	return <Search onChange={searchInputHandler} value={searchValue}/>;
});

export {
	SearchContextNames,
};