import { createApi } from '@reduxjs/toolkit/query/react';
import {
	baseQueryWithAuthCheckForNewGenerateTransactionsServiceQueries,
	baseQueryWithAuthCheckForSearchServiceQueries,
	baseQueryWithAuthCheckForDeduplicationServiceQueries,
	baseQueryWithAuthCheckForExportServiceQueries,
	baseQueryWithAuthCheckForIntegrationsServiceQueries,
	baseQueryWithAuthCheckForMdmgGeneratedQueries,
} from '../baseQuery';

export const transactionServiceApi = createApi({
	baseQuery: baseQueryWithAuthCheckForNewGenerateTransactionsServiceQueries,
	endpoints: () => ({}),
	reducerPath: 'transactionsServiceApi',
	tagTypes: ['Transaction'],
});

export const searchApi = createApi({
	baseQuery: baseQueryWithAuthCheckForSearchServiceQueries,
	endpoints: () => ({}),
	reducerPath: 'searchApi',
});

export const deduplicationApi = createApi({
	baseQuery: baseQueryWithAuthCheckForDeduplicationServiceQueries,
	endpoints: () => ({}),
	reducerPath: 'deduplicationApi',
});

export const exportApi = createApi({
	baseQuery: baseQueryWithAuthCheckForExportServiceQueries,
	endpoints: () => ({}),
	reducerPath: 'exportApi',
});

export const integrationsApi = createApi({
	baseQuery: baseQueryWithAuthCheckForIntegrationsServiceQueries,
	endpoints: () => ({}),
	reducerPath: 'integrationsApi',
	tagTypes: [
		'Configurations'
	]
});

export const mdmgApi = createApi({
	baseQuery: baseQueryWithAuthCheckForMdmgGeneratedQueries,
	endpoints: () => ({}),
	reducerPath: 'mdmgApi',
	tagTypes: [
		'Transactional',
		'Catalog', 'CatalogItems',
		'CatalogAttributeDeclarations',
		'Classifier', 'ClassifierItems',
		'ClassifierAttributeDeclarations',
		'Attributes', 'AttributeGroups',
		'MeasureUnits', 'MeasureGroup',
		'ContextNames', 'CompatibilityItems'
	],
});
