import { Flex, Result } from 'antd';
import React, { memo, useMemo } from 'react';
import { CreateCompatibilityButton } from 'features/compatibilities';
import { useGetCompatibilitiesQuery } from 'shared/api/generatedApi/mdmgApi';
import { useTypedTranslation } from 'shared/hooks';
import { CompatibilitiesOption } from '../CompatibilitiesOption';

interface ICompatibilitiesWidget {
	entityId: string;
	entityType: 'CATALOG' | 'CATALOG_ITEM' | 'CLASSIFIER' | 'CLASSIFIER_ITEM';
	contextNameId?: string;
}

const CompatibilitiesWidget = memo(({ entityId, entityType }: ICompatibilitiesWidget) => {
	const { t } = useTypedTranslation();
	const { data, error } = useGetCompatibilitiesQuery({
		entityId,
		entityType: entityType,
	});

	const compatibilities = useMemo(() => {
		const relationDirections = new Map<string, 'STRAIGHT' | 'REVERSE'>();

		return data?.map(({ id, firstEntity, secondEntity, contextName }) => {
			let direction: 'STRAIGHT' | 'REVERSE' = entityId === firstEntity.id
				? 'STRAIGHT'
				: 'REVERSE';

			//	двусторонняя связь
			if (relationDirections.get(id) === direction) {
				direction = direction === 'STRAIGHT' ? 'REVERSE' : 'STRAIGHT';
			} else {
				relationDirections.set(id, direction);
			}

			return {
				id: id,
				direction: direction,
				firstEntity: firstEntity,
				secondEntity: secondEntity,
				contextName: contextName,
			};
		});
	}, [ data ]);

	return (
		<>
			{error ? (
				<Result status="error" title={t((l) => l.compatibilities.error.gettingItems)}/>
			) : (
				<Flex vertical gap={12}>
					<Flex gap={6}>
						<CreateCompatibilityButton entityId={entityId}/>
					</Flex>
					{compatibilities?.map((item) => (
						<CompatibilitiesOption key={item.id}
											   {...item}
						/>
					))}
				</Flex>
			)}
		</>
	);
});

export {
	CompatibilitiesWidget,
};