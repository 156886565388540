import { Flex } from 'antd';
import React, { memo, useRef } from 'react';
import { ContextNamesTable, CreateContextName } from 'features/compatibilities';

interface SelectContextNameWidgetProps {
	onSelect: (id: string) => void;
}

const SelectContextNameWidget = memo((
	{
		onSelect,
	}: SelectContextNameWidgetProps,
) => {
	const tableRef = useRef<any>();

	const onRowClick = (id: string) => {
		tableRef.current?.setSelection([ id ]);
	};

	return (
		<Flex vertical gap={24}>
			<div>
				<CreateContextName/>
			</div>

			<ContextNamesTable ref={tableRef}
							   selectable
							   onSelect={ids => onSelect(ids[0])}
							   onClick={onRowClick}
			/>
		</Flex>
	);
});

export {
	SelectContextNameWidget,
};