import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { CreateExchangeConfig } from 'features/integrations';
import { setBreadcrumbs } from 'entities/breadcrumbs';
import { routes } from 'shared/config';
import { useAppDispatch, useTypedTranslation } from 'shared/hooks';

const CreateExchangeConfigurationPage = () => {
	const { t } = useTypedTranslation();
	const dispatch = useAppDispatch();

	const { integrationId } = useParams();
	const navigate = useNavigate();

	const onCreate = useCallback((configId) =>
			navigate(`/${routes.integrations.main}/${routes.integrations.detail}/${integrationId}/${routes.integrations.exchangeConfigs}/${configId}`),
		[]);

	useEffect(() => {
		dispatch(
			setBreadcrumbs({
				displayName: t(l => l.integrations.breadcrumbs.integrations),
				url: routes.integrations.main,
				child: {
					displayName: t(l => l.integrations.breadcrumbs.connectionCard),
					url: `${routes.integrations.main}/${routes.integrations.detail}/${integrationId}`,
					child: {
						displayName: t(l => l.integrations.breadcrumbs.createConfig),
						url: `${routes.integrations.main}/${routes.integrations.detail}/${integrationId}/${routes.integrations.exchangeConfigs}`,
					},
				},
			}),
		);
	}, []);

	return (
		<CreateExchangeConfig integrationId={integrationId}
							  onCreate={onCreate}
		/>
	);
};

export {
	CreateExchangeConfigurationPage,
};