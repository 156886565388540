import { RiSaveLine } from '@remixicon/react';
import { App, Button, Flex, Form, Result, Spin, Typography } from 'antd';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Transactions } from 'widgets/Transactions';
import { CompatibilityConditionGroup, CompatibilityConditionFormItem } from 'features/compatibilities';
import { useGetCompatibilityQuery, useUpdateCompatibilitiesMutation } from 'shared/api/generatedApi/mdmgApi';
import { routes } from 'shared/config';
import { errorHelper } from 'shared/helpers';
import { DetailFooter } from 'shared/ui';
import { useGetEntityAttributeDeclarations } from './useEntityAttributeDeclarations';

type EntityType = 'CATALOG_ITEM' | 'CLASSIFIER_ITEM' | 'CATALOG' | 'CLASSIFIER';

const itemTypes: Array<EntityType> = [
	'CATALOG_ITEM',
	'CLASSIFIER_ITEM',
];

const mapFormToDto = (element: { items: Array<CompatibilityConditionFormItem> }) => {
	return element.items
		.map(x => {
			if (x.type === 'simple') {
				return [
					{
						firstDeclarationId: x.firstDeclarationId,
						secondDeclarationId: x.secondDeclarationId,
						condition: x.condition,
					},
					...(x.logicalOperator ? [ {
						descriptor: x.logicalOperator,
					} ] : []),
				];
			} else if (x.type === 'group') {
				return [
					{
						descriptor: 'OPEN_PARENTHESIS',
					},
					...mapFormToDto(x),
					{
						descriptor: 'CLOSE_PARENTHESIS',
					},
					...(x.logicalOperator ? [ {
						descriptor: x.logicalOperator,
					} ] : []),
				];
			}
		})
		.flat();
};

const mapDtoToForm = (conditions: any) => {
	const stack: any[] = [];
	let currentGroup: any = { items: [] };

	conditions.forEach(item => {
		if (!item) {
			return;
		}

		if (item.descriptor === 'OPEN_PARENTHESIS') {
			const newGroup = { type: 'group', items: [] };
			stack.push(currentGroup);
			currentGroup.items.push(newGroup);
			currentGroup = newGroup;
		} else if (item.descriptor === 'CLOSE_PARENTHESIS') {
			currentGroup = stack.pop() || { items: [] };
		} else if (item.firstDeclarationId && item.condition) {
			currentGroup.items.push({
				type: 'simple',
				firstDeclarationId: item.firstDeclarationId,
				secondDeclarationId: item.secondDeclarationId,
				condition: item.condition,
			});
		} else if (item.descriptor) {
			if (currentGroup.items.length > 0) {
				currentGroup.items[currentGroup.items.length - 1].logicalOperator = item.descriptor;
			}
		}
	});

	return currentGroup;
};

const CompatibilityPage = () => {
	const { notification } = App.useApp();

	const { compatibilityId } = useParams();
	const navigate = useNavigate();

	const [ form ] = Form.useForm();

	const { data: compatibility, isFetching } = useGetCompatibilityQuery({
		id: compatibilityId,
	});

	const isMappingNotApplicable =
		compatibility &&
		itemTypes.includes(compatibility.firstEntity.type) &&
		itemTypes.includes(compatibility.secondEntity.type);

	const skip = !compatibility || isMappingNotApplicable;

	const {
		data: firstEntityAttributeDeclarations,
		isFetching: isFirstAttributeFetching,
	} = useGetEntityAttributeDeclarations({
		entity: compatibility?.firstEntity,
		skip: skip,
	});

	const {
		data: secondEntityAttributeDeclarations,
		isFetching: isSecondAttributeFetching,
	} = useGetEntityAttributeDeclarations({
		entity: compatibility?.secondEntity,
		skip: skip,
	});

	const [ updateCompatibility, { isLoading } ] = useUpdateCompatibilitiesMutation();

	const handleBackClick = () => {
		navigate(`/${routes.catalogs.main}/`);
	};

	const onFinish = async (form) => {
		try {
			await updateCompatibility({
				id: compatibilityId,
				updateCompatibilityRequest: {
					conditions: JSON.stringify(mapFormToDto(form)),
				},
			}).unwrap();
			notification.success({ message: 'Маппинг успешно сохранен' });
		} catch(err) {
			errorHelper(
				'Ошибка сохранения маппинга',
				err,
				notification
			);
		}
	};

	useEffect(() => {
		if (!compatibility) {
			return;
		}

		try {
			form.setFieldsValue(
				mapDtoToForm(
					JSON.parse(compatibility.conditions),
				),
			);
		} catch (err) {
			console.error(err);
		}
	}, [ compatibility ]);

	if (isFetching ||
		isFirstAttributeFetching || isSecondAttributeFetching ||
		(!isMappingNotApplicable && (isFirstAttributeFetching || isSecondAttributeFetching))
	) {
		return (
			<Spin/>
		);
	}

	return (
		<Spin spinning={isLoading}>
			<Flex vertical gap={24}>
				<Flex justify="space-between" align="flex-end" style={{ width: '100%' }}>
					<Typography.Title level={1}>
						Редактор совместимостей
					</Typography.Title>
					<Transactions/>
				</Flex>

				{isMappingNotApplicable && (
					<Result title="Данная совместимость является декларативной"/>
				)}

				{!isMappingNotApplicable && (
					<Form form={form} onFinish={onFinish} style={{ width: 920 }}>
						<CompatibilityConditionGroup root={true}
													 firstAttributes={firstEntityAttributeDeclarations}
													 secondAttributes={secondEntityAttributeDeclarations}
						/>

						<DetailFooter customHandleBack={handleBackClick}>
							<Button htmlType="submit" type="text" icon={<RiSaveLine/>}>
								Сохранить совместимость
							</Button>
						</DetailFooter>
					</Form>
				)}
			</Flex>
		</Spin>
	);
};

export {
	CompatibilityPage,
};