import { TabsProps, Tabs } from 'antd';
import React, { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import { SetupMapping } from 'widgets/integrations';
import { EditExchangeConfig } from 'features/integrations';
import { DeleteExchangeConfig } from 'features/integrations/deleteExchangeConfig';
import { setBreadcrumbs } from 'entities/breadcrumbs';
import { routes } from 'shared/config';
import { useAppDispatch, useTypedTranslation } from 'shared/hooks';
import { DetailFooter } from 'shared/ui';
import { IntegrationDetailPageTab, tabSearchParamKey } from '../IntegrationDetail';

const ExchangeConfigurationDetailPage = React.memo(() => {
	const { t } = useTypedTranslation();
	const dispatch = useAppDispatch();
	const { integrationId, exchangeConfigId } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		dispatch(
			setBreadcrumbs({
				displayName: t(l => l.integrations.breadcrumbs.integrations),
				url: routes.integrations.main,
				child: {
					displayName: t(l => l.integrations.breadcrumbs.connectionCard),
					url: `${routes.integrations.main}/${routes.integrations.detail}/${integrationId}`,
					child: {
						displayName: t(l => l.integrations.breadcrumbs.configCard),
						url: `${routes.integrations.main}/${routes.integrations.detail}/${integrationId}/${routes.integrations.exchangeConfigs}/${exchangeConfigId}`,
					},
				},
			}),
		);
	}, []);

	const tabs: TabsProps['items'] = useMemo(() => [
		{
			key: 'editExchangeConfigInfo',
			label: t(l => l.integrations.exchangeConfig.tabs.configInfo),
			children: (
				<EditExchangeConfig integrationId={integrationId}
									configurationId={exchangeConfigId}
				/>
			),
		},
		{
			key: 'mappingSetup',
			label: t(l => l.integrations.exchangeConfig.tabs.mapping),
			children: (
				<SetupMapping integrationId={integrationId}
							  exchangeConfigId={exchangeConfigId}
				/>
			),
		},
	], [ t ]);

	return (
		<>
			<Tabs items={tabs} style={{ marginBottom: 74 }}/>

			<DetailFooter>
				<DeleteExchangeConfig integrationId={integrationId}
									  exchangeConfigIds={[ exchangeConfigId ]}
									  onDelete={() => navigate({
										  pathname: `/${routes.integrations.main}/${routes.integrations.detail}/${integrationId}`,
										  search: `${tabSearchParamKey}=${IntegrationDetailPageTab.ExchangeConfigs}`,
									  })}
				/>
			</DetailFooter>
		</>

	);
});

export {
	ExchangeConfigurationDetailPage,
};
