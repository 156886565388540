import { RiPlayCircleLine } from '@remixicon/react';
import { App } from 'antd';
import React, { memo, useCallback, useState } from 'react';
import { TaskType, useAsyncOperation } from 'entities/events';
import { mdmgApi, useSearchCompatibilitiesMutation } from 'shared/api/generatedApi/mdmgApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch } from 'shared/hooks';
import { ButtonVsDropdownLink } from 'shared/ui/components/ButtonVsDropdownLink';

interface StartSearchCompatibilitiesButtonProps {
	compatibilityId: string;
	isDropdown: boolean;
}

const StartSearchCompatibilitiesButton = memo((
	{
		compatibilityId,
		isDropdown,
	}: StartSearchCompatibilitiesButtonProps,
) => {
	const { notification } = App.useApp();
	const dispatch = useAppDispatch();

	const [ isLoading, setIsLoading ] = useState(false);

	const { execute: startSearchCompatibilities } = useAsyncOperation(
		useSearchCompatibilitiesMutation,
		TaskType.COMPATIBILITY_ITEM_SEARCH,
	);

	const startSearch = useCallback(async () => {
		try {
			setIsLoading(true);
			await startSearchCompatibilities({
				id: compatibilityId,
			});
			dispatch(mdmgApi.util.invalidateTags(['CompatibilityItems']));
		} catch(err) {
			errorHelper('Ошибка запуска поиска совместимостей', err, notification);
		} finally {
			setIsLoading(false);
		}
	}, [ compatibilityId ]);

	return (
		<ButtonVsDropdownLink title="Запустить поиск"
							  callback={startSearch}
							  icon={<RiPlayCircleLine size={16}/>}
							  type={isDropdown ? 'dropdownLink' : null}
							  isLoading={isLoading}
		/>
	);
});

export {
	StartSearchCompatibilitiesButton,
};