import { App, Flex, Steps, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { SetupMapping } from 'widgets/integrations';
import {
	CreateExchangeConfig,
	DeleteIntegration,
	EditIntegrationMainInfo,
	SelectIntegrationSystem,
} from 'features/integrations';
import { routes } from 'shared/config';
import { DetailFooter } from 'shared/ui';

enum CreateIntegrationStep {
	System,
	Parameters,
	ExchangeConfig,
	Mapping,
}

const createIntegrationStepsNames: Record<CreateIntegrationStep, string> = {
	[CreateIntegrationStep.System]: 'Выбор подключения',
	[CreateIntegrationStep.Parameters]: 'Параметры подключения',
	[CreateIntegrationStep.ExchangeConfig]: 'Настройка конфигураций обмена',
	[CreateIntegrationStep.Mapping]: 'Настройка маппинга',
};

const CreateNewIntegrationPage = React.memo(() => {
	const { notification } = App.useApp();

	const navigate = useNavigate();
	const { integrationId, exchangeConfigId } = useParams();

	const [ form ] = useForm();

	const [ current, setCurrent ] = useState<CreateIntegrationStep | null>(null);
	const [ selectedSystemId, setSelectedSystemId ] = useState<string | null>(null);

	const stepFactory = (current: CreateIntegrationStep) => {
		switch (current) {
			case CreateIntegrationStep.System:
				return (
					<SelectIntegrationSystem onSelect={setSelectedSystemId}/>
				);
			case CreateIntegrationStep.Parameters:
				return (
					<EditIntegrationMainInfo systemId={selectedSystemId}
											 onUpsert={integrationId => {
												 notification.success({ message: 'Подключение успешно создано!' });
												 navigate(integrationId);
											 }}
					/>
				);
			case CreateIntegrationStep.ExchangeConfig:
				return (
					<CreateExchangeConfig integrationId={integrationId}
										  onCreate={configId => navigate(`exchangeConfigs/${configId}`)}
					/>
				);
			case CreateIntegrationStep.Mapping:
				return (
					<SetupMapping integrationId={integrationId}
								  exchangeConfigId={exchangeConfigId}
								  onUpsert={() =>
									  navigate(`/${routes.integrations.main}/${routes.integrations.detail}/${integrationId}`)}
					/>
				);
		}
	};

	const items = Object.entries(createIntegrationStepsNames)
		.map(([ key, title ]) => ({ key, title: title }));

	const prev = () => {
		if (!!integrationId && current == CreateIntegrationStep.ExchangeConfig) {
			return;
		}

		setCurrent(current - 1);
		if (current === CreateIntegrationStep.Parameters) {
			setSelectedSystemId(null);
		}
	};

	const next = () => {
		setCurrent(current + 1);
	};

	const handleBack = () => {
		if (current > 0) {
			prev();
		} else {
			navigate(`/${routes.integrations.main}`);
		}
	};

	useEffect(() => {
		if (selectedSystemId) {
			next();
		} else {
			form.resetFields();
		}
	}, [ selectedSystemId, form ]);

	useEffect(() => {
		if (exchangeConfigId) {
			setCurrent(CreateIntegrationStep.Mapping);
		} else if (integrationId) {
			setCurrent(CreateIntegrationStep.ExchangeConfig);
		} else {
			setCurrent(CreateIntegrationStep.System);
		}
	}, [ integrationId, exchangeConfigId ]);

	return (
		<>
			<Flex vertical gap={24} style={{ marginBottom: 120 }}>
				<Typography.Title level={1}>
					Создание нового подключения
				</Typography.Title>
				<Steps current={current} items={items}/>
				<div>
					{stepFactory(current)}
				</div>
			</Flex>

			<DetailFooter customHandleBack={handleBack}
						  isNavHidden={!!integrationId || !!exchangeConfigId}
			>
				{integrationId && (
					<DeleteIntegration id={integrationId}
									   isButton={true}
									   onDelete={() => navigate(`/${routes.integrations.main}`)}
					/>
				)}
			</DetailFooter>
		</>
	);
});

export {
	CreateNewIntegrationPage,
};
