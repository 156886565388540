import { App, Flex, Result, Skeleton, Spin } from 'antd';
import React from 'react';
import { ExchangeConfigForm } from 'entities/integrations';
import {
	useGetConfigurationQuery,
	useUpdateConfigurationMutation,
} from 'shared/api/generatedApi/integrationsApi';
import { errorHelper } from 'shared/helpers';

interface EditExchangeConfigProps {
	integrationId: string;
	configurationId: string;
}

const EditExchangeConfig = (
	{
		integrationId,
		configurationId,
	}: EditExchangeConfigProps,
) => {
	const { notification } = App.useApp();

	const { data, isLoading: isConfigurationLoading, error } = useGetConfigurationQuery(
		{
			connectionId: integrationId,
			configurationId: configurationId,
		},
	);

	const [ updateConfig, { isLoading } ] = useUpdateConfigurationMutation();

	const onSubmit = async (state) => {
		try {
			await updateConfig({
				connectionId: integrationId,
				configurationId: configurationId,
				exchangeConfigurationRequest: {
					name: state.name,
					description: state.description,
					status: state.status,
					on_delete_action: state.on_delete_action,
					extraction_targets: state.extraction_targets,
					destination: state.destination,
					activation_mode: {
						execution_type: state.execution_type,
						schedule_interval: state.schedule_interval,
					},
				},
			})
				.unwrap();
		} catch (e) {
			errorHelper(
				'Ошибка при создании конфигурации обмена!',
				e,
				notification,
			);
		}
	};

	if (error) {
		return <Result status="error" title="Ошибка!" subTitle={JSON.stringify(error)}/>;
	}

	if (isConfigurationLoading) {
		return (
			<Flex vertical style={{ width: '100%' }} gap={12}>
				<Skeleton.Input active style={{ width: '100%' }}/>
				<Skeleton.Input active style={{ width: '100%' }}/>
				<Skeleton.Input active style={{ width: '100%' }}/>
				<Skeleton.Input active style={{ width: '100%' }}/>
			</Flex>
		);
	}

	return (
		<Spin spinning={isLoading}>
			<ExchangeConfigForm integrationId={integrationId}
								data={data}
								onSubmit={onSubmit}
			/>
		</Spin>
	);
};

export {
	EditExchangeConfig,
};