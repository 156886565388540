import { exportApi } from './generatedApi/exportApi';
import { integrationsApi } from './generatedApi/integrationsApi';
import { mdmgApi } from './generatedApi/mdmgApi';
import { transactionServiceApi } from './generatedApi/transactionServiceApi';

mdmgApi.enhanceEndpoints({
	endpoints: {
		createCatalog: {
			invalidatesTags: ['Catalog'],
		},
		deleteCatalogsAsync: {
			invalidatesTags: ['Catalog'],
		},
		getCatalog: {
			providesTags: ['Transactional', 'Catalog'],
		},
		getCatalogs: {
			providesTags: ['Catalog'],
		},

		/*	Catalog Items	*/
		createCatalogItem: {
			invalidatesTags: ['CatalogItems'],
		},
		getCatalogItems: {
			providesTags: ['Transactional', 'CatalogItems'],
		},
		getCatalogItem: {
			providesTags: ['Transactional', 'CatalogItems'],
		},
		deleteCatalogItem: {
			invalidatesTags: ['Transactional', 'CatalogItems'],
		},
		updateClassifierItem: {
			invalidatesTags: ['Transactional', 'ClassifierItems'],
		},
		getClassifier: {
			providesTags: ['Transactional', 'Classifier'],
		},
		getClassifiers: {
			providesTags: ['Transactional', 'Classifier'],
		},
		getClassifierItem: {
			providesTags: ['Transactional', 'ClassifierItems'],
		},
		getClassifierItems: {
			providesTags: ['Transactional', 'ClassifierItems'],
		},
		deleteClassifierItem: {
			invalidatesTags: ['Transactional', 'ClassifierItems'],
		},
		getCatalogAttributeDeclaration: {
			providesTags: ['Transactional', 'CatalogAttributeDeclarations'],
		},
		getCatalogAttributeDeclarations: {
			providesTags: ['Transactional', 'CatalogAttributeDeclarations'],
		},
		getClassifierAttributeDeclarations: {
			providesTags: ['Transactional', 'ClassifierAttributeDeclarations'],
		},
		getAttributeGroup1: {
			providesTags: ['Transactional', 'AttributeGroups'],
		},
		createAttribute: {
			invalidatesTags: ['Transactional', 'Attributes'],
		},
		updateAttribute: {
			invalidatesTags: ['Transactional', 'Attributes'],
		},
		getAttributes: {
			providesTags: ['Transactional', 'Attributes'],
		},
		getAttribute: {
			providesTags: ['Transactional', 'Attributes'],
		},


		getMeasurementGroup: {
			providesTags: ['MeasureGroup'],
		},
		updateMeasurementGroup: {
			invalidatesTags: ['MeasureGroup'],
		},
		getMeasurement: {
			providesTags: ['Transactional', 'MeasureUnits'],
		},
		getMeasurements: {
			providesTags: ['Transactional', 'MeasureUnits'],
		},
		getMeasurement1: {
			providesTags: ['Transactional', 'MeasureUnits'],
		},
		updateMeasurement: {
			invalidatesTags: ['Transactional', 'MeasureUnits'],
		},

		/**	Compatibilities	*/
		getContextNames: {
			providesTags: ['ContextNames'],
		},
		createContextName: {
			invalidatesTags: ['ContextNames'],
		},
		updateContextName: {
			invalidatesTags: ['ContextNames'],
		},
		deleteContextNames: {
			invalidatesTags: ['ContextNames'],
		},
		getCompatibilityItems: {
			providesTags: ['CompatibilityItems'],
		},

		importXlsx: {
			invalidatesTags: ['CatalogItems'],
		},
		downloadFile: {
			query: (queryArg) => ({
				url: `/api/v1/files/${queryArg.id}/download`,
				responseHandler: async (response) => {
					const blob = await response.blob();
					return window.URL.createObjectURL(blob);
				},
			}),
		},
	},
});

transactionServiceApi.enhanceEndpoints({
	endpoints: {
		getTransactionData: {
			providesTags: ['Transaction'],
		},
		getTransactionsData: {
			providesTags: ['Transaction'],
		},
	},
});

exportApi.enhanceEndpoints({
	endpoints: {
		exportItemsXlsx: {
			query: (queryArg) => ({
				url: `/api/v1/catalogs/${queryArg.id}/items/export`,
				params: { attributeDeclarationIds: queryArg.attributeDeclarationIds },
				cache: 'no-cache',
				responseHandler: async (response) => {
					const blobFile = await response.blob();
					return {
						fileUrl: window.URL.createObjectURL(blobFile),
						filenameHeader: response.headers.get('Content-Disposition'),
					};
				},
			}),
		},
	},
});

integrationsApi.enhanceEndpoints({
	endpoints: {},
});
