import { Localization } from './types';

const localization: Localization = {
	common: {
		buttons: {
			confirm: 'Подтвердить',
			cancel: 'Отменить',
			back: 'Назад',
			delete: 'Удалить',
			deleteSelected: 'Удалить выбранные',
			edit: 'Редактировать',
			columnsSetup: 'Настройка колонок',
			create: 'Создать',
			selectAll: 'Выбрать все',
			save: 'Сохранить',
			apply: 'Применить',
			continue: 'Продолжить',
			select: 'Выбрать',
		},
		statuses: {
			normalized: 'Нормализована',
			nonNormalized: 'Не нормализована',
			blocked: 'Заблокирована',
			deleted: 'Удалена',
			dupe: 'Дубликат',
			error: 'Ошибка',
		},
		defaultNames: {
			emptyName: '<Название не заполнено>',
			status: 'Статус',
			name: 'Название',
			designation: 'Наименование',
			type: 'Тип',
			isMulti: 'Множественный',
			description: 'Описание',
			dataType: 'Тип данных',
			mainInfo: 'Общая информация',
			defaultValue: 'Значение по умолчанию',
			inputMask: 'Маска ввода',
			formula: 'Формула',
			maxLength: 'Максимальная длина',
			accuracy: 'Точность',
			emptyValue: '<Значение не задано>',
			yes: 'Да',
			no: 'Нет',
			itemsNumber: 'Количество позиций',
			groupName: 'Наименование группы',
		},
		search: {
			nothingFound: 'По вашему запросу ничего не найдено',
			checkRequest: 'Проверьте, правильно ли введен ваш запрос, или попробуйте его изменить',
		},
		inputs: {
			emptyValueLabel: 'Отсутствует',
			trueValueLabel: 'Правда',
			falseValueLabel: 'Ложь',
		},
		tables: {
			total: 'Всего',
		},
		filters: {
			filterControl: 'Управление фильтрацией',
			clearFilter: 'Очистить фильтр',
			equal: 'Равно',
			notEqual: 'Не равно',
			extendedFilters: 'Расширенный фильтр',
			addFilterOption: 'Добавить параметр фильтрации',
			and: 'И',
			or: 'Или',
			empty: 'Пустое значение',
			notEmpty: 'Непустое значение',
			more: 'Больше',
			moreOrEqual: 'Больше или равно',
			less: 'Меньше',
			lessOrEqual: 'Меньше или равно',
			contains: 'Содержит',
			notContains: 'Не содержит',
		},
		error: {
			message: 'Текст ошибки',
			emptyRequiresFields: 'Необходимо заполнить обязательные поля',
			noStateDifference: 'Ошибка при получении разницы состояний',
			title: 'Ошибка!',
		},
	},
	catalogs: {
		mainInfo: 'Общая информация',
		catalogs: 'Справочники',
		catalog: 'Справочник',
		catalogInfo: 'Сведения о справочнике',
		attributesDeclarations: {
			attributesDeclarationsErr: 'Ошибка при получении списка деклараций!',
			addAttribute: 'Добавить атрибут',
			addAttributeDeclModal: 'Для добавления атрибута необходимо выбрать атрибут из списка',
			mappingCreated: 'Маппинг создан',
			mappingUpdated: 'Маппинг обновлен',
			mappingDeleted: 'Маппинг удален',
		},
		deduplication: {
			main: 'Дедубликация позиций',
			deduplication: 'Дедубликация',
			dupesGroupsErr: 'Ошибка при получении групп дубликатов',
			dupesSearchResult: 'Результаты поиска дубликатов',
			deleteDupesForSelectedItems: 'Удалить дубликаты для выбранных позиций',
			makeDupesForSelectedItems: 'Установить статус «Дубликат» для выбранных позиций',
			searchSaved: 'Вариант поиска сохранен',
			searchCompleted: 'Вариант поиска произведен',
			updateSearchErr: 'Ошибка обновления варианта поиска дубликатов',
			importSearch: 'Поиск при импорте',
			addCatalog: 'Добавление справочника',
			attributesList: 'Атрибуты, по которым осуществляется поиск дубликатов',
			addAttribute: 'Атрибуты, по которым осуществляется поиск дубликатов',
			selectAttribute: 'Для добавления атрибута необходимо выбрать атрибут из списка',
			startDupeSearch: 'Запустить поиск',
			dupeSearchResult: 'Просмотреть результаты поиска дубликатов',
			variantN: 'Вариант №',
			relaunchSearch: 'Перезапустить поиск',
			addNewVariant: 'Добавить вариант поиска дубликатов',
			isOriginal: 'Является оригиналом',
			setAsOriginal: 'Принять за оригинал',
			position: 'Позиция',
			source: 'Источник',
			unionAndDelete: 'Объединить и удалить дубликаты',
			setStatus: 'Установить статус «Дубликат»',
			deleteDupes: 'Удалить дубликаты для позиции',
			deleteSearchOption: 'Удалить',
			actualOn: 'Актуально на',
			actualOnTooltip: 'Для получения актуального результата запустите поиск заново',


		},
		groups: {
			noCatalog: 'Нет справочника',
			noGroups: 'Нет справочных групп',
			selectGroup: 'Выберите справочную группу для отображения списка записей',
			currCatalogErr: 'Ошибка получения текущего справочника',
			catalogErr: 'Ошибка получения справочника',
			rootGroupErr: 'Ошибка при получении корневой группы справочников!',
			listErr: 'Ошибка при получении корневой группы справочников!',
			noGroupErr: 'Вы не добавили еще ни одной справочной группы',
			createNewGroup:
				'Для создания новой группы нажмите на кнопку «Добавить справочную группу»',
			createNestedGroup: 'Добавить вложенный справочник',
			addNewCatalog: 'Добавить новый справочник',
		},
		records: {
			recordsValues: 'Значения атрибутов',
			attributesSetup: 'Настройка атрибутов',
			editRecord: 'Редактирование записи',
			recordCard: 'Карточка позиции справочника',
			recordsRelationsErr: 'Ошибка получения записей для атрибута типа "Связь"!',
			recordsListErr: 'Ошибка при получении списка записей!',
			declarationsListErr: 'Ошибка получения деклараций',
			noRecords: 'Справочник не содержит позиций',
			addRecordTooltip:
				'Для добавления новой позиции нажмите на кнопку «Добавить справочную запись»',
			getMeasurementsErr: 'Ошибка при получении единиц измерения',
			converterErr: 'Ошибка конвертирования единицы измерения',
			historyTabTitle: 'История изменений',
		},
		export: {
			exportCatalogTitle: 'Экспорт в Excel',
			exportCatalogTooltip:
				'Для экспорта справочных записей в Excel Вам необходимо выбрать экспортируемые колонки',
			exportCatalogBtn: 'Экспортировать',
			fetchErr: 'Ошибка получения данных для экспорта',
		},
		restrictions: {
			restrictions: 'Ограничения',
			restrictionsTableErr: 'Ошибка редактирования таблицы допустимых значений',
			declarationsTableErr: 'Ошибка редактирования таблицы допустимых значений',
			addTable: 'Добавление таблиц допустимых значений',
			noTableErr: 'Вы ещё не создали ни одной ограничительной таблицы',
			tabTitle: 'Таблицы допустимых значений',
			addTableBtn: 'Добавить таблицу допустимых значений',
			noTableMsg: 'Добавьте таблицу допустимых значений чтобы начать редактирование',
			requiredCheckbox: 'Обязательность исполнения',
			requiredCheck: 'Проверка на обязательность заполнения',
			requiredAttributes: 'Обязательные атрибуты',
			addAttributeModalTitle: 'Добавление атрибута',
			uniqueValueCheck: 'Проверка на уникальность значения',
			uniqueAttributes: 'Уникальные атрибуты',
		},
		deleteRecord: {
			title: 'Удаление справочной записи',
			message:
				'Вы действительно хотите удалить данную справочную запись? Справочная запись и сопутствующие данные будут удалены без возможности восстановления',
			relatedData: 'Сопутствующие данные:',
			integrationMessages: 'сообщения обмена с интеграцией',
			transitionKeys: 'переходные ключи',
			agreement: 'Я согласен удалить все вышеперечисленные сопутствующие данные',
			error: 'Ошибка при удалении справочной записи!',
		},
		deleteCatalog: {
			title: 'Удаление справочника',
			agreement: 'Я согласен удалить справочник и входящие в него справочники',
			alert: 'Вы действительно хотите удалить данный справочник? Справочник и входящие в него справочники будут удалены без возможности восстановления',
			error: 'Ошибка при удалении справочника!',
		},
		error: {
			gettingItem: 'Ошибка при получении позиции справочника',
			addAttributeToRestrictionErr: 'Ошибка при добавлении атрибута в ограничение!',
			gettingDeclarationsErr: 'Ошибка при получении деклараций!',
		},
	},
	classifier: {
		mainPage: {
			title: 'Классификаторы',
		},
		common: {
			classifiers: 'Классификаторы',
			classifierCard: 'Карточка классификатора',
			attributesSetting: 'Настройка атрибутов для классификации',
			chooseAttributeFromList: 'Для добавления атрибута необходимо выбрать атрибут из списка',
			addAttribute: 'Добавление атрибута',
			classifiersList: 'Список классификаторов',
			classifierFilling: 'Наполнение классификатора',
			editRecord: 'Редактирование записи',
			recordCard: 'Карточка записи в классификаторе',
			noClassifier: 'Нет классификатора',
			selectClassifier: 'Выберите классификатор для отображения списка записей',
		},
		action: {
			addClassifier: 'Добавить классификатор',
			setAsRecordName: 'Установить, как название записи',
			setAttributesForClassification: 'Назначить атрибуты для классификации справочных данных',
			addAttribute: 'Добавить атрибут',
		},
		error: {
			newClassifierCreation: 'Ошибка при создании классификатора!',
			classifierGetting: 'Ошибка при получении классификатора!',
			classifierEditing: 'Ошибка при редактировании классификатора!',
			gettingItem: 'Ошибка при получении позиции классификатора!',
		},
	},
	attributes: {
		attributeTreeErr: 'Не удалось загрузить дерево атрибутов!',
		attributeGroupErr: 'Ошибка получения группы атрибутов',
		attributeListErr: 'Ошибка при получении списка атрибутов!',
		attributeErr: 'Ошибка получения атрибута',
		gettingAttributeApplicabilityErr:
			'Ошибка при загрузке применимости атрибута в справочниках!',
		receivingDataForEditingAttributeErr:
			'Ошибка при получении данных для редактирования атрибута!',
		applicabilityinCatalogs: 'Применяемость в справочниках',
		unusedAttribute: 'Атрибут в справочниках не применяется',
		applicabilityListError: 'Ошибка при получении списка применимости в справочниках!',
		attributesSetup: 'Настройка атрибутов',
		attributesTabTitle: 'Атрибуты',
	},
	attributeGroup: {
		deleteAttributeGroup: {
			title: 'Удаление группы атрибутов',
			agreement: 'Я согласен удалить группу атрибутов и входящие в нее атрибуты',
			alert: 'Вы действительно хотите удалить данную группу атрибутов? Группа атрибутов и входящие в нее атрибуты будут удалены без возможности восстановления',
			error: 'Ошибка при удалении группы атрибутов!',
		},
	},
	measures: {
		mainInfo: 'Общая информация',
		measurementUnit: 'Единица измерения',
		measuresTabTitle: 'Единицы измерения',
		group: {
			displayName: 'Наименование группы единицы измерения',
			description: 'Описание',
			groupTitle: 'Группа единиц измерения',
			groupsTitle: 'Группы единиц измерения',
		},
		unit: {
			displayName: 'Наименование',
			shortName: 'Обозначение',
			basicUnit: 'Базовая ЕИ',
			formula: 'Формула пересчета',
			inverseFormula: 'Обратная формула пересчета',
			coefficient: 'Коэффициент пересчета',
			noCoefficient: '<Не задан>',
			isBasic: {
				true: 'Да',
				false: 'Нет',
			},
			cardTitle: 'Карточка единицы измерения',
		},
		error: {
			gettingMeasurementUnits: 'Ошибка при получении единиц измерения!',
			editingMeasurementUnit: 'Ошибка при изменении значения ЕИ!',
			designatingSecondBaseUnit: 'Выбор второй базовой ЕИ невозможен',
			gettingDataToSelectUnits: 'Не удалось получить данные для выбора Единиц измерения!',
		},
		actions: {
			delete: {
				unit: {
					modalTitle: 'Удаление единицы измерения',
					modalMessage:
						'Вы действительно хотите удалить данную единицу измерения? Единица измерения будет удалена без возможности восстановления',
				},
				group: {
					modalTitle: 'Удаление группы единиц измерения',
					modalMessage:
						'Вы действительно хотите удалить данную группу единиц измерения? Группа и входящие в нее единицы измерения будут удалены без возможности восстановления.',
					agreement:
						'Я согласен удалить группу единиц измерения и входящие в нее единицы измерения',
				},
			},
			add: {
				unit: {
					btnTitle: 'Добавить единицу измерения',
				},
				group: {
					btnTitle: 'Добавить группу единиц измерения',
					editGroupTitle: 'Редактирование группы единицы измерения',
					createGroupTitle: 'Создание группы единицы измерения',
				},
			},
		},
		hint: {
			baseUnit:
				'В группе единиц измерения может быть только одна базовая ЕИ. Остальные ЕИ связываются с базовой через коэффициент пересчёта или формулу пересчёта. Для базовых ЕИ коэффициент пересчёта равен 1.',
		},
		noGroups: 'Вы не добавили еще ни одной группы единиц измерения',
		createNewGroup:
			'Для создания новой группы нажмите на кнопку «Добавить группу единиц измерения»',
	},
	constraintTables: {
		hint: {
			required: '«+» — Атрибут должен быть обязательно заполнен',
			any: '«*» — Значение атрибута может быть любым',
			requiredAndFilled:
				'«*+» — Значение атрибута может быть любым, но обязательно заполненным, т.е. ячейка не может остаться пустой',
		},
		card: 'Карточка ограничительной таблицы',
		noColumns: 'Вы еще не выбрали ни одного столбца для ограничительной таблицы',
		noTables: 'Вы ещё не создали ни одной ограничительной таблицы',
		listTitle: 'Перечень ограничительных таблиц',
		pageSubtitle: 'Раздел для управления данными справочников',
		assignColumnLabel: 'Колонки таблицы',
		assignColumnModalTitle: 'Выбор наименования колонки',
		constraintTablesTabTitle: 'Ограничительные таблицы',
	},
	breadcrumbs: {
		metadata: 'Метаданные',
	},
	profile: {
		goToProfileButton: 'Профиль',
		logoutButton: 'Выйти',
		userName: 'Имя пользователя',
	},
	transactions: {
		errorGetList: 'Ошибка при получении списка транзакций!',
		addToCompareTitle: 'Добавить к сравнению',
	},
	integrations: {
		exchangeConfig: {
			deleteError: 'Ошибка при удалении конфигурации обмена!',
			tabs: {
				configInfo: 'Информация о конфигурации обмена',
				mapping: 'Настройка маппинга',
			},
			list: {
				configName: 'Наименование конфигурации',
				linkedCatalogs: 'Связанные справочники',
				replicationDirection: 'Направление репликации',
				status: 'Статус',
				error: 'Ошибка при получении списка конфигураций обмена!',
			},
			form: {
				name: 'Наименование конфигурации',
				description: 'Описание',
				onDeleteAction: 'Действие при удалении позиции во внешней системе',
				status: 'Статус',
				replicationDirection: 'Направление репликации',
				executionType: 'Режим импорта позиций',
				scheduleInterval: 'Регулярное выражение для настройки расписания обмена',
				scheduleIntervalPlaceholder: 'Введите CRON-выражение',
				mappingSettings: 'Настройки маппинга',
				importDestination: 'Справочник, в который будут импортированы позиции',
				importSource: 'Источник данных',
				exportDestination: 'Справочник, из которого будут экспортированы позиции',
				exportSource: 'Источник данных',
				confirmButton: 'Подтвердить выбранный источник данных',
				executionTypes: {
					scheduled: 'По расписанию',
					manual: 'Ручной',
					manualImmediate: 'Ручной немедленно',
				},
				statuses: {
					inactive: 'Остановлен',
					active: 'Выполняется',
				},
				onDeleteActions: {
					foo: 'Foo',
					bar: 'Bar',
					baz: 'Baz',
				},
				replicationDirections: {
					import: 'Импорт',
					export: 'Экспорт',
				},
			},
		},
		replicationDirections: {
			import: 'Импорт',
			export: 'Экспорт',
		},
		breadcrumbs: {
			integrations: 'Интеграции',
			connectionCard: 'Карточка подключения',
			configCard: 'Карточка конфигурации обмена',
			createConfig: 'Создание конфигурации обмена',
		},
		tabs: {
			exchangeConfigs: 'Конфигурации обмена',
			connectionInfo: 'Информация о подключении',
			journal: 'Журнал сообщений',
			exchangeKeys: 'Переходные ключи',
		},
		filters: {
			showAll: 'Показать все',
			showActive: 'Показать активные интеграции',
			showFailed: 'Показать интеграции со сбоями',
		},
		connectionInfo: {
			identifier: 'Идентификатор',
			lastActivity: 'Последняя активность',
			completed: 'Выполн...',
			stopped: 'Останов...',
		},
		deleteIntegration: {
			confirmTitle: 'Подтверждение удаления',
			confirmMessage:
				'Вы действительно хотите удалить данную интеграцию? Интеграция и сопутствующие данные будут удалены без возможности восстановления',
			relatedData: 'Сопутствующие данные:',
			exchangeConfigs: 'конфигурации обмена',
			replicationMessages: 'репликационные сообщения',
			transitionKeys: 'переходные ключи',
			confirmCheckbox: 'Я согласен удалить все вышеперечисленные сопутствующие данные',
			deleteError: 'Ошибка при удалении интеграции!',
		},
		errors: {
			listError: 'Ошибка при получении списка интеграций!',
		},
		exchangeKeys: {
			externalId: 'Внешний идентификатор',
			internalId: 'Внутренний идентификатор',
			position: 'Позиция',
		},
	},
	compatibilities: {
		mainInfo: 'Общая информация',
		addCompatibility:'Добавление совместимости',
		tabTitle: 'Совместимости',
		tabCatalogs: 'Справочные записи',
		tabClassifiers: 'Записи классификатора',
		choosingRecordTitle: 'Выбор записи',
		mainPage: {
			title: 'Классификаторы',
		},
		contextNames: {
			create: 'Добавить новую контекстную связь',
			cardTitle: 'Карточка контекстной связи',
			leftEntityName: 'Сущность слева',
			contextDisplayName: 'Прямая связь',
			rightEntityName: 'Сущность справа',
			inverseContextDisplayName: 'Обратная связь',
			usingContextName: 'Применяемость контекстной связи',
			delete: {
				modalTitle: 'Удаление контекстной связи',
				modalMessage: 'Вы действительно хотите удалить данную контекстную связь? Контекстная связь будет удалена без возможности восстановления'
			},
		},
		actions: {
			addRecordToGroup: 'Добавить запись в группу',
			setup: 'Настроить совместимость',
			add: {
				btnTitle: 'Добавить совместимость'
			},
			addCompatibilitiesItems: 'Добавить запись в группу',
		},
		error: {
			getting: 'Ошибка при получении совместимостей',
			deleting: 'Ошибка при удалении совместимостей',
			gettingItems: 'Ошибка при получении совместимости по позиции',
			editingCompatibilityItem: 'Ошибка при изменении значения совместимости!',
			usingCompatibilityListError: 'Ошибка при получении совместимостей!',
			deletingCompatibilityItems: 'Ошибка при удалении позиции совместимости',
			addCompatibilitiesItemsErr: 'Ошибка при добавлении позиции совместимостей!',
		},
	},
};

export default localization;
