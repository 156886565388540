import { mdmgApi as api } from './newCreateApiFile';
const injectedRtkApi = api.injectEndpoints({
	endpoints: (build) => ({
		updateClassifierAsync: build.mutation<
			UpdateClassifierAsyncApiResponse,
			UpdateClassifierAsyncApiArg
		>({
			query: (queryArg) => ({
				url: `/api/v3/classifiers/${queryArg.id}`,
				method: 'PUT',
				body: queryArg.updateClassifierRequest,
			}),
		}),
		updateClassifierAttributeDeclarationAsync: build.mutation<
			UpdateClassifierAttributeDeclarationAsyncApiResponse,
			UpdateClassifierAttributeDeclarationAsyncApiArg
		>({
			query: (queryArg) => ({
				url: `/api/v3/classifier-attribute-declarations/${queryArg.id}`,
				method: 'PUT',
				body: queryArg.updateClassifierAttributeDeclarationRequest,
			}),
		}),
		updateCatalogAsync: build.mutation<UpdateCatalogAsyncApiResponse, UpdateCatalogAsyncApiArg>(
			{
				query: (queryArg) => ({
					url: `/api/v3/catalogs/${queryArg.id}`,
					method: 'PUT',
					body: queryArg.updateCatalogRequest,
				}),
			}
		),
		updateCatalogAttributeDeclarationAsync: build.mutation<
			UpdateCatalogAttributeDeclarationAsyncApiResponse,
			UpdateCatalogAttributeDeclarationAsyncApiArg
		>({
			query: (queryArg) => ({
				url: `/api/v3/catalog-attribute-declarations/${queryArg.id}`,
				method: 'PUT',
				body: queryArg.updateCatalogAttributeDeclarationRequest,
			}),
		}),
		updateRestrictionTableItem: build.mutation<
			UpdateRestrictionTableItemApiResponse,
			UpdateRestrictionTableItemApiArg
		>({
			query: (queryArg) => ({
				url: `/api/v2/restriction-tables/${queryArg.restrictionTableId}/restriction-table-items/${queryArg.id}`,
				method: 'PUT',
				body: queryArg.updateRestrictionTableItemRequest,
			}),
		}),
		getRestrictionTable: build.query<GetRestrictionTableApiResponse, GetRestrictionTableApiArg>(
			{
				query: (queryArg) => ({ url: `/api/v2/restriction-tables/${queryArg.id}` }),
			}
		),
		updateRestrictionTable: build.mutation<
			UpdateRestrictionTableApiResponse,
			UpdateRestrictionTableApiArg
		>({
			query: (queryArg) => ({
				url: `/api/v2/restriction-tables/${queryArg.id}`,
				method: 'PUT',
				body: queryArg.updateRestrictionTableRequest,
			}),
		}),
		updateMeasurement: build.mutation<UpdateMeasurementApiResponse, UpdateMeasurementApiArg>({
			query: (queryArg) => ({
				url: `/api/v2/measurement-groups/${queryArg.measurementGroupId}/measurements/${queryArg.id}`,
				method: 'PUT',
				body: queryArg.updateMeasurementRequest,
			}),
		}),
		getClassifier: build.query<GetClassifierApiResponse, GetClassifierApiArg>({
			query: (queryArg) => ({ url: `/api/v2/classifiers/${queryArg.id}` }),
		}),
		updateClassifier: build.mutation<UpdateClassifierApiResponse, UpdateClassifierApiArg>({
			query: (queryArg) => ({
				url: `/api/v2/classifiers/${queryArg.id}`,
				method: 'PUT',
				body: queryArg.updateClassifierRequest,
			}),
		}),
		rollbackClassifier: build.mutation<RollbackClassifierApiResponse, RollbackClassifierApiArg>(
			{
				query: (queryArg) => ({
					url: `/api/v2/classifiers/${queryArg.id}/rollback`,
					method: 'PUT',
					params: { dateTime: queryArg.dateTime },
				}),
			}
		),
		updateClassifierItem: build.mutation<
			UpdateClassifierItemApiResponse,
			UpdateClassifierItemApiArg
		>({
			query: (queryArg) => ({
				url: `/api/v2/classifiers/${queryArg.classifierId}/classifier-items/${queryArg.id}`,
				method: 'PUT',
				body: queryArg.updateClassifierItemRequest,
			}),
		}),
		rollbackClassifierItem: build.mutation<
			RollbackClassifierItemApiResponse,
			RollbackClassifierItemApiArg
		>({
			query: (queryArg) => ({
				url: `/api/v2/classifier-items/${queryArg.id}/rollback`,
				method: 'PUT',
				params: { dateTime: queryArg.dateTime },
			}),
		}),
		getAttributeDeclaration: build.query<
			GetAttributeDeclarationApiResponse,
			GetAttributeDeclarationApiArg
		>({
			query: (queryArg) => ({
				url: `/api/v2/classifier-attribute-declarations/${queryArg.id}`,
			}),
		}),
		updateClassifierAttributeDeclaration: build.mutation<
			UpdateClassifierAttributeDeclarationApiResponse,
			UpdateClassifierAttributeDeclarationApiArg
		>({
			query: (queryArg) => ({
				url: `/api/v2/classifier-attribute-declarations/${queryArg.id}`,
				method: 'PUT',
				body: queryArg.updateClassifierAttributeDeclarationRequest,
			}),
		}),
		getCatalog: build.query<GetCatalogApiResponse, GetCatalogApiArg>({
			query: (queryArg) => ({ url: `/api/v2/catalogs/${queryArg.id}` }),
		}),
		updateCatalog: build.mutation<UpdateCatalogApiResponse, UpdateCatalogApiArg>({
			query: (queryArg) => ({
				url: `/api/v2/catalogs/${queryArg.id}`,
				method: 'PUT',
				body: queryArg.updateCatalogRequest,
			}),
		}),
		rollbackCatalog: build.mutation<RollbackCatalogApiResponse, RollbackCatalogApiArg>({
			query: (queryArg) => ({
				url: `/api/v2/catalogs/${queryArg.id}/rollback`,
				method: 'PUT',
				params: { dateTime: queryArg.dateTime },
			}),
		}),
		updateCatalogItem: build.mutation<UpdateCatalogItemApiResponse, UpdateCatalogItemApiArg>({
			query: (queryArg) => ({
				url: `/api/v2/catalogs/${queryArg.catalogId}/catalog-items/${queryArg.id}`,
				method: 'PUT',
				body: queryArg.updateCatalogItemRequest,
			}),
		}),
		getCatalogRestrictionTableDeclaration: build.query<
			GetCatalogRestrictionTableDeclarationApiResponse,
			GetCatalogRestrictionTableDeclarationApiArg
		>({
			query: (queryArg) => ({
				url: `/api/v2/catalog-restriction-table-declarations/${queryArg.id}`,
			}),
		}),
		updateCatalogRestrictionTableDeclaration: build.mutation<
			UpdateCatalogRestrictionTableDeclarationApiResponse,
			UpdateCatalogRestrictionTableDeclarationApiArg
		>({
			query: (queryArg) => ({
				url: `/api/v2/catalog-restriction-table-declarations/${queryArg.id}`,
				method: 'PUT',
				body: queryArg.updateCatalogRestrictionTableDeclarationRequest,
			}),
		}),
		rollbackCatalogItem: build.mutation<
			RollbackCatalogItemApiResponse,
			RollbackCatalogItemApiArg
		>({
			query: (queryArg) => ({
				url: `/api/v2/catalog-items/${queryArg.id}/rollback`,
				method: 'PUT',
				params: { dateTime: queryArg.dateTime },
			}),
		}),
		getCatalogAttributeDeclaration: build.query<
			GetCatalogAttributeDeclarationApiResponse,
			GetCatalogAttributeDeclarationApiArg
		>({
			query: (queryArg) => ({ url: `/api/v2/catalog-attribute-declarations/${queryArg.id}` }),
		}),
		updateCatalogAttributeDeclaration: build.mutation<
			UpdateCatalogAttributeDeclarationApiResponse,
			UpdateCatalogAttributeDeclarationApiArg
		>({
			query: (queryArg) => ({
				url: `/api/v2/catalog-attribute-declarations/${queryArg.id}`,
				method: 'PUT',
				body: queryArg.updateCatalogAttributeDeclarationRequest,
			}),
		}),
		rollbackAttribute: build.mutation<RollbackAttributeApiResponse, RollbackAttributeApiArg>({
			query: (queryArg) => ({
				url: `/api/v2/attributes/${queryArg.id}/rollback`,
				method: 'PUT',
				params: { dateTime: queryArg.dateTime },
			}),
		}),
		getAttributeGroup1: build.query<GetAttributeGroup1ApiResponse, GetAttributeGroup1ApiArg>({
			query: (queryArg) => ({ url: `/api/v2/attribute-groups/${queryArg.id}` }),
		}),
		updateAttributeGroup: build.mutation<
			UpdateAttributeGroupApiResponse,
			UpdateAttributeGroupApiArg
		>({
			query: (queryArg) => ({
				url: `/api/v2/attribute-groups/${queryArg.id}`,
				method: 'PUT',
				body: queryArg.updateAttributeGroupRequest,
			}),
		}),
		updateAttribute: build.mutation<UpdateAttributeApiResponse, UpdateAttributeApiArg>({
			query: (queryArg) => ({
				url: `/api/v2/attribute-groups/${queryArg.attributeGroupId}/attributes/${queryArg.id}`,
				method: 'PUT',
				body: queryArg.updateAttributeRequest,
			}),
		}),
		getContextName: build.query<GetContextNameApiResponse, GetContextNameApiArg>({
			query: (queryArg) => ({ url: `/api/v1/context-names/${queryArg.id}` }),
		}),
		updateContextName: build.mutation<UpdateContextNameApiResponse, UpdateContextNameApiArg>({
			query: (queryArg) => ({
				url: `/api/v1/context-names/${queryArg.id}`,
				method: 'PUT',
				body: queryArg.updateContextNameRequest,
			}),
		}),
		getCompatibility: build.query<GetCompatibilityApiResponse, GetCompatibilityApiArg>({
			query: (queryArg) => ({ url: `/api/v1/compatibilities/${queryArg.id}` }),
		}),
		updateCompatibilities: build.mutation<
			UpdateCompatibilitiesApiResponse,
			UpdateCompatibilitiesApiArg
		>({
			query: (queryArg) => ({
				url: `/api/v1/compatibilities/${queryArg.id}`,
				method: 'PUT',
				body: queryArg.updateCompatibilityRequest,
			}),
		}),
		createClassifierAttributeDeclarationsAsync: build.mutation<
			CreateClassifierAttributeDeclarationsAsyncApiResponse,
			CreateClassifierAttributeDeclarationsAsyncApiArg
		>({
			query: (queryArg) => ({
				url: `/api/v3/classifiers/${queryArg.classifierId}/classifier-attribute-declarations`,
				method: 'POST',
				body: queryArg.body,
			}),
		}),
		importXlsxAsync: build.mutation<ImportXlsxAsyncApiResponse, ImportXlsxAsyncApiArg>({
			query: (queryArg) => ({
				url: `/api/v3/catalogs/${queryArg.catalogId}/catalog-items/xlsx-import`,
				method: 'POST',
				body: queryArg.xlsxImportCatalogItemsRequest,
			}),
		}),
		createCatalogAttributeDeclarationsAsync: build.mutation<
			CreateCatalogAttributeDeclarationsAsyncApiResponse,
			CreateCatalogAttributeDeclarationsAsyncApiArg
		>({
			query: (queryArg) => ({
				url: `/api/v3/catalogs/${queryArg.catalogId}/catalog-attribute-declarations`,
				method: 'POST',
				body: queryArg.body,
			}),
		}),
		rollback: build.mutation<RollbackApiResponse, RollbackApiArg>({
			query: (queryArg) => ({
				url: '/api/v2/transactions/rollback',
				method: 'POST',
				params: { transactionId: queryArg.transactionId },
			}),
		}),
		commit: build.mutation<CommitApiResponse, CommitApiArg>({
			query: () => ({ url: '/api/v2/transactions/commit', method: 'POST' }),
		}),
		getRestrictionTables: build.query<
			GetRestrictionTablesApiResponse,
			GetRestrictionTablesApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v2/restriction-tables',
				params: { ids: queryArg.ids },
			}),
		}),
		createRestrictionTable: build.mutation<
			CreateRestrictionTableApiResponse,
			CreateRestrictionTableApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v2/restriction-tables',
				method: 'POST',
				body: queryArg.createRestrictionTableRequest,
			}),
		}),
		deleteRestrictionTable: build.mutation<
			DeleteRestrictionTableApiResponse,
			DeleteRestrictionTableApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v2/restriction-tables',
				method: 'DELETE',
				params: { ids: queryArg.ids },
			}),
		}),
		getRestrictionTableItems: build.query<
			GetRestrictionTableItemsApiResponse,
			GetRestrictionTableItemsApiArg
		>({
			query: (queryArg) => ({
				url: `/api/v2/restriction-tables/${queryArg.restrictionTableId}/restriction-table-items`,
				params: { ids: queryArg.ids },
			}),
		}),
		createRestrictionTableItem: build.mutation<
			CreateRestrictionTableItemApiResponse,
			CreateRestrictionTableItemApiArg
		>({
			query: (queryArg) => ({
				url: `/api/v2/restriction-tables/${queryArg.restrictionTableId}/restriction-table-items`,
				method: 'POST',
				body: queryArg.createRestrictionTableItemRequest,
			}),
		}),
		getMeasurementGroups: build.query<
			GetMeasurementGroupsApiResponse,
			GetMeasurementGroupsApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v2/measurement-groups',
				params: { ids: queryArg.ids },
			}),
		}),
		createMeasurementGroup: build.mutation<
			CreateMeasurementGroupApiResponse,
			CreateMeasurementGroupApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v2/measurement-groups',
				method: 'POST',
				body: queryArg.createMeasurementGroupRequest,
			}),
		}),
		deleteMeasurementGroup: build.mutation<
			DeleteMeasurementGroupApiResponse,
			DeleteMeasurementGroupApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v2/measurement-groups',
				method: 'DELETE',
				params: { ids: queryArg.ids },
			}),
		}),
		getMeasurements: build.query<GetMeasurementsApiResponse, GetMeasurementsApiArg>({
			query: (queryArg) => ({
				url: `/api/v2/measurement-groups/${queryArg.measurementGroupId}/measurements`,
				params: { ids: queryArg.ids },
			}),
		}),
		createMeasurement: build.mutation<CreateMeasurementApiResponse, CreateMeasurementApiArg>({
			query: (queryArg) => ({
				url: `/api/v2/measurement-groups/${queryArg.measurementGroupId}/measurements`,
				method: 'POST',
				body: queryArg.createMeasurementRequest,
			}),
		}),
		getClassifiers: build.query<GetClassifiersApiResponse, GetClassifiersApiArg>({
			query: (queryArg) => ({ url: '/api/v2/classifiers', params: { ids: queryArg.ids } }),
		}),
		createClassifier: build.mutation<CreateClassifierApiResponse, CreateClassifierApiArg>({
			query: (queryArg) => ({
				url: '/api/v2/classifiers',
				method: 'POST',
				body: queryArg.createClassifierRequest,
			}),
		}),
		deleteClassifier: build.mutation<DeleteClassifierApiResponse, DeleteClassifierApiArg>({
			query: (queryArg) => ({
				url: '/api/v2/classifiers',
				method: 'DELETE',
				params: { ids: queryArg.ids },
			}),
		}),
		createClassifierItem: build.mutation<
			CreateClassifierItemApiResponse,
			CreateClassifierItemApiArg
		>({
			query: (queryArg) => ({
				url: `/api/v2/classifiers/${queryArg.classifierId}/classifier-items`,
				method: 'POST',
				body: queryArg.createClassifierItemRequest,
			}),
		}),
		getClassifierAttributeDeclarations: build.query<
			GetClassifierAttributeDeclarationsApiResponse,
			GetClassifierAttributeDeclarationsApiArg
		>({
			query: (queryArg) => ({
				url: `/api/v2/classifiers/${queryArg.classifierId}/classifier-attribute-declarations`,
				params: { ids: queryArg.ids },
			}),
		}),
		createClassifierAttributeDeclarations: build.mutation<
			CreateClassifierAttributeDeclarationsApiResponse,
			CreateClassifierAttributeDeclarationsApiArg
		>({
			query: (queryArg) => ({
				url: `/api/v2/classifiers/${queryArg.classifierId}/classifier-attribute-declarations`,
				method: 'POST',
				body: queryArg.body,
			}),
		}),
		getCatalogs: build.query<GetCatalogsApiResponse, GetCatalogsApiArg>({
			query: (queryArg) => ({
				url: '/api/v2/catalogs',
				params: { parentIds: queryArg.parentIds, root: queryArg.root, ids: queryArg.ids },
			}),
		}),
		createCatalog: build.mutation<CreateCatalogApiResponse, CreateCatalogApiArg>({
			query: (queryArg) => ({
				url: '/api/v2/catalogs',
				method: 'POST',
				body: queryArg.createCatalogRequest,
			}),
		}),
		deleteCatalogs: build.mutation<DeleteCatalogsApiResponse, DeleteCatalogsApiArg>({
			query: (queryArg) => ({
				url: '/api/v2/catalogs',
				method: 'DELETE',
				params: { ids: queryArg.ids },
			}),
		}),
		getCatalogRestrictionTableDeclarations: build.query<
			GetCatalogRestrictionTableDeclarationsApiResponse,
			GetCatalogRestrictionTableDeclarationsApiArg
		>({
			query: (queryArg) => ({
				url: `/api/v2/catalogs/${queryArg.catalogId}/catalog-restriction-table-declarations`,
				params: { ids: queryArg.ids },
			}),
		}),
		createCatalogRestrictionTableDeclarations: build.mutation<
			CreateCatalogRestrictionTableDeclarationsApiResponse,
			CreateCatalogRestrictionTableDeclarationsApiArg
		>({
			query: (queryArg) => ({
				url: `/api/v2/catalogs/${queryArg.catalogId}/catalog-restriction-table-declarations`,
				method: 'POST',
				body: queryArg.body,
			}),
		}),
		createCatalogItem: build.mutation<CreateCatalogItemApiResponse, CreateCatalogItemApiArg>({
			query: (queryArg) => ({
				url: `/api/v2/catalogs/${queryArg.catalogId}/catalog-items`,
				method: 'POST',
				body: queryArg.createCatalogItemRequest,
			}),
		}),
		importXlsx: build.mutation<ImportXlsxApiResponse, ImportXlsxApiArg>({
			query: (queryArg) => ({
				url: `/api/v2/catalogs/${queryArg.catalogId}/catalog-items/xlsx-import`,
				method: 'POST',
				body: queryArg.xlsxImportCatalogItemsRequest,
			}),
		}),
		getCatalogAttributeDeclarations: build.query<
			GetCatalogAttributeDeclarationsApiResponse,
			GetCatalogAttributeDeclarationsApiArg
		>({
			query: (queryArg) => ({
				url: `/api/v2/catalogs/${queryArg.catalogId}/catalog-attribute-declarations`,
				params: { ids: queryArg.ids },
			}),
		}),
		createCatalogAttributeDeclarations: build.mutation<
			CreateCatalogAttributeDeclarationsApiResponse,
			CreateCatalogAttributeDeclarationsApiArg
		>({
			query: (queryArg) => ({
				url: `/api/v2/catalogs/${queryArg.catalogId}/catalog-attribute-declarations`,
				method: 'POST',
				body: queryArg.body,
			}),
		}),
		getAttributeGroup: build.query<GetAttributeGroupApiResponse, GetAttributeGroupApiArg>({
			query: (queryArg) => ({
				url: '/api/v2/attribute-groups',
				params: { parentIds: queryArg.parentIds, root: queryArg.root, ids: queryArg.ids },
			}),
		}),
		createAttributeGroup: build.mutation<
			CreateAttributeGroupApiResponse,
			CreateAttributeGroupApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v2/attribute-groups',
				method: 'POST',
				body: queryArg.createAttributeGroupRequest,
			}),
		}),
		deleteAttributeGroup: build.mutation<
			DeleteAttributeGroupApiResponse,
			DeleteAttributeGroupApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v2/attribute-groups',
				method: 'DELETE',
				params: { ids: queryArg.ids },
			}),
		}),
		createAttribute: build.mutation<CreateAttributeApiResponse, CreateAttributeApiArg>({
			query: (queryArg) => ({
				url: `/api/v2/attribute-groups/${queryArg.attributeGroupId}/attributes`,
				method: 'POST',
				body: queryArg.createAttributeRequest,
			}),
		}),
		uploadFile: build.mutation<UploadFileApiResponse, UploadFileApiArg>({
			query: (queryArg) => ({
				url: '/api/v1/files',
				method: 'POST',
				body: queryArg.uploadFileFrontRequest,
			}),
		}),
		getContextNames: build.query<GetContextNamesApiResponse, GetContextNamesApiArg>({
			query: (queryArg) => ({
				url: '/api/v1/context-names',
				params: {
					limit: queryArg.limit,
					page: queryArg.page,
					sortBy: queryArg.sortBy,
					sortType: queryArg.sortType,
					search: queryArg.search,
				},
			}),
		}),
		createContextName: build.mutation<CreateContextNameApiResponse, CreateContextNameApiArg>({
			query: (queryArg) => ({
				url: '/api/v1/context-names',
				method: 'POST',
				body: queryArg.createContextNameRequest,
			}),
		}),
		deleteContextNames: build.mutation<DeleteContextNamesApiResponse, DeleteContextNamesApiArg>(
			{
				query: (queryArg) => ({
					url: '/api/v1/context-names',
					method: 'DELETE',
					params: { ids: queryArg.ids },
				}),
			}
		),
		getCompatibilityItems: build.query<
			GetCompatibilityItemsApiResponse,
			GetCompatibilityItemsApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v1/compatibility-items',
				params: {
					limit: queryArg.limit,
					page: queryArg.page,
					sortBy: queryArg.sortBy,
					sortType: queryArg.sortType,
					itemId: queryArg.itemId,
					contextNameId: queryArg.contextNameId,
					contextNameDirection: queryArg.contextNameDirection,
				},
			}),
		}),
		createCompatibilityItems: build.mutation<
			CreateCompatibilityItemsApiResponse,
			CreateCompatibilityItemsApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v1/compatibility-items',
				method: 'POST',
				body: queryArg.createCompatibilityItemRequest,
			}),
		}),
		deleteCompatibilityItems: build.mutation<
			DeleteCompatibilityItemsApiResponse,
			DeleteCompatibilityItemsApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v1/compatibility-items',
				method: 'DELETE',
				params: { ids: queryArg.ids },
			}),
		}),
		getCompatibilities: build.query<GetCompatibilitiesApiResponse, GetCompatibilitiesApiArg>({
			query: (queryArg) => ({
				url: '/api/v1/compatibilities',
				params: {
					entityId: queryArg.entityId,
					entityType: queryArg.entityType,
					contextNameId: queryArg.contextNameId,
				},
			}),
		}),
		createCompatibility: build.mutation<
			CreateCompatibilityApiResponse,
			CreateCompatibilityApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v1/compatibilities',
				method: 'POST',
				body: queryArg.createCompatibilityRequest,
			}),
		}),
		deleteCompatibilities: build.mutation<
			DeleteCompatibilitiesApiResponse,
			DeleteCompatibilitiesApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v1/compatibilities',
				method: 'DELETE',
				params: { ids: queryArg.ids },
			}),
		}),
		searchCompatibilities: build.mutation<
			SearchCompatibilitiesApiResponse,
			SearchCompatibilitiesApiArg
		>({
			query: (queryArg) => ({
				url: `/api/v1/compatibilities/${queryArg.id}/search/start`,
				method: 'POST',
			}),
		}),
		updateMeasurementAsync: build.mutation<
			UpdateMeasurementAsyncApiResponse,
			UpdateMeasurementAsyncApiArg
		>({
			query: (queryArg) => ({
				url: `/api/v3/measurement-groups/${queryArg.measurementGroupId}/measurements/${queryArg.id}`,
				method: 'PATCH',
				body: queryArg.updateMeasurementRequest,
			}),
		}),
		getMeasurementGroup: build.query<GetMeasurementGroupApiResponse, GetMeasurementGroupApiArg>(
			{
				query: (queryArg) => ({
					url: `/api/v2/measurement-groups/${queryArg.id}`,
					params: { includeChildren: queryArg.includeChildren },
				}),
			}
		),
		updateMeasurementGroup: build.mutation<
			UpdateMeasurementGroupApiResponse,
			UpdateMeasurementGroupApiArg
		>({
			query: (queryArg) => ({
				url: `/api/v2/measurement-groups/${queryArg.id}`,
				method: 'PATCH',
				body: queryArg.updateMeasurementGroupRequest,
			}),
		}),
		getCatalogsV3: build.query<GetCatalogsV3ApiResponse, GetCatalogsV3ApiArg>({
			query: (queryArg) => ({
				url: '/api/v3/catalogs',
				params: {
					parentIds: queryArg.parentIds,
					root: queryArg.root,
					ids: queryArg.ids,
					searchSubstring: queryArg.searchSubstring,
					limit: queryArg.limit,
					page: queryArg.page,
					sortBy: queryArg.sortBy,
					sortType: queryArg.sortType,
				},
			}),
		}),
		deleteCatalogsAsync: build.mutation<
			DeleteCatalogsAsyncApiResponse,
			DeleteCatalogsAsyncApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v3/catalogs',
				method: 'DELETE',
				params: { ids: queryArg.ids },
			}),
		}),
		getTransactionsDetails: build.query<
			GetTransactionsDetailsApiResponse,
			GetTransactionsDetailsApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v2/transactions',
				params: {
					limit: queryArg.limit,
					page: queryArg.page,
					sortBy: queryArg.sortBy,
					sortType: queryArg.sortType,
					transactionIds: queryArg.transactionIds,
					userId: queryArg.userId,
					statuses: queryArg.statuses,
					includeEvents: queryArg.includeEvents,
				},
			}),
		}),
		getTransactionDetails: build.query<
			GetTransactionDetailsApiResponse,
			GetTransactionDetailsApiArg
		>({
			query: (queryArg) => ({
				url: `/api/v2/transactions/${queryArg.id}`,
				params: {
					includeEvents: queryArg.includeEvents,
					entityTypes: queryArg.entityTypes,
				},
			}),
		}),
		getRestrictionTableItem: build.query<
			GetRestrictionTableItemApiResponse,
			GetRestrictionTableItemApiArg
		>({
			query: (queryArg) => ({ url: `/api/v2/restriction-table-items/${queryArg.id}` }),
		}),
		getMeasurement: build.query<GetMeasurementApiResponse, GetMeasurementApiArg>({
			query: (queryArg) => ({ url: '/api/v2/measurements', params: { ids: queryArg.ids } }),
		}),
		deleteMeasurement: build.mutation<DeleteMeasurementApiResponse, DeleteMeasurementApiArg>({
			query: (queryArg) => ({
				url: '/api/v2/measurements',
				method: 'DELETE',
				params: { ids: queryArg.ids },
			}),
		}),
		getMeasurement1: build.query<GetMeasurement1ApiResponse, GetMeasurement1ApiArg>({
			query: (queryArg) => ({ url: `/api/v2/measurements/${queryArg.id}` }),
		}),
		convert: build.query<ConvertApiResponse, ConvertApiArg>({
			query: (queryArg) => ({
				url: '/api/v2/measurements/conversion',
				params: {
					fromMeasurementId: queryArg.fromMeasurementId,
					toMeasurementId: queryArg.toMeasurementId,
					value: queryArg.value,
				},
			}),
		}),
		getClassifierItemTree: build.query<
			GetClassifierItemTreeApiResponse,
			GetClassifierItemTreeApiArg
		>({
			query: (queryArg) => ({
				url: `/api/v2/classifiers/${queryArg.classifierId}/classifier-items/tree`,
				params: {
					fromItemId: queryArg.fromItemId,
					includePathToItemId: queryArg.includePathToItemId,
					sortBy: queryArg.sortBy,
					sortType: queryArg.sortType,
				},
			}),
		}),
		getClassifierItems: build.query<GetClassifierItemsApiResponse, GetClassifierItemsApiArg>({
			query: (queryArg) => ({
				url: '/api/v2/classifier-items',
				params: {
					limit: queryArg.limit,
					page: queryArg.page,
					sortBy: queryArg.sortBy,
					sortType: queryArg.sortType,
					classifierId: queryArg.classifierId,
					statuses: queryArg.statuses,
					parentItemId: queryArg.parentItemId,
					root: queryArg.root,
					ids: queryArg.ids,
					searchSubstring: queryArg.searchSubstring,
					searchDetails: queryArg.searchDetails,
				},
			}),
		}),
		deleteClassifierItem: build.mutation<
			DeleteClassifierItemApiResponse,
			DeleteClassifierItemApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v2/classifier-items',
				method: 'DELETE',
				params: { ids: queryArg.ids },
			}),
		}),
		getClassifierItem: build.query<GetClassifierItemApiResponse, GetClassifierItemApiArg>({
			query: (queryArg) => ({ url: `/api/v2/classifier-items/${queryArg.id}` }),
		}),
		getItemsByClassifierItemId: build.query<
			GetItemsByClassifierItemIdApiResponse,
			GetItemsByClassifierItemIdApiArg
		>({
			query: (queryArg) => ({
				url: `/api/v2/classifier-items/${queryArg.classifierItemId}/catalog-items`,
			}),
		}),
		getCatalogTree: build.query<GetCatalogTreeApiResponse, GetCatalogTreeApiArg>({
			query: (queryArg) => ({
				url: `/api/v2/catalogs/${queryArg.id}/tree`,
				params: {
					direction: queryArg.direction,
					includeChildren: queryArg.includeChildren,
					searchSubstring: queryArg.searchSubstring,
					sortBy: queryArg.sortBy,
					sortType: queryArg.sortType,
				},
			}),
		}),
		getCatalogItems: build.query<GetCatalogItemsApiResponse, GetCatalogItemsApiArg>({
			query: (queryArg) => ({
				url: '/api/v2/catalog-items',
				params: {
					limit: queryArg.limit,
					page: queryArg.page,
					sortBy: queryArg.sortBy,
					sortType: queryArg.sortType,
					catalogIds: queryArg.catalogIds,
					statuses: queryArg.statuses,
					includeSubCatalogs: queryArg.includeSubCatalogs,
					attributeDeclarationIds: queryArg.attributeDeclarationIds,
					ids: queryArg.ids,
					searchSubstring: queryArg.searchSubstring,
					searchDetails: queryArg.searchDetails,
				},
			}),
		}),
		deleteCatalogItem: build.mutation<DeleteCatalogItemApiResponse, DeleteCatalogItemApiArg>({
			query: (queryArg) => ({
				url: '/api/v2/catalog-items',
				method: 'DELETE',
				params: { ids: queryArg.ids },
			}),
		}),
		getCatalogItem: build.query<GetCatalogItemApiResponse, GetCatalogItemApiArg>({
			query: (queryArg) => ({ url: `/api/v2/catalog-items/${queryArg.id}` }),
		}),
		getAttributes: build.query<GetAttributesApiResponse, GetAttributesApiArg>({
			query: (queryArg) => ({
				url: '/api/v2/attributes',
				params: {
					limit: queryArg.limit,
					page: queryArg.page,
					sortBy: queryArg.sortBy,
					sortType: queryArg.sortType,
					type: queryArg['type'],
					ids: queryArg.ids,
					attributeGroupIds: queryArg.attributeGroupIds,
					includeSubGroups: queryArg.includeSubGroups,
				},
			}),
		}),
		deleteAttribute: build.mutation<DeleteAttributeApiResponse, DeleteAttributeApiArg>({
			query: (queryArg) => ({
				url: '/api/v2/attributes',
				method: 'DELETE',
				params: { ids: queryArg.ids },
			}),
		}),
		getAttribute: build.query<GetAttributeApiResponse, GetAttributeApiArg>({
			query: (queryArg) => ({ url: `/api/v2/attributes/${queryArg.id}` }),
		}),
		getCatalogsByAttribute: build.query<
			GetCatalogsByAttributeApiResponse,
			GetCatalogsByAttributeApiArg
		>({
			query: (queryArg) => ({ url: `/api/v2/attributes/${queryArg.attributeId}/catalogs` }),
		}),
		getClassifierByItemId: build.query<
			GetClassifierByItemIdApiResponse,
			GetClassifierByItemIdApiArg
		>({
			query: (queryArg) => ({
				url: `/api/v2/attributes/${queryArg.attributeId}/catalog-items`,
				params: { classifierItemId: queryArg.classifierItemId },
			}),
		}),
		getAttributeGroupTree: build.query<
			GetAttributeGroupTreeApiResponse,
			GetAttributeGroupTreeApiArg
		>({
			query: (queryArg) => ({
				url: `/api/v2/attribute-groups/${queryArg.id}/tree`,
				params: {
					direction: queryArg.direction,
					includeChildren: queryArg.includeChildren,
				},
			}),
		}),
		getPresignedFileUrl: build.query<GetPresignedFileUrlApiResponse, GetPresignedFileUrlApiArg>(
			{
				query: (queryArg) => ({ url: `/api/v1/files/${queryArg.id}/presigned-url` }),
			}
		),
		downloadFile: build.query<DownloadFileApiResponse, DownloadFileApiArg>({
			query: (queryArg) => ({ url: `/api/v1/files/${queryArg.id}/download` }),
		}),
		getEntityVersions: build.query<GetEntityVersionsApiResponse, GetEntityVersionsApiArg>({
			query: (queryArg) => ({
				url: `/api/v1/entities/${queryArg.entityId}/versions`,
				headers: { Authorization: queryArg.authorization },
				params: {
					entityType: queryArg.entityType,
					transactionIds: queryArg.transactionIds,
				},
			}),
		}),
		getEntityTransactions: build.query<
			GetEntityTransactionsApiResponse,
			GetEntityTransactionsApiArg
		>({
			query: (queryArg) => ({
				url: `/api/v1/entities/${queryArg.entityId}/transactions`,
				params: {
					entityType: queryArg.entityType,
					limit: queryArg.limit,
					page: queryArg.page,
					sortBy: queryArg.sortBy,
					sortType: queryArg.sortType,
				},
			}),
		}),
		getEvents: build.query<GetEventsApiResponse, GetEventsApiArg>({
			query: (queryArg) => ({
				url: `/api/v1/entities/${queryArg.entityId}/events`,
				params: {
					entityType: queryArg.entityType,
					limit: queryArg.limit,
					page: queryArg.page,
				},
			}),
		}),
		getEntityDifference: build.query<GetEntityDifferenceApiResponse, GetEntityDifferenceApiArg>(
			{
				query: (queryArg) => ({
					url: `/api/v1/entities/${queryArg.entityId}/difference`,
					params: {
						entityType: queryArg.entityType,
						fromDate: queryArg.fromDate,
						toDate: queryArg.toDate,
					},
				}),
			}
		),
		getEntityChanges: build.query<GetEntityChangesApiResponse, GetEntityChangesApiArg>({
			query: (queryArg) => ({
				url: `/api/v1/entities/${queryArg.entityId}/changes`,
				params: {
					eventType: queryArg.eventType,
					transactionId: queryArg.transactionId,
					userId: queryArg.userId,
					fromDate: queryArg.fromDate,
					toDate: queryArg.toDate,
					limit: queryArg.limit,
					page: queryArg.page,
					sortBy: queryArg.sortBy,
					sortType: queryArg.sortType,
				},
			}),
		}),
		deleteClassifierAsync: build.mutation<
			DeleteClassifierAsyncApiResponse,
			DeleteClassifierAsyncApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v3/classifiers',
				method: 'DELETE',
				params: { ids: queryArg.ids },
			}),
		}),
		deleteClassifierAttributeDeclarationAsync: build.mutation<
			DeleteClassifierAttributeDeclarationAsyncApiResponse,
			DeleteClassifierAttributeDeclarationAsyncApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v3/classifier-attribute-declarations',
				method: 'DELETE',
				params: { ids: queryArg.ids },
			}),
		}),
		deleteCatalogAttributeDeclarationAsync: build.mutation<
			DeleteCatalogAttributeDeclarationAsyncApiResponse,
			DeleteCatalogAttributeDeclarationAsyncApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v3/catalog-attribute-declarations',
				method: 'DELETE',
				params: { ids: queryArg.ids },
			}),
		}),
		deleteAttributeAsync: build.mutation<
			DeleteAttributeAsyncApiResponse,
			DeleteAttributeAsyncApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v3/attributes',
				method: 'DELETE',
				params: { ids: queryArg.ids },
			}),
		}),
		deleteAttributeGroupAsync: build.mutation<
			DeleteAttributeGroupAsyncApiResponse,
			DeleteAttributeGroupAsyncApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v3/attribute-groups',
				method: 'DELETE',
				params: { ids: queryArg.ids },
			}),
		}),
		deleteRestrictionTableItems: build.mutation<
			DeleteRestrictionTableItemsApiResponse,
			DeleteRestrictionTableItemsApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v2/restriction-table-items',
				method: 'DELETE',
				params: { ids: queryArg.ids },
			}),
		}),
		deleteClassifierAttributeDeclaration: build.mutation<
			DeleteClassifierAttributeDeclarationApiResponse,
			DeleteClassifierAttributeDeclarationApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v2/classifier-attribute-declarations',
				method: 'DELETE',
				params: { ids: queryArg.ids },
			}),
		}),
		deleteCatalogRestrictionTableDeclaration: build.mutation<
			DeleteCatalogRestrictionTableDeclarationApiResponse,
			DeleteCatalogRestrictionTableDeclarationApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v2/catalog-restriction-table-declarations',
				method: 'DELETE',
				params: { ids: queryArg.ids },
			}),
		}),
		deleteCatalogAttributeDeclaration: build.mutation<
			DeleteCatalogAttributeDeclarationApiResponse,
			DeleteCatalogAttributeDeclarationApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v2/catalog-attribute-declarations',
				method: 'DELETE',
				params: { ids: queryArg.ids },
			}),
		}),
	}),
	overrideExisting: false,
});
export { injectedRtkApi as mdmgApi };
export type UpdateClassifierAsyncApiResponse = /** status 200 OK */ BackgroundTaskStartDto;
export type UpdateClassifierAsyncApiArg = {
	id: string;
	updateClassifierRequest: UpdateClassifierRequest;
};
export type UpdateClassifierAttributeDeclarationAsyncApiResponse =
	/** status 200 OK */ BackgroundTaskStartDto;
export type UpdateClassifierAttributeDeclarationAsyncApiArg = {
	id: string;
	updateClassifierAttributeDeclarationRequest: UpdateClassifierAttributeDeclarationRequest;
};
export type UpdateCatalogAsyncApiResponse = /** status 200 OK */ BackgroundTaskStartDto;
export type UpdateCatalogAsyncApiArg = {
	id: string;
	updateCatalogRequest: UpdateCatalogRequest;
};
export type UpdateCatalogAttributeDeclarationAsyncApiResponse =
	/** status 200 OK */ BackgroundTaskStartDto;
export type UpdateCatalogAttributeDeclarationAsyncApiArg = {
	id: string;
	updateCatalogAttributeDeclarationRequest: UpdateCatalogAttributeDeclarationRequest;
};
export type UpdateRestrictionTableItemApiResponse =
	/** status 200 OK */ RestrictionTableItemResponse;
export type UpdateRestrictionTableItemApiArg = {
	restrictionTableId: string;
	id: string;
	updateRestrictionTableItemRequest: UpdateRestrictionTableItemRequest;
};
export type GetRestrictionTableApiResponse = /** status 200 OK */ RestrictionTableResponse;
export type GetRestrictionTableApiArg = {
	id: string;
};
export type UpdateRestrictionTableApiResponse = /** status 200 OK */ RestrictionTableResponse;
export type UpdateRestrictionTableApiArg = {
	id: string;
	updateRestrictionTableRequest: UpdateRestrictionTableRequest;
};
export type UpdateMeasurementApiResponse = /** status 200 OK */ MeasurementResponse;
export type UpdateMeasurementApiArg = {
	measurementGroupId: string;
	id: string;
	updateMeasurementRequest: UpdateMeasurementRequest;
};
export type GetClassifierApiResponse = /** status 200 OK */ ClassifierResponse;
export type GetClassifierApiArg = {
	id: string;
};
export type UpdateClassifierApiResponse = /** status 200 OK */ ClassifierResponse;
export type UpdateClassifierApiArg = {
	id: string;
	updateClassifierRequest: UpdateClassifierRequest;
};
export type RollbackClassifierApiResponse = /** status 200 OK */ ClassifierResponse;
export type RollbackClassifierApiArg = {
	id: string;
	dateTime: string;
};
export type UpdateClassifierItemApiResponse = /** status 200 OK */ ClassifierItemResponse;
export type UpdateClassifierItemApiArg = {
	classifierId: string;
	id: string;
	updateClassifierItemRequest: UpdateClassifierItemRequest;
};
export type RollbackClassifierItemApiResponse = /** status 200 OK */ ClassifierItemResponse;
export type RollbackClassifierItemApiArg = {
	id: string;
	dateTime: string;
};
export type GetAttributeDeclarationApiResponse =
	/** status 200 OK */ ClassifierAttributeDeclarationResponse;
export type GetAttributeDeclarationApiArg = {
	id: string;
};
export type UpdateClassifierAttributeDeclarationApiResponse =
	/** status 200 OK */ ClassifierAttributeDeclarationResponse;
export type UpdateClassifierAttributeDeclarationApiArg = {
	id: string;
	updateClassifierAttributeDeclarationRequest: UpdateClassifierAttributeDeclarationRequest;
};
export type GetCatalogApiResponse = /** status 200 OK */ CatalogResponse;
export type GetCatalogApiArg = {
	id: string;
};
export type UpdateCatalogApiResponse = /** status 200 OK */ CatalogResponse;
export type UpdateCatalogApiArg = {
	id: string;
	updateCatalogRequest: UpdateCatalogRequest;
};
export type RollbackCatalogApiResponse = /** status 200 OK */ CatalogResponse;
export type RollbackCatalogApiArg = {
	id: string;
	dateTime: string;
};
export type UpdateCatalogItemApiResponse = /** status 200 OK */ CatalogItemResponse;
export type UpdateCatalogItemApiArg = {
	id: string;
	catalogId: string;
	updateCatalogItemRequest: UpdateCatalogItemRequest;
};
export type GetCatalogRestrictionTableDeclarationApiResponse =
	/** status 200 OK */ CatalogRestrictionTableDeclarationResponse;
export type GetCatalogRestrictionTableDeclarationApiArg = {
	id: string;
};
export type UpdateCatalogRestrictionTableDeclarationApiResponse =
	/** status 200 OK */ CatalogRestrictionTableDeclarationResponse;
export type UpdateCatalogRestrictionTableDeclarationApiArg = {
	id: string;
	updateCatalogRestrictionTableDeclarationRequest: UpdateCatalogRestrictionTableDeclarationRequest;
};
export type RollbackCatalogItemApiResponse = /** status 200 OK */ CatalogItemResponse;
export type RollbackCatalogItemApiArg = {
	id: string;
	dateTime: string;
};
export type GetCatalogAttributeDeclarationApiResponse =
	/** status 200 OK */ CatalogAttributeDeclarationResponse;
export type GetCatalogAttributeDeclarationApiArg = {
	id: string;
};
export type UpdateCatalogAttributeDeclarationApiResponse =
	/** status 200 OK */ CatalogAttributeDeclarationResponse;
export type UpdateCatalogAttributeDeclarationApiArg = {
	id: string;
	updateCatalogAttributeDeclarationRequest: UpdateCatalogAttributeDeclarationRequest;
};
export type RollbackAttributeApiResponse = /** status 200 OK */ AttributeResponse;
export type RollbackAttributeApiArg = {
	id: string;
	dateTime: string;
};
export type GetAttributeGroup1ApiResponse = /** status 200 OK */ AttributeGroupResponse;
export type GetAttributeGroup1ApiArg = {
	id: string;
};
export type UpdateAttributeGroupApiResponse = /** status 200 OK */ AttributeGroupResponse;
export type UpdateAttributeGroupApiArg = {
	id: string;
	updateAttributeGroupRequest: UpdateAttributeGroupRequest;
};
export type UpdateAttributeApiResponse = /** status 200 OK */ AttributeResponse;
export type UpdateAttributeApiArg = {
	attributeGroupId: string;
	id: string;
	updateAttributeRequest: UpdateAttributeRequest;
};
export type GetContextNameApiResponse = /** status 200 OK */ ContextNameResponse;
export type GetContextNameApiArg = {
	id: string;
};
export type UpdateContextNameApiResponse = /** status 200 OK */ ContextNameResponse;
export type UpdateContextNameApiArg = {
	id: string;
	updateContextNameRequest: UpdateContextNameRequest;
};
export type GetCompatibilityApiResponse = /** status 200 OK */ CompatibilityResponse;
export type GetCompatibilityApiArg = {
	id: string;
};
export type UpdateCompatibilitiesApiResponse = /** status 200 OK */ CompatibilityResponse;
export type UpdateCompatibilitiesApiArg = {
	id: string;
	updateCompatibilityRequest: UpdateCompatibilityRequest;
};
export type CreateClassifierAttributeDeclarationsAsyncApiResponse =
	/** status 200 OK */ BackgroundTaskStartDto;
export type CreateClassifierAttributeDeclarationsAsyncApiArg = {
	classifierId: string;
	body: CreateClassifierAttributeDeclarationRequest[];
};
export type ImportXlsxAsyncApiResponse = /** status 200 OK */ BackgroundTaskStartDto;
export type ImportXlsxAsyncApiArg = {
	catalogId: string;
	xlsxImportCatalogItemsRequest: XlsxImportCatalogItemsRequest;
};
export type CreateCatalogAttributeDeclarationsAsyncApiResponse =
	/** status 200 OK */ BackgroundTaskStartDto;
export type CreateCatalogAttributeDeclarationsAsyncApiArg = {
	catalogId: string;
	body: CreateCatalogAttributeDeclarationRequest[];
};
export type RollbackApiResponse = unknown;
export type RollbackApiArg = {
	/** ID транзакции, на которую происходит откат последней транзакции */
	transactionId: string;
};
export type CommitApiResponse = unknown;
export type CommitApiArg = void;
export type GetRestrictionTablesApiResponse = /** status 200 OK */ RestrictionTableResponse[];
export type GetRestrictionTablesApiArg = {
	ids?: string[];
};
export type CreateRestrictionTableApiResponse = /** status 200 OK */ RestrictionTableResponse;
export type CreateRestrictionTableApiArg = {
	createRestrictionTableRequest: CreateRestrictionTableRequest;
};
export type DeleteRestrictionTableApiResponse = /** status 200 OK */
	| '100 CONTINUE'
	| '101 SWITCHING_PROTOCOLS'
	| '102 PROCESSING'
	| '103 EARLY_HINTS'
	| '103 CHECKPOINT'
	| '200 OK'
	| '201 CREATED'
	| '202 ACCEPTED'
	| '203 NON_AUTHORITATIVE_INFORMATION'
	| '204 NO_CONTENT'
	| '205 RESET_CONTENT'
	| '206 PARTIAL_CONTENT'
	| '207 MULTI_STATUS'
	| '208 ALREADY_REPORTED'
	| '226 IM_USED'
	| '300 MULTIPLE_CHOICES'
	| '301 MOVED_PERMANENTLY'
	| '302 FOUND'
	| '302 MOVED_TEMPORARILY'
	| '303 SEE_OTHER'
	| '304 NOT_MODIFIED'
	| '305 USE_PROXY'
	| '307 TEMPORARY_REDIRECT'
	| '308 PERMANENT_REDIRECT'
	| '400 BAD_REQUEST'
	| '401 UNAUTHORIZED'
	| '402 PAYMENT_REQUIRED'
	| '403 FORBIDDEN'
	| '404 NOT_FOUND'
	| '405 METHOD_NOT_ALLOWED'
	| '406 NOT_ACCEPTABLE'
	| '407 PROXY_AUTHENTICATION_REQUIRED'
	| '408 REQUEST_TIMEOUT'
	| '409 CONFLICT'
	| '410 GONE'
	| '411 LENGTH_REQUIRED'
	| '412 PRECONDITION_FAILED'
	| '413 PAYLOAD_TOO_LARGE'
	| '413 REQUEST_ENTITY_TOO_LARGE'
	| '414 URI_TOO_LONG'
	| '414 REQUEST_URI_TOO_LONG'
	| '415 UNSUPPORTED_MEDIA_TYPE'
	| '416 REQUESTED_RANGE_NOT_SATISFIABLE'
	| '417 EXPECTATION_FAILED'
	| '418 I_AM_A_TEAPOT'
	| '419 INSUFFICIENT_SPACE_ON_RESOURCE'
	| '420 METHOD_FAILURE'
	| '421 DESTINATION_LOCKED'
	| '422 UNPROCESSABLE_ENTITY'
	| '423 LOCKED'
	| '424 FAILED_DEPENDENCY'
	| '425 TOO_EARLY'
	| '426 UPGRADE_REQUIRED'
	| '428 PRECONDITION_REQUIRED'
	| '429 TOO_MANY_REQUESTS'
	| '431 REQUEST_HEADER_FIELDS_TOO_LARGE'
	| '451 UNAVAILABLE_FOR_LEGAL_REASONS'
	| '500 INTERNAL_SERVER_ERROR'
	| '501 NOT_IMPLEMENTED'
	| '502 BAD_GATEWAY'
	| '503 SERVICE_UNAVAILABLE'
	| '504 GATEWAY_TIMEOUT'
	| '505 HTTP_VERSION_NOT_SUPPORTED'
	| '506 VARIANT_ALSO_NEGOTIATES'
	| '507 INSUFFICIENT_STORAGE'
	| '508 LOOP_DETECTED'
	| '509 BANDWIDTH_LIMIT_EXCEEDED'
	| '510 NOT_EXTENDED'
	| '511 NETWORK_AUTHENTICATION_REQUIRED';
export type DeleteRestrictionTableApiArg = {
	ids: string[];
};
export type GetRestrictionTableItemsApiResponse =
	/** status 200 OK */ RestrictionTableItemResponse[];
export type GetRestrictionTableItemsApiArg = {
	restrictionTableId: string;
	ids?: string[];
};
export type CreateRestrictionTableItemApiResponse =
	/** status 200 OK */ RestrictionTableItemResponse;
export type CreateRestrictionTableItemApiArg = {
	restrictionTableId: string;
	createRestrictionTableItemRequest: CreateRestrictionTableItemRequest;
};
export type GetMeasurementGroupsApiResponse = /** status 200 OK */ MeasurementGroupResponse[];
export type GetMeasurementGroupsApiArg = {
	ids?: string[];
};
export type CreateMeasurementGroupApiResponse = /** status 200 OK */ MeasurementGroupResponse;
export type CreateMeasurementGroupApiArg = {
	createMeasurementGroupRequest: CreateMeasurementGroupRequest;
};
export type DeleteMeasurementGroupApiResponse = /** status 200 OK */
	| '100 CONTINUE'
	| '101 SWITCHING_PROTOCOLS'
	| '102 PROCESSING'
	| '103 EARLY_HINTS'
	| '103 CHECKPOINT'
	| '200 OK'
	| '201 CREATED'
	| '202 ACCEPTED'
	| '203 NON_AUTHORITATIVE_INFORMATION'
	| '204 NO_CONTENT'
	| '205 RESET_CONTENT'
	| '206 PARTIAL_CONTENT'
	| '207 MULTI_STATUS'
	| '208 ALREADY_REPORTED'
	| '226 IM_USED'
	| '300 MULTIPLE_CHOICES'
	| '301 MOVED_PERMANENTLY'
	| '302 FOUND'
	| '302 MOVED_TEMPORARILY'
	| '303 SEE_OTHER'
	| '304 NOT_MODIFIED'
	| '305 USE_PROXY'
	| '307 TEMPORARY_REDIRECT'
	| '308 PERMANENT_REDIRECT'
	| '400 BAD_REQUEST'
	| '401 UNAUTHORIZED'
	| '402 PAYMENT_REQUIRED'
	| '403 FORBIDDEN'
	| '404 NOT_FOUND'
	| '405 METHOD_NOT_ALLOWED'
	| '406 NOT_ACCEPTABLE'
	| '407 PROXY_AUTHENTICATION_REQUIRED'
	| '408 REQUEST_TIMEOUT'
	| '409 CONFLICT'
	| '410 GONE'
	| '411 LENGTH_REQUIRED'
	| '412 PRECONDITION_FAILED'
	| '413 PAYLOAD_TOO_LARGE'
	| '413 REQUEST_ENTITY_TOO_LARGE'
	| '414 URI_TOO_LONG'
	| '414 REQUEST_URI_TOO_LONG'
	| '415 UNSUPPORTED_MEDIA_TYPE'
	| '416 REQUESTED_RANGE_NOT_SATISFIABLE'
	| '417 EXPECTATION_FAILED'
	| '418 I_AM_A_TEAPOT'
	| '419 INSUFFICIENT_SPACE_ON_RESOURCE'
	| '420 METHOD_FAILURE'
	| '421 DESTINATION_LOCKED'
	| '422 UNPROCESSABLE_ENTITY'
	| '423 LOCKED'
	| '424 FAILED_DEPENDENCY'
	| '425 TOO_EARLY'
	| '426 UPGRADE_REQUIRED'
	| '428 PRECONDITION_REQUIRED'
	| '429 TOO_MANY_REQUESTS'
	| '431 REQUEST_HEADER_FIELDS_TOO_LARGE'
	| '451 UNAVAILABLE_FOR_LEGAL_REASONS'
	| '500 INTERNAL_SERVER_ERROR'
	| '501 NOT_IMPLEMENTED'
	| '502 BAD_GATEWAY'
	| '503 SERVICE_UNAVAILABLE'
	| '504 GATEWAY_TIMEOUT'
	| '505 HTTP_VERSION_NOT_SUPPORTED'
	| '506 VARIANT_ALSO_NEGOTIATES'
	| '507 INSUFFICIENT_STORAGE'
	| '508 LOOP_DETECTED'
	| '509 BANDWIDTH_LIMIT_EXCEEDED'
	| '510 NOT_EXTENDED'
	| '511 NETWORK_AUTHENTICATION_REQUIRED';
export type DeleteMeasurementGroupApiArg = {
	ids: string[];
};
export type GetMeasurementsApiResponse = /** status 200 OK */ MeasurementResponse[];
export type GetMeasurementsApiArg = {
	ids?: string[];
	measurementGroupId: string;
};
export type CreateMeasurementApiResponse = /** status 200 OK */ MeasurementResponse;
export type CreateMeasurementApiArg = {
	measurementGroupId: string;
	createMeasurementRequest: CreateMeasurementRequest;
};
export type GetClassifiersApiResponse = /** status 200 OK */ ClassifierResponse[];
export type GetClassifiersApiArg = {
	ids?: string[];
};
export type CreateClassifierApiResponse = /** status 200 OK */ ClassifierResponse;
export type CreateClassifierApiArg = {
	createClassifierRequest: CreateClassifierRequest;
};
export type DeleteClassifierApiResponse = /** status 200 OK */
	| '100 CONTINUE'
	| '101 SWITCHING_PROTOCOLS'
	| '102 PROCESSING'
	| '103 EARLY_HINTS'
	| '103 CHECKPOINT'
	| '200 OK'
	| '201 CREATED'
	| '202 ACCEPTED'
	| '203 NON_AUTHORITATIVE_INFORMATION'
	| '204 NO_CONTENT'
	| '205 RESET_CONTENT'
	| '206 PARTIAL_CONTENT'
	| '207 MULTI_STATUS'
	| '208 ALREADY_REPORTED'
	| '226 IM_USED'
	| '300 MULTIPLE_CHOICES'
	| '301 MOVED_PERMANENTLY'
	| '302 FOUND'
	| '302 MOVED_TEMPORARILY'
	| '303 SEE_OTHER'
	| '304 NOT_MODIFIED'
	| '305 USE_PROXY'
	| '307 TEMPORARY_REDIRECT'
	| '308 PERMANENT_REDIRECT'
	| '400 BAD_REQUEST'
	| '401 UNAUTHORIZED'
	| '402 PAYMENT_REQUIRED'
	| '403 FORBIDDEN'
	| '404 NOT_FOUND'
	| '405 METHOD_NOT_ALLOWED'
	| '406 NOT_ACCEPTABLE'
	| '407 PROXY_AUTHENTICATION_REQUIRED'
	| '408 REQUEST_TIMEOUT'
	| '409 CONFLICT'
	| '410 GONE'
	| '411 LENGTH_REQUIRED'
	| '412 PRECONDITION_FAILED'
	| '413 PAYLOAD_TOO_LARGE'
	| '413 REQUEST_ENTITY_TOO_LARGE'
	| '414 URI_TOO_LONG'
	| '414 REQUEST_URI_TOO_LONG'
	| '415 UNSUPPORTED_MEDIA_TYPE'
	| '416 REQUESTED_RANGE_NOT_SATISFIABLE'
	| '417 EXPECTATION_FAILED'
	| '418 I_AM_A_TEAPOT'
	| '419 INSUFFICIENT_SPACE_ON_RESOURCE'
	| '420 METHOD_FAILURE'
	| '421 DESTINATION_LOCKED'
	| '422 UNPROCESSABLE_ENTITY'
	| '423 LOCKED'
	| '424 FAILED_DEPENDENCY'
	| '425 TOO_EARLY'
	| '426 UPGRADE_REQUIRED'
	| '428 PRECONDITION_REQUIRED'
	| '429 TOO_MANY_REQUESTS'
	| '431 REQUEST_HEADER_FIELDS_TOO_LARGE'
	| '451 UNAVAILABLE_FOR_LEGAL_REASONS'
	| '500 INTERNAL_SERVER_ERROR'
	| '501 NOT_IMPLEMENTED'
	| '502 BAD_GATEWAY'
	| '503 SERVICE_UNAVAILABLE'
	| '504 GATEWAY_TIMEOUT'
	| '505 HTTP_VERSION_NOT_SUPPORTED'
	| '506 VARIANT_ALSO_NEGOTIATES'
	| '507 INSUFFICIENT_STORAGE'
	| '508 LOOP_DETECTED'
	| '509 BANDWIDTH_LIMIT_EXCEEDED'
	| '510 NOT_EXTENDED'
	| '511 NETWORK_AUTHENTICATION_REQUIRED';
export type DeleteClassifierApiArg = {
	ids: string[];
};
export type CreateClassifierItemApiResponse = /** status 200 OK */ ClassifierItemResponse;
export type CreateClassifierItemApiArg = {
	classifierId: string;
	createClassifierItemRequest: CreateClassifierItemRequest;
};
export type GetClassifierAttributeDeclarationsApiResponse =
	/** status 200 OK */ ClassifierAttributeDeclarationResponse[];
export type GetClassifierAttributeDeclarationsApiArg = {
	classifierId: string;
	ids?: string[];
};
export type CreateClassifierAttributeDeclarationsApiResponse =
	/** status 200 OK */ ClassifierAttributeDeclarationResponse[];
export type CreateClassifierAttributeDeclarationsApiArg = {
	classifierId: string;
	body: CreateClassifierAttributeDeclarationRequest[];
};
export type GetCatalogsApiResponse = /** status 200 OK */ CatalogResponse[];
export type GetCatalogsApiArg = {
	parentIds?: string[];
	root?: boolean;
	ids?: string[];
};
export type CreateCatalogApiResponse = /** status 200 OK */ CatalogResponse;
export type CreateCatalogApiArg = {
	createCatalogRequest: CreateCatalogRequest;
};
export type DeleteCatalogsApiResponse = /** status 200 OK */
	| '100 CONTINUE'
	| '101 SWITCHING_PROTOCOLS'
	| '102 PROCESSING'
	| '103 EARLY_HINTS'
	| '103 CHECKPOINT'
	| '200 OK'
	| '201 CREATED'
	| '202 ACCEPTED'
	| '203 NON_AUTHORITATIVE_INFORMATION'
	| '204 NO_CONTENT'
	| '205 RESET_CONTENT'
	| '206 PARTIAL_CONTENT'
	| '207 MULTI_STATUS'
	| '208 ALREADY_REPORTED'
	| '226 IM_USED'
	| '300 MULTIPLE_CHOICES'
	| '301 MOVED_PERMANENTLY'
	| '302 FOUND'
	| '302 MOVED_TEMPORARILY'
	| '303 SEE_OTHER'
	| '304 NOT_MODIFIED'
	| '305 USE_PROXY'
	| '307 TEMPORARY_REDIRECT'
	| '308 PERMANENT_REDIRECT'
	| '400 BAD_REQUEST'
	| '401 UNAUTHORIZED'
	| '402 PAYMENT_REQUIRED'
	| '403 FORBIDDEN'
	| '404 NOT_FOUND'
	| '405 METHOD_NOT_ALLOWED'
	| '406 NOT_ACCEPTABLE'
	| '407 PROXY_AUTHENTICATION_REQUIRED'
	| '408 REQUEST_TIMEOUT'
	| '409 CONFLICT'
	| '410 GONE'
	| '411 LENGTH_REQUIRED'
	| '412 PRECONDITION_FAILED'
	| '413 PAYLOAD_TOO_LARGE'
	| '413 REQUEST_ENTITY_TOO_LARGE'
	| '414 URI_TOO_LONG'
	| '414 REQUEST_URI_TOO_LONG'
	| '415 UNSUPPORTED_MEDIA_TYPE'
	| '416 REQUESTED_RANGE_NOT_SATISFIABLE'
	| '417 EXPECTATION_FAILED'
	| '418 I_AM_A_TEAPOT'
	| '419 INSUFFICIENT_SPACE_ON_RESOURCE'
	| '420 METHOD_FAILURE'
	| '421 DESTINATION_LOCKED'
	| '422 UNPROCESSABLE_ENTITY'
	| '423 LOCKED'
	| '424 FAILED_DEPENDENCY'
	| '425 TOO_EARLY'
	| '426 UPGRADE_REQUIRED'
	| '428 PRECONDITION_REQUIRED'
	| '429 TOO_MANY_REQUESTS'
	| '431 REQUEST_HEADER_FIELDS_TOO_LARGE'
	| '451 UNAVAILABLE_FOR_LEGAL_REASONS'
	| '500 INTERNAL_SERVER_ERROR'
	| '501 NOT_IMPLEMENTED'
	| '502 BAD_GATEWAY'
	| '503 SERVICE_UNAVAILABLE'
	| '504 GATEWAY_TIMEOUT'
	| '505 HTTP_VERSION_NOT_SUPPORTED'
	| '506 VARIANT_ALSO_NEGOTIATES'
	| '507 INSUFFICIENT_STORAGE'
	| '508 LOOP_DETECTED'
	| '509 BANDWIDTH_LIMIT_EXCEEDED'
	| '510 NOT_EXTENDED'
	| '511 NETWORK_AUTHENTICATION_REQUIRED';
export type DeleteCatalogsApiArg = {
	ids: string[];
};
export type GetCatalogRestrictionTableDeclarationsApiResponse =
	/** status 200 OK */ CatalogRestrictionTableDeclarationResponse[];
export type GetCatalogRestrictionTableDeclarationsApiArg = {
	ids?: string[];
	catalogId: string;
};
export type CreateCatalogRestrictionTableDeclarationsApiResponse =
	/** status 200 OK */ CatalogRestrictionTableDeclarationResponse[];
export type CreateCatalogRestrictionTableDeclarationsApiArg = {
	catalogId: string;
	body: CreateCatalogRestrictionTableDeclarationRequest[];
};
export type CreateCatalogItemApiResponse = /** status 200 OK */ CatalogItemResponse;
export type CreateCatalogItemApiArg = {
	catalogId: string;
	createCatalogItemRequest: CreateCatalogItemRequest;
};
export type ImportXlsxApiResponse = unknown;
export type ImportXlsxApiArg = {
	catalogId: string;
	xlsxImportCatalogItemsRequest: XlsxImportCatalogItemsRequest;
};
export type GetCatalogAttributeDeclarationsApiResponse =
	/** status 200 OK */ CatalogAttributeDeclarationResponse[];
export type GetCatalogAttributeDeclarationsApiArg = {
	catalogId: string;
	ids?: string[];
};
export type CreateCatalogAttributeDeclarationsApiResponse =
	/** status 200 OK */ CatalogAttributeDeclarationResponse[];
export type CreateCatalogAttributeDeclarationsApiArg = {
	catalogId: string;
	body: CreateCatalogAttributeDeclarationRequest[];
};
export type GetAttributeGroupApiResponse = /** status 200 OK */ AttributeGroupResponse[];
export type GetAttributeGroupApiArg = {
	parentIds?: string[];
	root?: boolean;
	ids?: string[];
};
export type CreateAttributeGroupApiResponse = /** status 200 OK */ AttributeGroupResponse;
export type CreateAttributeGroupApiArg = {
	createAttributeGroupRequest: CreateAttributeGroupRequest;
};
export type DeleteAttributeGroupApiResponse = /** status 200 OK */
	| '100 CONTINUE'
	| '101 SWITCHING_PROTOCOLS'
	| '102 PROCESSING'
	| '103 EARLY_HINTS'
	| '103 CHECKPOINT'
	| '200 OK'
	| '201 CREATED'
	| '202 ACCEPTED'
	| '203 NON_AUTHORITATIVE_INFORMATION'
	| '204 NO_CONTENT'
	| '205 RESET_CONTENT'
	| '206 PARTIAL_CONTENT'
	| '207 MULTI_STATUS'
	| '208 ALREADY_REPORTED'
	| '226 IM_USED'
	| '300 MULTIPLE_CHOICES'
	| '301 MOVED_PERMANENTLY'
	| '302 FOUND'
	| '302 MOVED_TEMPORARILY'
	| '303 SEE_OTHER'
	| '304 NOT_MODIFIED'
	| '305 USE_PROXY'
	| '307 TEMPORARY_REDIRECT'
	| '308 PERMANENT_REDIRECT'
	| '400 BAD_REQUEST'
	| '401 UNAUTHORIZED'
	| '402 PAYMENT_REQUIRED'
	| '403 FORBIDDEN'
	| '404 NOT_FOUND'
	| '405 METHOD_NOT_ALLOWED'
	| '406 NOT_ACCEPTABLE'
	| '407 PROXY_AUTHENTICATION_REQUIRED'
	| '408 REQUEST_TIMEOUT'
	| '409 CONFLICT'
	| '410 GONE'
	| '411 LENGTH_REQUIRED'
	| '412 PRECONDITION_FAILED'
	| '413 PAYLOAD_TOO_LARGE'
	| '413 REQUEST_ENTITY_TOO_LARGE'
	| '414 URI_TOO_LONG'
	| '414 REQUEST_URI_TOO_LONG'
	| '415 UNSUPPORTED_MEDIA_TYPE'
	| '416 REQUESTED_RANGE_NOT_SATISFIABLE'
	| '417 EXPECTATION_FAILED'
	| '418 I_AM_A_TEAPOT'
	| '419 INSUFFICIENT_SPACE_ON_RESOURCE'
	| '420 METHOD_FAILURE'
	| '421 DESTINATION_LOCKED'
	| '422 UNPROCESSABLE_ENTITY'
	| '423 LOCKED'
	| '424 FAILED_DEPENDENCY'
	| '425 TOO_EARLY'
	| '426 UPGRADE_REQUIRED'
	| '428 PRECONDITION_REQUIRED'
	| '429 TOO_MANY_REQUESTS'
	| '431 REQUEST_HEADER_FIELDS_TOO_LARGE'
	| '451 UNAVAILABLE_FOR_LEGAL_REASONS'
	| '500 INTERNAL_SERVER_ERROR'
	| '501 NOT_IMPLEMENTED'
	| '502 BAD_GATEWAY'
	| '503 SERVICE_UNAVAILABLE'
	| '504 GATEWAY_TIMEOUT'
	| '505 HTTP_VERSION_NOT_SUPPORTED'
	| '506 VARIANT_ALSO_NEGOTIATES'
	| '507 INSUFFICIENT_STORAGE'
	| '508 LOOP_DETECTED'
	| '509 BANDWIDTH_LIMIT_EXCEEDED'
	| '510 NOT_EXTENDED'
	| '511 NETWORK_AUTHENTICATION_REQUIRED';
export type DeleteAttributeGroupApiArg = {
	ids: string[];
};
export type CreateAttributeApiResponse = /** status 200 OK */ AttributeResponse;
export type CreateAttributeApiArg = {
	attributeGroupId: string;
	createAttributeRequest: CreateAttributeRequest;
};
export type UploadFileApiResponse = /** status 200 OK */ UploadFileResponse;
export type UploadFileApiArg = {
	uploadFileFrontRequest: UploadFileFrontRequest;
};
export type GetContextNamesApiResponse = /** status 200 OK */ SliceContextNameResponse;
export type GetContextNamesApiArg = {
	limit?: number;
	page?: number;
	sortBy?: string;
	sortType?: string;
	search?: string;
};
export type CreateContextNameApiResponse = /** status 200 OK */ ContextNameResponse;
export type CreateContextNameApiArg = {
	createContextNameRequest: CreateContextNameRequest;
};
export type DeleteContextNamesApiResponse = /** status 200 OK */
	| '100 CONTINUE'
	| '101 SWITCHING_PROTOCOLS'
	| '102 PROCESSING'
	| '103 EARLY_HINTS'
	| '103 CHECKPOINT'
	| '200 OK'
	| '201 CREATED'
	| '202 ACCEPTED'
	| '203 NON_AUTHORITATIVE_INFORMATION'
	| '204 NO_CONTENT'
	| '205 RESET_CONTENT'
	| '206 PARTIAL_CONTENT'
	| '207 MULTI_STATUS'
	| '208 ALREADY_REPORTED'
	| '226 IM_USED'
	| '300 MULTIPLE_CHOICES'
	| '301 MOVED_PERMANENTLY'
	| '302 FOUND'
	| '302 MOVED_TEMPORARILY'
	| '303 SEE_OTHER'
	| '304 NOT_MODIFIED'
	| '305 USE_PROXY'
	| '307 TEMPORARY_REDIRECT'
	| '308 PERMANENT_REDIRECT'
	| '400 BAD_REQUEST'
	| '401 UNAUTHORIZED'
	| '402 PAYMENT_REQUIRED'
	| '403 FORBIDDEN'
	| '404 NOT_FOUND'
	| '405 METHOD_NOT_ALLOWED'
	| '406 NOT_ACCEPTABLE'
	| '407 PROXY_AUTHENTICATION_REQUIRED'
	| '408 REQUEST_TIMEOUT'
	| '409 CONFLICT'
	| '410 GONE'
	| '411 LENGTH_REQUIRED'
	| '412 PRECONDITION_FAILED'
	| '413 PAYLOAD_TOO_LARGE'
	| '413 REQUEST_ENTITY_TOO_LARGE'
	| '414 URI_TOO_LONG'
	| '414 REQUEST_URI_TOO_LONG'
	| '415 UNSUPPORTED_MEDIA_TYPE'
	| '416 REQUESTED_RANGE_NOT_SATISFIABLE'
	| '417 EXPECTATION_FAILED'
	| '418 I_AM_A_TEAPOT'
	| '419 INSUFFICIENT_SPACE_ON_RESOURCE'
	| '420 METHOD_FAILURE'
	| '421 DESTINATION_LOCKED'
	| '422 UNPROCESSABLE_ENTITY'
	| '423 LOCKED'
	| '424 FAILED_DEPENDENCY'
	| '425 TOO_EARLY'
	| '426 UPGRADE_REQUIRED'
	| '428 PRECONDITION_REQUIRED'
	| '429 TOO_MANY_REQUESTS'
	| '431 REQUEST_HEADER_FIELDS_TOO_LARGE'
	| '451 UNAVAILABLE_FOR_LEGAL_REASONS'
	| '500 INTERNAL_SERVER_ERROR'
	| '501 NOT_IMPLEMENTED'
	| '502 BAD_GATEWAY'
	| '503 SERVICE_UNAVAILABLE'
	| '504 GATEWAY_TIMEOUT'
	| '505 HTTP_VERSION_NOT_SUPPORTED'
	| '506 VARIANT_ALSO_NEGOTIATES'
	| '507 INSUFFICIENT_STORAGE'
	| '508 LOOP_DETECTED'
	| '509 BANDWIDTH_LIMIT_EXCEEDED'
	| '510 NOT_EXTENDED'
	| '511 NETWORK_AUTHENTICATION_REQUIRED';
export type DeleteContextNamesApiArg = {
	ids: string[];
};
export type GetCompatibilityItemsApiResponse = /** status 200 OK */ SliceCompatibilityItemResponse;
export type GetCompatibilityItemsApiArg = {
	limit?: number;
	page?: number;
	sortBy?: string;
	sortType?: string;
	itemId: string;
	contextNameId: string;
	contextNameDirection: 'STRAIGHT' | 'REVERSE';
};
export type CreateCompatibilityItemsApiResponse = /** status 200 OK */ CompatibilityItemResponse[];
export type CreateCompatibilityItemsApiArg = {
	createCompatibilityItemRequest: CreateCompatibilityItemRequest;
};
export type DeleteCompatibilityItemsApiResponse = /** status 200 OK */
	| '100 CONTINUE'
	| '101 SWITCHING_PROTOCOLS'
	| '102 PROCESSING'
	| '103 EARLY_HINTS'
	| '103 CHECKPOINT'
	| '200 OK'
	| '201 CREATED'
	| '202 ACCEPTED'
	| '203 NON_AUTHORITATIVE_INFORMATION'
	| '204 NO_CONTENT'
	| '205 RESET_CONTENT'
	| '206 PARTIAL_CONTENT'
	| '207 MULTI_STATUS'
	| '208 ALREADY_REPORTED'
	| '226 IM_USED'
	| '300 MULTIPLE_CHOICES'
	| '301 MOVED_PERMANENTLY'
	| '302 FOUND'
	| '302 MOVED_TEMPORARILY'
	| '303 SEE_OTHER'
	| '304 NOT_MODIFIED'
	| '305 USE_PROXY'
	| '307 TEMPORARY_REDIRECT'
	| '308 PERMANENT_REDIRECT'
	| '400 BAD_REQUEST'
	| '401 UNAUTHORIZED'
	| '402 PAYMENT_REQUIRED'
	| '403 FORBIDDEN'
	| '404 NOT_FOUND'
	| '405 METHOD_NOT_ALLOWED'
	| '406 NOT_ACCEPTABLE'
	| '407 PROXY_AUTHENTICATION_REQUIRED'
	| '408 REQUEST_TIMEOUT'
	| '409 CONFLICT'
	| '410 GONE'
	| '411 LENGTH_REQUIRED'
	| '412 PRECONDITION_FAILED'
	| '413 PAYLOAD_TOO_LARGE'
	| '413 REQUEST_ENTITY_TOO_LARGE'
	| '414 URI_TOO_LONG'
	| '414 REQUEST_URI_TOO_LONG'
	| '415 UNSUPPORTED_MEDIA_TYPE'
	| '416 REQUESTED_RANGE_NOT_SATISFIABLE'
	| '417 EXPECTATION_FAILED'
	| '418 I_AM_A_TEAPOT'
	| '419 INSUFFICIENT_SPACE_ON_RESOURCE'
	| '420 METHOD_FAILURE'
	| '421 DESTINATION_LOCKED'
	| '422 UNPROCESSABLE_ENTITY'
	| '423 LOCKED'
	| '424 FAILED_DEPENDENCY'
	| '425 TOO_EARLY'
	| '426 UPGRADE_REQUIRED'
	| '428 PRECONDITION_REQUIRED'
	| '429 TOO_MANY_REQUESTS'
	| '431 REQUEST_HEADER_FIELDS_TOO_LARGE'
	| '451 UNAVAILABLE_FOR_LEGAL_REASONS'
	| '500 INTERNAL_SERVER_ERROR'
	| '501 NOT_IMPLEMENTED'
	| '502 BAD_GATEWAY'
	| '503 SERVICE_UNAVAILABLE'
	| '504 GATEWAY_TIMEOUT'
	| '505 HTTP_VERSION_NOT_SUPPORTED'
	| '506 VARIANT_ALSO_NEGOTIATES'
	| '507 INSUFFICIENT_STORAGE'
	| '508 LOOP_DETECTED'
	| '509 BANDWIDTH_LIMIT_EXCEEDED'
	| '510 NOT_EXTENDED'
	| '511 NETWORK_AUTHENTICATION_REQUIRED';
export type DeleteCompatibilityItemsApiArg = {
	ids: string[];
};
export type GetCompatibilitiesApiResponse = /** status 200 OK */ CompatibilityMultipleResponse[];
export type GetCompatibilitiesApiArg = {
	entityId?: string;
	entityType?: 'CATALOG_ITEM' | 'CLASSIFIER_ITEM' | 'CATALOG' | 'CLASSIFIER';
	contextNameId?: string;
};
export type CreateCompatibilityApiResponse = /** status 200 OK */ CompatibilityResponse;
export type CreateCompatibilityApiArg = {
	createCompatibilityRequest: CreateCompatibilityRequest;
};
export type DeleteCompatibilitiesApiResponse = /** status 200 OK */
	| '100 CONTINUE'
	| '101 SWITCHING_PROTOCOLS'
	| '102 PROCESSING'
	| '103 EARLY_HINTS'
	| '103 CHECKPOINT'
	| '200 OK'
	| '201 CREATED'
	| '202 ACCEPTED'
	| '203 NON_AUTHORITATIVE_INFORMATION'
	| '204 NO_CONTENT'
	| '205 RESET_CONTENT'
	| '206 PARTIAL_CONTENT'
	| '207 MULTI_STATUS'
	| '208 ALREADY_REPORTED'
	| '226 IM_USED'
	| '300 MULTIPLE_CHOICES'
	| '301 MOVED_PERMANENTLY'
	| '302 FOUND'
	| '302 MOVED_TEMPORARILY'
	| '303 SEE_OTHER'
	| '304 NOT_MODIFIED'
	| '305 USE_PROXY'
	| '307 TEMPORARY_REDIRECT'
	| '308 PERMANENT_REDIRECT'
	| '400 BAD_REQUEST'
	| '401 UNAUTHORIZED'
	| '402 PAYMENT_REQUIRED'
	| '403 FORBIDDEN'
	| '404 NOT_FOUND'
	| '405 METHOD_NOT_ALLOWED'
	| '406 NOT_ACCEPTABLE'
	| '407 PROXY_AUTHENTICATION_REQUIRED'
	| '408 REQUEST_TIMEOUT'
	| '409 CONFLICT'
	| '410 GONE'
	| '411 LENGTH_REQUIRED'
	| '412 PRECONDITION_FAILED'
	| '413 PAYLOAD_TOO_LARGE'
	| '413 REQUEST_ENTITY_TOO_LARGE'
	| '414 URI_TOO_LONG'
	| '414 REQUEST_URI_TOO_LONG'
	| '415 UNSUPPORTED_MEDIA_TYPE'
	| '416 REQUESTED_RANGE_NOT_SATISFIABLE'
	| '417 EXPECTATION_FAILED'
	| '418 I_AM_A_TEAPOT'
	| '419 INSUFFICIENT_SPACE_ON_RESOURCE'
	| '420 METHOD_FAILURE'
	| '421 DESTINATION_LOCKED'
	| '422 UNPROCESSABLE_ENTITY'
	| '423 LOCKED'
	| '424 FAILED_DEPENDENCY'
	| '425 TOO_EARLY'
	| '426 UPGRADE_REQUIRED'
	| '428 PRECONDITION_REQUIRED'
	| '429 TOO_MANY_REQUESTS'
	| '431 REQUEST_HEADER_FIELDS_TOO_LARGE'
	| '451 UNAVAILABLE_FOR_LEGAL_REASONS'
	| '500 INTERNAL_SERVER_ERROR'
	| '501 NOT_IMPLEMENTED'
	| '502 BAD_GATEWAY'
	| '503 SERVICE_UNAVAILABLE'
	| '504 GATEWAY_TIMEOUT'
	| '505 HTTP_VERSION_NOT_SUPPORTED'
	| '506 VARIANT_ALSO_NEGOTIATES'
	| '507 INSUFFICIENT_STORAGE'
	| '508 LOOP_DETECTED'
	| '509 BANDWIDTH_LIMIT_EXCEEDED'
	| '510 NOT_EXTENDED'
	| '511 NETWORK_AUTHENTICATION_REQUIRED';
export type DeleteCompatibilitiesApiArg = {
	ids: string[];
};
export type SearchCompatibilitiesApiResponse = /** status 200 OK */ BackgroundTaskStartDto;
export type SearchCompatibilitiesApiArg = {
	id: string;
};
export type UpdateMeasurementAsyncApiResponse = /** status 200 OK */ BackgroundTaskStartDto;
export type UpdateMeasurementAsyncApiArg = {
	measurementGroupId: string;
	id: string;
	updateMeasurementRequest: UpdateMeasurementRequest;
};
export type GetMeasurementGroupApiResponse = /** status 200 OK */ MeasurementGroupResponse;
export type GetMeasurementGroupApiArg = {
	id: string;
	includeChildren?: boolean;
};
export type UpdateMeasurementGroupApiResponse = /** status 200 OK */ MeasurementGroupResponse;
export type UpdateMeasurementGroupApiArg = {
	id: string;
	updateMeasurementGroupRequest: UpdateMeasurementGroupRequest;
};
export type GetCatalogsV3ApiResponse = /** status 200 OK */ SliceCatalogResponse;
export type GetCatalogsV3ApiArg = {
	/** Id родительских справочников */
	parentIds?: string[];
	/** Признак того, является ли справочник рутовым (без родителя) */
	root?: boolean;
	/** Id справочников */
	ids?: string[];
	/** Фильтр по подстроке в названии или описании справочника */
	searchSubstring?: string;
	/** Ограничение по количеству записей на странице */
	limit?: number;
	/** Номер страницы */
	page?: number;
	/** Поле, по которому ведется сортировка */
	sortBy: 'DISPLAY_NAME' | 'DESCRIPTION' | 'CREATED_AT';
	/** Тип сортировки (по возрастанию, по убыванию) */
	sortType: 'ASC' | 'DESC';
};
export type DeleteCatalogsAsyncApiResponse = /** status 200 OK */ BackgroundTaskStartDto;
export type DeleteCatalogsAsyncApiArg = {
	ids: string[];
};
export type GetTransactionsDetailsApiResponse =
	/** status 200 OK */ SliceTransactionDetailsResponse;
export type GetTransactionsDetailsApiArg = {
	limit?: number;
	page?: number;
	sortBy?: string;
	sortType?: string;
	transactionIds?: string[];
	userId?: string;
	statuses?: string[];
	includeEvents?: boolean;
};
export type GetTransactionDetailsApiResponse = /** status 200 OK */ TransactionDetailsResponse;
export type GetTransactionDetailsApiArg = {
	id: string;
	includeEvents?: boolean;
	entityTypes?: (
		| 'CATALOG'
		| 'ATTRIBUTE'
		| 'ATTRIBUTE_GROUP'
		| 'ATTRIBUTE_DECLARATION'
		| 'EXCHANGE_CLASS'
		| 'EXCHANGE_KEY'
		| 'ITEM'
		| 'MEASUREMENT'
		| 'MEASUREMENT_GROUP'
		| 'CLASSIFIER'
		| 'CLASSIFIER_ATTRIBUTE_DECLARATION'
		| 'CLASSIFIER_ITEM'
		| 'RESTRICTION_TABLE_DECLARATION'
		| 'RESTRICTION_TABLE'
		| 'RESTRICTION_TABLE_ITEM'
		| 'FORMULA'
		| 'COMPATIBILITY'
		| 'COMPATIBILITY_CONDITION'
		| 'COMPATIBILITY_ITEM'
		| 'CONTEXT_NAME'
		| 'UNKNOWN'
	)[];
};
export type GetRestrictionTableItemApiResponse = /** status 200 OK */ RestrictionTableItemResponse;
export type GetRestrictionTableItemApiArg = {
	id: string;
};
export type GetMeasurementApiResponse = /** status 200 OK */ MeasurementResponse[];
export type GetMeasurementApiArg = {
	ids?: string[];
};
export type DeleteMeasurementApiResponse = /** status 200 OK */
	| '100 CONTINUE'
	| '101 SWITCHING_PROTOCOLS'
	| '102 PROCESSING'
	| '103 EARLY_HINTS'
	| '103 CHECKPOINT'
	| '200 OK'
	| '201 CREATED'
	| '202 ACCEPTED'
	| '203 NON_AUTHORITATIVE_INFORMATION'
	| '204 NO_CONTENT'
	| '205 RESET_CONTENT'
	| '206 PARTIAL_CONTENT'
	| '207 MULTI_STATUS'
	| '208 ALREADY_REPORTED'
	| '226 IM_USED'
	| '300 MULTIPLE_CHOICES'
	| '301 MOVED_PERMANENTLY'
	| '302 FOUND'
	| '302 MOVED_TEMPORARILY'
	| '303 SEE_OTHER'
	| '304 NOT_MODIFIED'
	| '305 USE_PROXY'
	| '307 TEMPORARY_REDIRECT'
	| '308 PERMANENT_REDIRECT'
	| '400 BAD_REQUEST'
	| '401 UNAUTHORIZED'
	| '402 PAYMENT_REQUIRED'
	| '403 FORBIDDEN'
	| '404 NOT_FOUND'
	| '405 METHOD_NOT_ALLOWED'
	| '406 NOT_ACCEPTABLE'
	| '407 PROXY_AUTHENTICATION_REQUIRED'
	| '408 REQUEST_TIMEOUT'
	| '409 CONFLICT'
	| '410 GONE'
	| '411 LENGTH_REQUIRED'
	| '412 PRECONDITION_FAILED'
	| '413 PAYLOAD_TOO_LARGE'
	| '413 REQUEST_ENTITY_TOO_LARGE'
	| '414 URI_TOO_LONG'
	| '414 REQUEST_URI_TOO_LONG'
	| '415 UNSUPPORTED_MEDIA_TYPE'
	| '416 REQUESTED_RANGE_NOT_SATISFIABLE'
	| '417 EXPECTATION_FAILED'
	| '418 I_AM_A_TEAPOT'
	| '419 INSUFFICIENT_SPACE_ON_RESOURCE'
	| '420 METHOD_FAILURE'
	| '421 DESTINATION_LOCKED'
	| '422 UNPROCESSABLE_ENTITY'
	| '423 LOCKED'
	| '424 FAILED_DEPENDENCY'
	| '425 TOO_EARLY'
	| '426 UPGRADE_REQUIRED'
	| '428 PRECONDITION_REQUIRED'
	| '429 TOO_MANY_REQUESTS'
	| '431 REQUEST_HEADER_FIELDS_TOO_LARGE'
	| '451 UNAVAILABLE_FOR_LEGAL_REASONS'
	| '500 INTERNAL_SERVER_ERROR'
	| '501 NOT_IMPLEMENTED'
	| '502 BAD_GATEWAY'
	| '503 SERVICE_UNAVAILABLE'
	| '504 GATEWAY_TIMEOUT'
	| '505 HTTP_VERSION_NOT_SUPPORTED'
	| '506 VARIANT_ALSO_NEGOTIATES'
	| '507 INSUFFICIENT_STORAGE'
	| '508 LOOP_DETECTED'
	| '509 BANDWIDTH_LIMIT_EXCEEDED'
	| '510 NOT_EXTENDED'
	| '511 NETWORK_AUTHENTICATION_REQUIRED';
export type DeleteMeasurementApiArg = {
	ids: string[];
};
export type GetMeasurement1ApiResponse = /** status 200 OK */ MeasurementResponse;
export type GetMeasurement1ApiArg = {
	id: string;
};
export type ConvertApiResponse = /** status 200 OK */ number;
export type ConvertApiArg = {
	fromMeasurementId: string;
	toMeasurementId: string;
	value: number;
};
export type GetClassifierItemTreeApiResponse =
	/** status 200 OK */ ClassifierItemTreeNodeResponse[];
export type GetClassifierItemTreeApiArg = {
	classifierId: string;
	fromItemId?: string;
	includePathToItemId?: string;
	sortBy?: string;
	sortType?: string;
};
export type GetClassifierItemsApiResponse = /** status 200 OK */ SliceClassifierItemResponse;
export type GetClassifierItemsApiArg = {
	/** Ограничение по количеству записей на странице */
	limit?: number;
	/** Номер страницы */
	page?: number;
	/** Поле, по которому ведется сортировка */
	sortBy?: string;
	/** Тип сортировки (по возрастанию, по убыванию) */
	sortType?: string;
	/** Id классификатора, к которому принадлежит позиция */
	classifierId?: string;
	/** Фильтр по статусам позиции */
	statuses?: ('NORMALIZED' | 'NON_NORMALIZED' | 'BLOCKED' | 'DELETED' | 'DUPLICATE')[];
	/** Id родительской позиции классификатора */
	parentItemId?: string;
	/** Признак того, является ли позиция рутовой (без родителя) */
	root?: boolean;
	/** Id записей */
	ids?: string[];
	/** Фильтр по подстроке в любом значении из values */
	searchSubstring?: string;
	/** Расширенный поиск по значениям в values позиции */
	searchDetails?: string;
};
export type DeleteClassifierItemApiResponse = /** status 200 OK */
	| '100 CONTINUE'
	| '101 SWITCHING_PROTOCOLS'
	| '102 PROCESSING'
	| '103 EARLY_HINTS'
	| '103 CHECKPOINT'
	| '200 OK'
	| '201 CREATED'
	| '202 ACCEPTED'
	| '203 NON_AUTHORITATIVE_INFORMATION'
	| '204 NO_CONTENT'
	| '205 RESET_CONTENT'
	| '206 PARTIAL_CONTENT'
	| '207 MULTI_STATUS'
	| '208 ALREADY_REPORTED'
	| '226 IM_USED'
	| '300 MULTIPLE_CHOICES'
	| '301 MOVED_PERMANENTLY'
	| '302 FOUND'
	| '302 MOVED_TEMPORARILY'
	| '303 SEE_OTHER'
	| '304 NOT_MODIFIED'
	| '305 USE_PROXY'
	| '307 TEMPORARY_REDIRECT'
	| '308 PERMANENT_REDIRECT'
	| '400 BAD_REQUEST'
	| '401 UNAUTHORIZED'
	| '402 PAYMENT_REQUIRED'
	| '403 FORBIDDEN'
	| '404 NOT_FOUND'
	| '405 METHOD_NOT_ALLOWED'
	| '406 NOT_ACCEPTABLE'
	| '407 PROXY_AUTHENTICATION_REQUIRED'
	| '408 REQUEST_TIMEOUT'
	| '409 CONFLICT'
	| '410 GONE'
	| '411 LENGTH_REQUIRED'
	| '412 PRECONDITION_FAILED'
	| '413 PAYLOAD_TOO_LARGE'
	| '413 REQUEST_ENTITY_TOO_LARGE'
	| '414 URI_TOO_LONG'
	| '414 REQUEST_URI_TOO_LONG'
	| '415 UNSUPPORTED_MEDIA_TYPE'
	| '416 REQUESTED_RANGE_NOT_SATISFIABLE'
	| '417 EXPECTATION_FAILED'
	| '418 I_AM_A_TEAPOT'
	| '419 INSUFFICIENT_SPACE_ON_RESOURCE'
	| '420 METHOD_FAILURE'
	| '421 DESTINATION_LOCKED'
	| '422 UNPROCESSABLE_ENTITY'
	| '423 LOCKED'
	| '424 FAILED_DEPENDENCY'
	| '425 TOO_EARLY'
	| '426 UPGRADE_REQUIRED'
	| '428 PRECONDITION_REQUIRED'
	| '429 TOO_MANY_REQUESTS'
	| '431 REQUEST_HEADER_FIELDS_TOO_LARGE'
	| '451 UNAVAILABLE_FOR_LEGAL_REASONS'
	| '500 INTERNAL_SERVER_ERROR'
	| '501 NOT_IMPLEMENTED'
	| '502 BAD_GATEWAY'
	| '503 SERVICE_UNAVAILABLE'
	| '504 GATEWAY_TIMEOUT'
	| '505 HTTP_VERSION_NOT_SUPPORTED'
	| '506 VARIANT_ALSO_NEGOTIATES'
	| '507 INSUFFICIENT_STORAGE'
	| '508 LOOP_DETECTED'
	| '509 BANDWIDTH_LIMIT_EXCEEDED'
	| '510 NOT_EXTENDED'
	| '511 NETWORK_AUTHENTICATION_REQUIRED';
export type DeleteClassifierItemApiArg = {
	ids: string[];
};
export type GetClassifierItemApiResponse = /** status 200 OK */ ClassifierItemResponse;
export type GetClassifierItemApiArg = {
	id: string;
};
export type GetItemsByClassifierItemIdApiResponse = /** status 200 OK */ CatalogItemResponse[];
export type GetItemsByClassifierItemIdApiArg = {
	classifierItemId: string;
};
export type GetCatalogTreeApiResponse = /** status 200 OK */ CatalogTreeNodeResponse;
export type GetCatalogTreeApiArg = {
	/** id справочника, с которого начинается построение дерева */
	id: string;
	/** направление, в котором строится дерево. UP/DOWN */
	direction: string;
	/** флаг включения отображения детей найденных справочников */
	includeChildren?: boolean;
	/** Фильтр по подстроке в названии или описании справочника */
	searchSubstring?: string;
	/** Поле, по которому ведется сортировка */
	sortBy: 'DISPLAY_NAME' | 'DESCRIPTION' | 'CREATED_AT';
	/** Тип сортировки (по возрастанию, по убыванию) */
	sortType: 'ASC' | 'DESC';
};
export type GetCatalogItemsApiResponse = /** status 200 OK */ SliceCatalogItemResponse;
export type GetCatalogItemsApiArg = {
	/** Ограничение по количеству записей на странице */
	limit?: number;
	/** Номер страницы */
	page?: number;
	/** Поле, по которому ведется сортировка. Может быть displayName, status или uuid декларации */
	sortBy?: string;
	/** Тип сортировки (по возрастанию, по убыванию) */
	sortType?: 'ASC' | 'DESC';
	/** Фильтр по каталогам, к которым принадлежит позиция */
	catalogIds?: string[];
	/** Фильтр по статусам позиции */
	statuses?: ('NORMALIZED' | 'NON_NORMALIZED' | 'BLOCKED' | 'DELETED' | 'DUPLICATE')[];
	/** Флаг включения поиска позиций во вложенных каталогах */
	includeSubCatalogs?: boolean;
	/** id деклараций, которые вернутся в ответе */
	attributeDeclarationIds?: string[];
	/** id позиций */
	ids?: string[];
	/** Фильтр по подстроке в displayName */
	searchSubstring?: string;
	/** Расширенный поиск по значениям в values позиции */
	searchDetails?: string;
};
export type DeleteCatalogItemApiResponse = /** status 200 OK */
	| '100 CONTINUE'
	| '101 SWITCHING_PROTOCOLS'
	| '102 PROCESSING'
	| '103 EARLY_HINTS'
	| '103 CHECKPOINT'
	| '200 OK'
	| '201 CREATED'
	| '202 ACCEPTED'
	| '203 NON_AUTHORITATIVE_INFORMATION'
	| '204 NO_CONTENT'
	| '205 RESET_CONTENT'
	| '206 PARTIAL_CONTENT'
	| '207 MULTI_STATUS'
	| '208 ALREADY_REPORTED'
	| '226 IM_USED'
	| '300 MULTIPLE_CHOICES'
	| '301 MOVED_PERMANENTLY'
	| '302 FOUND'
	| '302 MOVED_TEMPORARILY'
	| '303 SEE_OTHER'
	| '304 NOT_MODIFIED'
	| '305 USE_PROXY'
	| '307 TEMPORARY_REDIRECT'
	| '308 PERMANENT_REDIRECT'
	| '400 BAD_REQUEST'
	| '401 UNAUTHORIZED'
	| '402 PAYMENT_REQUIRED'
	| '403 FORBIDDEN'
	| '404 NOT_FOUND'
	| '405 METHOD_NOT_ALLOWED'
	| '406 NOT_ACCEPTABLE'
	| '407 PROXY_AUTHENTICATION_REQUIRED'
	| '408 REQUEST_TIMEOUT'
	| '409 CONFLICT'
	| '410 GONE'
	| '411 LENGTH_REQUIRED'
	| '412 PRECONDITION_FAILED'
	| '413 PAYLOAD_TOO_LARGE'
	| '413 REQUEST_ENTITY_TOO_LARGE'
	| '414 URI_TOO_LONG'
	| '414 REQUEST_URI_TOO_LONG'
	| '415 UNSUPPORTED_MEDIA_TYPE'
	| '416 REQUESTED_RANGE_NOT_SATISFIABLE'
	| '417 EXPECTATION_FAILED'
	| '418 I_AM_A_TEAPOT'
	| '419 INSUFFICIENT_SPACE_ON_RESOURCE'
	| '420 METHOD_FAILURE'
	| '421 DESTINATION_LOCKED'
	| '422 UNPROCESSABLE_ENTITY'
	| '423 LOCKED'
	| '424 FAILED_DEPENDENCY'
	| '425 TOO_EARLY'
	| '426 UPGRADE_REQUIRED'
	| '428 PRECONDITION_REQUIRED'
	| '429 TOO_MANY_REQUESTS'
	| '431 REQUEST_HEADER_FIELDS_TOO_LARGE'
	| '451 UNAVAILABLE_FOR_LEGAL_REASONS'
	| '500 INTERNAL_SERVER_ERROR'
	| '501 NOT_IMPLEMENTED'
	| '502 BAD_GATEWAY'
	| '503 SERVICE_UNAVAILABLE'
	| '504 GATEWAY_TIMEOUT'
	| '505 HTTP_VERSION_NOT_SUPPORTED'
	| '506 VARIANT_ALSO_NEGOTIATES'
	| '507 INSUFFICIENT_STORAGE'
	| '508 LOOP_DETECTED'
	| '509 BANDWIDTH_LIMIT_EXCEEDED'
	| '510 NOT_EXTENDED'
	| '511 NETWORK_AUTHENTICATION_REQUIRED';
export type DeleteCatalogItemApiArg = {
	ids: string[];
};
export type GetCatalogItemApiResponse = /** status 200 OK */ CatalogItemResponse;
export type GetCatalogItemApiArg = {
	id: string;
};
export type GetAttributesApiResponse = /** status 200 OK */ SliceAttributeResponse;
export type GetAttributesApiArg = {
	limit?: number;
	page?: number;
	sortBy?: string;
	sortType?: string;
	type?: string;
	ids?: string[];
	attributeGroupIds?: string[];
	includeSubGroups?: boolean;
};
export type DeleteAttributeApiResponse = /** status 200 OK */
	| '100 CONTINUE'
	| '101 SWITCHING_PROTOCOLS'
	| '102 PROCESSING'
	| '103 EARLY_HINTS'
	| '103 CHECKPOINT'
	| '200 OK'
	| '201 CREATED'
	| '202 ACCEPTED'
	| '203 NON_AUTHORITATIVE_INFORMATION'
	| '204 NO_CONTENT'
	| '205 RESET_CONTENT'
	| '206 PARTIAL_CONTENT'
	| '207 MULTI_STATUS'
	| '208 ALREADY_REPORTED'
	| '226 IM_USED'
	| '300 MULTIPLE_CHOICES'
	| '301 MOVED_PERMANENTLY'
	| '302 FOUND'
	| '302 MOVED_TEMPORARILY'
	| '303 SEE_OTHER'
	| '304 NOT_MODIFIED'
	| '305 USE_PROXY'
	| '307 TEMPORARY_REDIRECT'
	| '308 PERMANENT_REDIRECT'
	| '400 BAD_REQUEST'
	| '401 UNAUTHORIZED'
	| '402 PAYMENT_REQUIRED'
	| '403 FORBIDDEN'
	| '404 NOT_FOUND'
	| '405 METHOD_NOT_ALLOWED'
	| '406 NOT_ACCEPTABLE'
	| '407 PROXY_AUTHENTICATION_REQUIRED'
	| '408 REQUEST_TIMEOUT'
	| '409 CONFLICT'
	| '410 GONE'
	| '411 LENGTH_REQUIRED'
	| '412 PRECONDITION_FAILED'
	| '413 PAYLOAD_TOO_LARGE'
	| '413 REQUEST_ENTITY_TOO_LARGE'
	| '414 URI_TOO_LONG'
	| '414 REQUEST_URI_TOO_LONG'
	| '415 UNSUPPORTED_MEDIA_TYPE'
	| '416 REQUESTED_RANGE_NOT_SATISFIABLE'
	| '417 EXPECTATION_FAILED'
	| '418 I_AM_A_TEAPOT'
	| '419 INSUFFICIENT_SPACE_ON_RESOURCE'
	| '420 METHOD_FAILURE'
	| '421 DESTINATION_LOCKED'
	| '422 UNPROCESSABLE_ENTITY'
	| '423 LOCKED'
	| '424 FAILED_DEPENDENCY'
	| '425 TOO_EARLY'
	| '426 UPGRADE_REQUIRED'
	| '428 PRECONDITION_REQUIRED'
	| '429 TOO_MANY_REQUESTS'
	| '431 REQUEST_HEADER_FIELDS_TOO_LARGE'
	| '451 UNAVAILABLE_FOR_LEGAL_REASONS'
	| '500 INTERNAL_SERVER_ERROR'
	| '501 NOT_IMPLEMENTED'
	| '502 BAD_GATEWAY'
	| '503 SERVICE_UNAVAILABLE'
	| '504 GATEWAY_TIMEOUT'
	| '505 HTTP_VERSION_NOT_SUPPORTED'
	| '506 VARIANT_ALSO_NEGOTIATES'
	| '507 INSUFFICIENT_STORAGE'
	| '508 LOOP_DETECTED'
	| '509 BANDWIDTH_LIMIT_EXCEEDED'
	| '510 NOT_EXTENDED'
	| '511 NETWORK_AUTHENTICATION_REQUIRED';
export type DeleteAttributeApiArg = {
	ids: string[];
};
export type GetAttributeApiResponse = /** status 200 OK */ AttributeResponse;
export type GetAttributeApiArg = {
	id: string;
};
export type GetCatalogsByAttributeApiResponse = /** status 200 OK */ CatalogResponse[];
export type GetCatalogsByAttributeApiArg = {
	attributeId: string;
};
export type GetClassifierByItemIdApiResponse = /** status 200 OK */ CatalogItemResponse[];
export type GetClassifierByItemIdApiArg = {
	attributeId: string;
	classifierItemId?: string;
};
export type GetAttributeGroupTreeApiResponse = /** status 200 OK */ AttributeGroupTreeNodeResponse;
export type GetAttributeGroupTreeApiArg = {
	id: string;
	direction: string;
	includeChildren?: boolean;
};
export type GetPresignedFileUrlApiResponse = /** status 200 OK */ string;
export type GetPresignedFileUrlApiArg = {
	id: string;
};
export type DownloadFileApiResponse = /** status 200 OK */ Blob;
export type DownloadFileApiArg = {
	id: string;
};
export type GetEntityVersionsApiResponse = /** status 200 OK */ object[];
export type GetEntityVersionsApiArg = {
	authorization: string;
	entityId: string;
	entityType: string;
	transactionIds: string[];
};
export type GetEntityTransactionsApiResponse = /** status 200 OK */ SliceTransactionDto;
export type GetEntityTransactionsApiArg = {
	entityId: string;
	entityType: string;
	limit?: number;
	page?: number;
	sortBy?: string;
	sortType?: string;
};
export type GetEventsApiResponse = /** status 200 OK */ SliceStateChangeEventDto;
export type GetEventsApiArg = {
	entityId: string;
	entityType: string;
	limit?: number;
	page?: number;
};
export type GetEntityDifferenceApiResponse = /** status 200 OK */ EntityChangeDto;
export type GetEntityDifferenceApiArg = {
	entityId: string;
	entityType: string;
	fromDate: string;
	toDate: string;
};
export type GetEntityChangesApiResponse = /** status 200 OK */ SliceStateChangeSplitEventResponse;
export type GetEntityChangesApiArg = {
	/** Id сущности, для которой ищем изменения */
	entityId: string;
	/** Тип событий, которые ищем */
	eventType?:
		| 'CREATE'
		| 'UPDATE'
		| 'DELETE'
		| 'ROLLBACK_CREATE'
		| 'ROLLBACK_UPDATE'
		| 'ROLLBACK_DELETE'
		| 'BEGIN_TRANSACTION'
		| 'END_TRANSACTION';
	/** Id транзакции, в которой произошло событие */
	transactionId?: string[];
	/** Id пользователя, совершившего событие */
	userId?: string[];
	/** Дата, до которой произошло событие */
	fromDate?: string;
	/** Дата, после которой произошло событие */
	toDate?: string;
	/** Ограничение по количеству записей на странице */
	limit?: number;
	/** Номер страницы */
	page?: number;
	/** Поле, по которому ведется сортировка. На текущий момент доступна сортировка по createdAt, userId, transactionId */
	sortBy?: string;
	/** Тип сортировки (по возрастанию, по убыванию) */
	sortType?: string;
};
export type DeleteClassifierAsyncApiResponse = /** status 200 OK */ BackgroundTaskStartDto;
export type DeleteClassifierAsyncApiArg = {
	ids: string[];
};
export type DeleteClassifierAttributeDeclarationAsyncApiResponse =
	/** status 200 OK */ BackgroundTaskStartDto;
export type DeleteClassifierAttributeDeclarationAsyncApiArg = {
	ids: string[];
};
export type DeleteCatalogAttributeDeclarationAsyncApiResponse =
	/** status 200 OK */ BackgroundTaskStartDto;
export type DeleteCatalogAttributeDeclarationAsyncApiArg = {
	ids: string[];
};
export type DeleteAttributeAsyncApiResponse = /** status 200 OK */ BackgroundTaskStartDto;
export type DeleteAttributeAsyncApiArg = {
	ids: string[];
};
export type DeleteAttributeGroupAsyncApiResponse = /** status 200 OK */ BackgroundTaskStartDto;
export type DeleteAttributeGroupAsyncApiArg = {
	ids: string[];
};
export type DeleteRestrictionTableItemsApiResponse = /** status 200 OK */
	| '100 CONTINUE'
	| '101 SWITCHING_PROTOCOLS'
	| '102 PROCESSING'
	| '103 EARLY_HINTS'
	| '103 CHECKPOINT'
	| '200 OK'
	| '201 CREATED'
	| '202 ACCEPTED'
	| '203 NON_AUTHORITATIVE_INFORMATION'
	| '204 NO_CONTENT'
	| '205 RESET_CONTENT'
	| '206 PARTIAL_CONTENT'
	| '207 MULTI_STATUS'
	| '208 ALREADY_REPORTED'
	| '226 IM_USED'
	| '300 MULTIPLE_CHOICES'
	| '301 MOVED_PERMANENTLY'
	| '302 FOUND'
	| '302 MOVED_TEMPORARILY'
	| '303 SEE_OTHER'
	| '304 NOT_MODIFIED'
	| '305 USE_PROXY'
	| '307 TEMPORARY_REDIRECT'
	| '308 PERMANENT_REDIRECT'
	| '400 BAD_REQUEST'
	| '401 UNAUTHORIZED'
	| '402 PAYMENT_REQUIRED'
	| '403 FORBIDDEN'
	| '404 NOT_FOUND'
	| '405 METHOD_NOT_ALLOWED'
	| '406 NOT_ACCEPTABLE'
	| '407 PROXY_AUTHENTICATION_REQUIRED'
	| '408 REQUEST_TIMEOUT'
	| '409 CONFLICT'
	| '410 GONE'
	| '411 LENGTH_REQUIRED'
	| '412 PRECONDITION_FAILED'
	| '413 PAYLOAD_TOO_LARGE'
	| '413 REQUEST_ENTITY_TOO_LARGE'
	| '414 URI_TOO_LONG'
	| '414 REQUEST_URI_TOO_LONG'
	| '415 UNSUPPORTED_MEDIA_TYPE'
	| '416 REQUESTED_RANGE_NOT_SATISFIABLE'
	| '417 EXPECTATION_FAILED'
	| '418 I_AM_A_TEAPOT'
	| '419 INSUFFICIENT_SPACE_ON_RESOURCE'
	| '420 METHOD_FAILURE'
	| '421 DESTINATION_LOCKED'
	| '422 UNPROCESSABLE_ENTITY'
	| '423 LOCKED'
	| '424 FAILED_DEPENDENCY'
	| '425 TOO_EARLY'
	| '426 UPGRADE_REQUIRED'
	| '428 PRECONDITION_REQUIRED'
	| '429 TOO_MANY_REQUESTS'
	| '431 REQUEST_HEADER_FIELDS_TOO_LARGE'
	| '451 UNAVAILABLE_FOR_LEGAL_REASONS'
	| '500 INTERNAL_SERVER_ERROR'
	| '501 NOT_IMPLEMENTED'
	| '502 BAD_GATEWAY'
	| '503 SERVICE_UNAVAILABLE'
	| '504 GATEWAY_TIMEOUT'
	| '505 HTTP_VERSION_NOT_SUPPORTED'
	| '506 VARIANT_ALSO_NEGOTIATES'
	| '507 INSUFFICIENT_STORAGE'
	| '508 LOOP_DETECTED'
	| '509 BANDWIDTH_LIMIT_EXCEEDED'
	| '510 NOT_EXTENDED'
	| '511 NETWORK_AUTHENTICATION_REQUIRED';
export type DeleteRestrictionTableItemsApiArg = {
	ids: string[];
};
export type DeleteClassifierAttributeDeclarationApiResponse = /** status 200 OK */
	| '100 CONTINUE'
	| '101 SWITCHING_PROTOCOLS'
	| '102 PROCESSING'
	| '103 EARLY_HINTS'
	| '103 CHECKPOINT'
	| '200 OK'
	| '201 CREATED'
	| '202 ACCEPTED'
	| '203 NON_AUTHORITATIVE_INFORMATION'
	| '204 NO_CONTENT'
	| '205 RESET_CONTENT'
	| '206 PARTIAL_CONTENT'
	| '207 MULTI_STATUS'
	| '208 ALREADY_REPORTED'
	| '226 IM_USED'
	| '300 MULTIPLE_CHOICES'
	| '301 MOVED_PERMANENTLY'
	| '302 FOUND'
	| '302 MOVED_TEMPORARILY'
	| '303 SEE_OTHER'
	| '304 NOT_MODIFIED'
	| '305 USE_PROXY'
	| '307 TEMPORARY_REDIRECT'
	| '308 PERMANENT_REDIRECT'
	| '400 BAD_REQUEST'
	| '401 UNAUTHORIZED'
	| '402 PAYMENT_REQUIRED'
	| '403 FORBIDDEN'
	| '404 NOT_FOUND'
	| '405 METHOD_NOT_ALLOWED'
	| '406 NOT_ACCEPTABLE'
	| '407 PROXY_AUTHENTICATION_REQUIRED'
	| '408 REQUEST_TIMEOUT'
	| '409 CONFLICT'
	| '410 GONE'
	| '411 LENGTH_REQUIRED'
	| '412 PRECONDITION_FAILED'
	| '413 PAYLOAD_TOO_LARGE'
	| '413 REQUEST_ENTITY_TOO_LARGE'
	| '414 URI_TOO_LONG'
	| '414 REQUEST_URI_TOO_LONG'
	| '415 UNSUPPORTED_MEDIA_TYPE'
	| '416 REQUESTED_RANGE_NOT_SATISFIABLE'
	| '417 EXPECTATION_FAILED'
	| '418 I_AM_A_TEAPOT'
	| '419 INSUFFICIENT_SPACE_ON_RESOURCE'
	| '420 METHOD_FAILURE'
	| '421 DESTINATION_LOCKED'
	| '422 UNPROCESSABLE_ENTITY'
	| '423 LOCKED'
	| '424 FAILED_DEPENDENCY'
	| '425 TOO_EARLY'
	| '426 UPGRADE_REQUIRED'
	| '428 PRECONDITION_REQUIRED'
	| '429 TOO_MANY_REQUESTS'
	| '431 REQUEST_HEADER_FIELDS_TOO_LARGE'
	| '451 UNAVAILABLE_FOR_LEGAL_REASONS'
	| '500 INTERNAL_SERVER_ERROR'
	| '501 NOT_IMPLEMENTED'
	| '502 BAD_GATEWAY'
	| '503 SERVICE_UNAVAILABLE'
	| '504 GATEWAY_TIMEOUT'
	| '505 HTTP_VERSION_NOT_SUPPORTED'
	| '506 VARIANT_ALSO_NEGOTIATES'
	| '507 INSUFFICIENT_STORAGE'
	| '508 LOOP_DETECTED'
	| '509 BANDWIDTH_LIMIT_EXCEEDED'
	| '510 NOT_EXTENDED'
	| '511 NETWORK_AUTHENTICATION_REQUIRED';
export type DeleteClassifierAttributeDeclarationApiArg = {
	ids: string[];
};
export type DeleteCatalogRestrictionTableDeclarationApiResponse = /** status 200 OK */
	| '100 CONTINUE'
	| '101 SWITCHING_PROTOCOLS'
	| '102 PROCESSING'
	| '103 EARLY_HINTS'
	| '103 CHECKPOINT'
	| '200 OK'
	| '201 CREATED'
	| '202 ACCEPTED'
	| '203 NON_AUTHORITATIVE_INFORMATION'
	| '204 NO_CONTENT'
	| '205 RESET_CONTENT'
	| '206 PARTIAL_CONTENT'
	| '207 MULTI_STATUS'
	| '208 ALREADY_REPORTED'
	| '226 IM_USED'
	| '300 MULTIPLE_CHOICES'
	| '301 MOVED_PERMANENTLY'
	| '302 FOUND'
	| '302 MOVED_TEMPORARILY'
	| '303 SEE_OTHER'
	| '304 NOT_MODIFIED'
	| '305 USE_PROXY'
	| '307 TEMPORARY_REDIRECT'
	| '308 PERMANENT_REDIRECT'
	| '400 BAD_REQUEST'
	| '401 UNAUTHORIZED'
	| '402 PAYMENT_REQUIRED'
	| '403 FORBIDDEN'
	| '404 NOT_FOUND'
	| '405 METHOD_NOT_ALLOWED'
	| '406 NOT_ACCEPTABLE'
	| '407 PROXY_AUTHENTICATION_REQUIRED'
	| '408 REQUEST_TIMEOUT'
	| '409 CONFLICT'
	| '410 GONE'
	| '411 LENGTH_REQUIRED'
	| '412 PRECONDITION_FAILED'
	| '413 PAYLOAD_TOO_LARGE'
	| '413 REQUEST_ENTITY_TOO_LARGE'
	| '414 URI_TOO_LONG'
	| '414 REQUEST_URI_TOO_LONG'
	| '415 UNSUPPORTED_MEDIA_TYPE'
	| '416 REQUESTED_RANGE_NOT_SATISFIABLE'
	| '417 EXPECTATION_FAILED'
	| '418 I_AM_A_TEAPOT'
	| '419 INSUFFICIENT_SPACE_ON_RESOURCE'
	| '420 METHOD_FAILURE'
	| '421 DESTINATION_LOCKED'
	| '422 UNPROCESSABLE_ENTITY'
	| '423 LOCKED'
	| '424 FAILED_DEPENDENCY'
	| '425 TOO_EARLY'
	| '426 UPGRADE_REQUIRED'
	| '428 PRECONDITION_REQUIRED'
	| '429 TOO_MANY_REQUESTS'
	| '431 REQUEST_HEADER_FIELDS_TOO_LARGE'
	| '451 UNAVAILABLE_FOR_LEGAL_REASONS'
	| '500 INTERNAL_SERVER_ERROR'
	| '501 NOT_IMPLEMENTED'
	| '502 BAD_GATEWAY'
	| '503 SERVICE_UNAVAILABLE'
	| '504 GATEWAY_TIMEOUT'
	| '505 HTTP_VERSION_NOT_SUPPORTED'
	| '506 VARIANT_ALSO_NEGOTIATES'
	| '507 INSUFFICIENT_STORAGE'
	| '508 LOOP_DETECTED'
	| '509 BANDWIDTH_LIMIT_EXCEEDED'
	| '510 NOT_EXTENDED'
	| '511 NETWORK_AUTHENTICATION_REQUIRED';
export type DeleteCatalogRestrictionTableDeclarationApiArg = {
	ids: string[];
};
export type DeleteCatalogAttributeDeclarationApiResponse = /** status 200 OK */
	| '100 CONTINUE'
	| '101 SWITCHING_PROTOCOLS'
	| '102 PROCESSING'
	| '103 EARLY_HINTS'
	| '103 CHECKPOINT'
	| '200 OK'
	| '201 CREATED'
	| '202 ACCEPTED'
	| '203 NON_AUTHORITATIVE_INFORMATION'
	| '204 NO_CONTENT'
	| '205 RESET_CONTENT'
	| '206 PARTIAL_CONTENT'
	| '207 MULTI_STATUS'
	| '208 ALREADY_REPORTED'
	| '226 IM_USED'
	| '300 MULTIPLE_CHOICES'
	| '301 MOVED_PERMANENTLY'
	| '302 FOUND'
	| '302 MOVED_TEMPORARILY'
	| '303 SEE_OTHER'
	| '304 NOT_MODIFIED'
	| '305 USE_PROXY'
	| '307 TEMPORARY_REDIRECT'
	| '308 PERMANENT_REDIRECT'
	| '400 BAD_REQUEST'
	| '401 UNAUTHORIZED'
	| '402 PAYMENT_REQUIRED'
	| '403 FORBIDDEN'
	| '404 NOT_FOUND'
	| '405 METHOD_NOT_ALLOWED'
	| '406 NOT_ACCEPTABLE'
	| '407 PROXY_AUTHENTICATION_REQUIRED'
	| '408 REQUEST_TIMEOUT'
	| '409 CONFLICT'
	| '410 GONE'
	| '411 LENGTH_REQUIRED'
	| '412 PRECONDITION_FAILED'
	| '413 PAYLOAD_TOO_LARGE'
	| '413 REQUEST_ENTITY_TOO_LARGE'
	| '414 URI_TOO_LONG'
	| '414 REQUEST_URI_TOO_LONG'
	| '415 UNSUPPORTED_MEDIA_TYPE'
	| '416 REQUESTED_RANGE_NOT_SATISFIABLE'
	| '417 EXPECTATION_FAILED'
	| '418 I_AM_A_TEAPOT'
	| '419 INSUFFICIENT_SPACE_ON_RESOURCE'
	| '420 METHOD_FAILURE'
	| '421 DESTINATION_LOCKED'
	| '422 UNPROCESSABLE_ENTITY'
	| '423 LOCKED'
	| '424 FAILED_DEPENDENCY'
	| '425 TOO_EARLY'
	| '426 UPGRADE_REQUIRED'
	| '428 PRECONDITION_REQUIRED'
	| '429 TOO_MANY_REQUESTS'
	| '431 REQUEST_HEADER_FIELDS_TOO_LARGE'
	| '451 UNAVAILABLE_FOR_LEGAL_REASONS'
	| '500 INTERNAL_SERVER_ERROR'
	| '501 NOT_IMPLEMENTED'
	| '502 BAD_GATEWAY'
	| '503 SERVICE_UNAVAILABLE'
	| '504 GATEWAY_TIMEOUT'
	| '505 HTTP_VERSION_NOT_SUPPORTED'
	| '506 VARIANT_ALSO_NEGOTIATES'
	| '507 INSUFFICIENT_STORAGE'
	| '508 LOOP_DETECTED'
	| '509 BANDWIDTH_LIMIT_EXCEEDED'
	| '510 NOT_EXTENDED'
	| '511 NETWORK_AUTHENTICATION_REQUIRED';
export type DeleteCatalogAttributeDeclarationApiArg = {
	ids: string[];
};
export type BackgroundTaskStartDto = {
	id: string;
};
export type UpdateClassifierRequest = {
	displayName: string;
	description?: string;
	linkClassifierAttributes: string[];
	displayNameAttributeDeclarationId?: string;
	createFormulaId?: string;
	updateFormulaId?: string;
};
export type UpdateClassifierAttributeDeclarationRequest = {
	restrictions: {
		[key: string]: object;
	};
};
export type UpdateCatalogRequest = {
	displayName: string;
	description?: string;
	parentId?: string;
	createFormulaId?: string;
	updateFormulaId?: string;
	displayNameAttributeDeclarationId?: string;
};
export type UpdateCatalogAttributeDeclarationRequest = {
	restrictions: {
		[key: string]: object;
	};
};
export type RestrictionTableItemResponse = {
	id: string;
	restrictionTableId: string;
	values: {
		[key: string]: object;
	};
	createdAt: string;
	updatedAt: string;
};
export type UpdateRestrictionTableItemRequest = {
	values: {
		[key: string]: object;
	};
};
export type RestrictionTableResponse = {
	id: string;
	displayName: string;
	description?: string;
	attributeIds: string[];
	itemCount: number;
	createdAt: string;
	updatedAt: string;
};
export type UpdateRestrictionTableRequest = {
	displayName: string;
	description?: string;
	attributeIds: string[];
};
export type MeasurementResponse = {
	id: string;
	displayName: string;
	shortName?: string;
	measurementGroupId: string;
	formula?: string;
	inverseFormula?: string;
	coefficient: number;
	createdAt: string;
	updatedAt: string;
};
export type UpdateMeasurementRequest = {
	displayName: string;
	shortName?: string;
	formula?: string;
	inverseFormula?: string;
	coefficient: number;
};
export type AttributeInfo = {
	id: string;
	displayName: string;
};
export type ClassifierResponse = {
	id: string;
	displayName: string;
	description?: string;
	linkClassifierAttributes: AttributeInfo[];
	createFormulaId?: string;
	updateFormulaId?: string;
	displayNameAttributeDeclarationId?: string;
	createdAt: string;
	updatedAt: string;
};
export type ValueDto = {
	attributeId: string;
	attributeName: string;
	value?: object;
};
export type ClassifierItemResponse = {
	id: string;
	description?: string;
	displayName?: string;
	parent: boolean;
	status: 'NORMALIZED' | 'NON_NORMALIZED' | 'BLOCKED' | 'DELETED' | 'DUPLICATE';
	parentItemId?: string;
	classifierId: string;
	values: {
		[key: string]: ValueDto;
	};
	createdAt: string;
	updatedAt: string;
};
export type UpdateClassifierItemRequest = {
	displayName?: string;
	status: 'NORMALIZED' | 'NON_NORMALIZED' | 'BLOCKED' | 'DELETED' | 'DUPLICATE';
	description?: string;
	parentItemId?: string;
	values: {
		[key: string]: object;
	};
};
export type AttributeDto = {
	id: string;
	displayName: string;
	description?: string;
	type:
		| 'STRING'
		| 'FLOAT'
		| 'INTEGER'
		| 'BOOLEAN'
		| 'TEXT'
		| 'DATE_TIME'
		| 'COLOR'
		| 'FILE'
		| 'FORMULA'
		| 'RELATION';
	list: boolean;
	associatedAttributeIds: string[];
	attributeGroupId: string;
	measurementGroupId?: string;
	createdAt: string;
	updatedAt: string;
	restrictions: {
		[key: string]: object;
	};
};
export type ClassifierAttributeDeclarationResponse = {
	id: string;
	classifierId: string;
	attribute: AttributeDto;
	inherited: boolean;
	createdAt: string;
	updatedAt: string;
	restrictions: {
		[key: string]: object;
	};
};
export type CatalogResponse = {
	id: string;
	displayName: string;
	description?: string;
	parentId?: string;
	createFormulaId?: string;
	updateFormulaId?: string;
	parent: boolean;
	displayNameAttributeDeclarationId?: string;
	createdAt: string;
	updatedAt: string;
};
export type CatalogItemResponse = {
	id: string;
	displayName?: string;
	catalogId: string;
	values: {
		[key: string]: ValueDto;
	};
	status: 'NORMALIZED' | 'NON_NORMALIZED' | 'BLOCKED' | 'DELETED' | 'DUPLICATE';
	createdAt: string;
	updatedAt: string;
};
export type UpdateCatalogItemRequest = {
	values: {
		[key: string]: object;
	};
	status: 'NORMALIZED' | 'NON_NORMALIZED' | 'BLOCKED' | 'DELETED' | 'DUPLICATE';
};
export type RestrictionTableDto = {
	id: string;
	displayName: string;
	description?: string;
	attributeIds: string[];
	createdAt: string;
	updatedAt: string;
};
export type CatalogRestrictionTableDeclarationResponse = {
	id: string;
	required: boolean;
	generationValues: boolean;
	restrictionTable: RestrictionTableDto;
	catalogId: string;
	createdAt: string;
	updatedAt: string;
};
export type UpdateCatalogRestrictionTableDeclarationRequest = {
	required: boolean;
	generationValues: boolean;
};
export type CatalogAttributeDeclarationResponse = {
	id: string;
	catalogId: string;
	attribute: AttributeDto;
	inherited: boolean;
	createdAt: string;
	updatedAt: string;
	restrictions: {
		[key: string]: object;
	};
};
export type AttributeResponse = {
	id: string;
	displayName: string;
	description?: string;
	type:
		| 'STRING'
		| 'FLOAT'
		| 'INTEGER'
		| 'BOOLEAN'
		| 'TEXT'
		| 'DATE_TIME'
		| 'COLOR'
		| 'FILE'
		| 'FORMULA'
		| 'RELATION';
	list: boolean;
	associatedAttributeIds: string[];
	attributeGroupId: string;
	measurementGroupId?: string;
	createdAt: string;
	updatedAt: string;
	restrictions: {
		[key: string]: object;
	};
};
export type AttributeGroupResponse = {
	id: string;
	displayName: string;
	description?: string;
	parentId?: string;
	parent: boolean;
	createdAt: string;
	updatedAt: string;
};
export type UpdateAttributeGroupRequest = {
	displayName: string;
	description?: string;
	parentId?: string;
};
export type UpdateAttributeRequest = {
	displayName: string;
	description?: string;
	type:
		| 'STRING'
		| 'FLOAT'
		| 'INTEGER'
		| 'BOOLEAN'
		| 'TEXT'
		| 'DATE_TIME'
		| 'COLOR'
		| 'FILE'
		| 'FORMULA'
		| 'RELATION';
	list: boolean;
	associatedAttributeIds: string[];
	measurementGroupId?: string;
	restrictions: {
		[key: string]: object;
	};
};
export type ContextNameResponse = {
	id: string;
	contextDisplayName: string;
	inverseContextDisplayName: string;
	leftEntityName: string;
	rightEntityName: string;
};
export type UpdateContextNameRequest = {
	contextDisplayName: string;
	inverseContextDisplayName: string;
	leftEntityName: string;
	rightEntityName: string;
};
export type CompatibilityElementResponse = {
	id: string;
	type: 'CATALOG_ITEM' | 'CLASSIFIER_ITEM' | 'CATALOG' | 'CLASSIFIER';
	displayName?: string;
};
export type ContextNameDto = {
	id: string;
	contextDisplayName: string;
	inverseContextDisplayName: string;
	leftEntityName: string;
	rightEntityName: string;
	createdAt: string;
	updatedAt: string;
};
export type CompatibilityResponse = {
	id: string;
	firstEntity: CompatibilityElementResponse;
	secondEntity: CompatibilityElementResponse;
	isInherited: boolean;
	contextName: ContextNameDto;
	conditions: string;
	createdAt: string;
	updatedAt: string;
};
export type CompatibilityResponseWrite = {
	id: string;
	firstEntity: CompatibilityElementResponse;
	secondEntity: CompatibilityElementResponse;
	isInherited: boolean;
	contextName: ContextNameDto;
	conditions: string;
	createdAt: string;
	updatedAt: string;
	inherited?: boolean;
};
export type UpdateCompatibilityRequest = {
	conditions: string;
};
export type CreateClassifierAttributeDeclarationRequest = {
	attributeId: string;
	restrictions: {
		[key: string]: object;
	};
};
export type XlsxImportCatalogItemsRequest = {
	content: string;
};
export type CreateCatalogAttributeDeclarationRequest = {
	attributeId: string;
	restrictions: {
		[key: string]: object;
	};
};
export type CreateRestrictionTableRequest = {
	displayName: string;
	description?: string;
	attributeIds: string[];
};
export type CreateRestrictionTableItemRequest = {
	values: {
		[key: string]: object;
	};
};
export type MeasurementDto = {
	id: string;
	displayName: string;
	shortName?: string;
	measurementGroupId: string;
	formula?: string;
	inverseFormula?: string;
	coefficient: number;
	createdAt: string;
	updatedAt: string;
};
export type MeasurementGroupResponse = {
	id: string;
	displayName: string;
	description?: string;
	baseMeasurement?: MeasurementDto;
	createdAt: string;
	updatedAt: string;
};
export type CreateMeasurementGroupRequest = {
	displayName: string;
	description?: string;
};
export type CreateMeasurementRequest = {
	displayName: string;
	shortName?: string;
	formula?: string;
	inverseFormula?: string;
	coefficient: number;
};
export type CreateClassifierRequest = {
	displayName: string;
	description?: string;
	linkClassifierAttributes: string[];
	createFormulaId?: string;
	updateFormulaId?: string;
};
export type CreateClassifierItemRequest = {
	displayName?: string;
	status: 'NORMALIZED' | 'NON_NORMALIZED' | 'BLOCKED' | 'DELETED' | 'DUPLICATE';
	description?: string;
	parentItemId?: string;
	values: {
		[key: string]: object;
	};
};
export type CreateCatalogRequest = {
	displayName: string;
	description?: string;
	parentId?: string;
	createFormulaId?: string;
	updateFormulaId?: string;
};
export type CreateCatalogRestrictionTableDeclarationRequest = {
	required: boolean;
	generationValues: boolean;
	restrictionTableId: string;
};
export type CreateCatalogItemRequest = {
	values: {
		[key: string]: object;
	};
	status: 'NORMALIZED' | 'NON_NORMALIZED' | 'BLOCKED' | 'DELETED' | 'DUPLICATE';
};
export type CreateAttributeGroupRequest = {
	displayName: string;
	description?: string;
	parentId?: string;
};
export type CreateAttributeRequest = {
	displayName: string;
	description?: string;
	type:
		| 'STRING'
		| 'FLOAT'
		| 'INTEGER'
		| 'BOOLEAN'
		| 'TEXT'
		| 'DATE_TIME'
		| 'COLOR'
		| 'FILE'
		| 'FORMULA'
		| 'RELATION';
	list: boolean;
	associatedAttributeIds: string[];
	measurementGroupId?: string;
	restrictions: {
		[key: string]: object;
	};
};
export type UploadFileResponse = {
	id: string;
};
export type UploadFileFrontRequest = {
	displayName: string;
	contentType: string;
	size: number;
	contentBase64: string;
};
export type SliceMeta = {
	page: number;
	limit: number;
	total: number;
	sortBy: string;
	sortType: string;
};
export type SliceContextNameResponse = {
	data: ContextNameResponse[];
	meta: SliceMeta;
};
export type CreateContextNameRequest = {
	contextDisplayName: string;
	inverseContextDisplayName: string;
	leftEntityName: string;
	rightEntityName: string;
};
export type ItemResponse = {
	id: string;
	displayName?: string;
};
export type CompatibilityCatalogItemResponse = ItemResponse & {
	catalogId?: string;
	entityType?:
		| 'CATALOG'
		| 'ATTRIBUTE'
		| 'ATTRIBUTE_GROUP'
		| 'ATTRIBUTE_DECLARATION'
		| 'EXCHANGE_CLASS'
		| 'EXCHANGE_KEY'
		| 'ITEM'
		| 'MEASUREMENT'
		| 'MEASUREMENT_GROUP'
		| 'CLASSIFIER'
		| 'CLASSIFIER_ATTRIBUTE_DECLARATION'
		| 'CLASSIFIER_ITEM'
		| 'RESTRICTION_TABLE_DECLARATION'
		| 'RESTRICTION_TABLE'
		| 'RESTRICTION_TABLE_ITEM'
		| 'FORMULA'
		| 'COMPATIBILITY'
		| 'COMPATIBILITY_CONDITION'
		| 'COMPATIBILITY_ITEM'
		| 'CONTEXT_NAME'
		| 'UNKNOWN';
};
export type CompatibilityClassifierItemResponse = ItemResponse & {
	classifierId?: string;
	entityType?:
		| 'CATALOG'
		| 'ATTRIBUTE'
		| 'ATTRIBUTE_GROUP'
		| 'ATTRIBUTE_DECLARATION'
		| 'EXCHANGE_CLASS'
		| 'EXCHANGE_KEY'
		| 'ITEM'
		| 'MEASUREMENT'
		| 'MEASUREMENT_GROUP'
		| 'CLASSIFIER'
		| 'CLASSIFIER_ATTRIBUTE_DECLARATION'
		| 'CLASSIFIER_ITEM'
		| 'RESTRICTION_TABLE_DECLARATION'
		| 'RESTRICTION_TABLE'
		| 'RESTRICTION_TABLE_ITEM'
		| 'FORMULA'
		| 'COMPATIBILITY'
		| 'COMPATIBILITY_CONDITION'
		| 'COMPATIBILITY_ITEM'
		| 'CONTEXT_NAME'
		| 'UNKNOWN';
};
export type CompatibilityItemResponse = {
	id: string;
	item: CompatibilityCatalogItemResponse | CompatibilityClassifierItemResponse;
	isCalculated: boolean;
};
export type CompatibilityItemResponseWrite = {
	id: string;
	item: CompatibilityCatalogItemResponse | CompatibilityClassifierItemResponse;
	isCalculated: boolean;
	calculated?: boolean;
};
export type SliceCompatibilityItemResponse = {
	data: CompatibilityItemResponse[];
	meta: SliceMeta;
};
export type SliceCompatibilityItemResponseWrite = {
	data: CompatibilityItemResponseWrite[];
	meta: SliceMeta;
};
export type CreateCompatibilityItemRequest = {
	firstItemId: string;
	secondItemIds: string[];
	compatibilityId: string;
};
export type CompatibilityMultipleResponse = {
	id: string;
	firstEntity: CompatibilityElementResponse;
	secondEntity: CompatibilityElementResponse;
	isInherited: boolean;
	contextName: ContextNameDto;
	createdAt: string;
	updatedAt: string;
};
export type CompatibilityMultipleResponseWrite = {
	id: string;
	firstEntity: CompatibilityElementResponse;
	secondEntity: CompatibilityElementResponse;
	isInherited: boolean;
	contextName: ContextNameDto;
	createdAt: string;
	updatedAt: string;
	inherited?: boolean;
};
export type CreateCompatibilityRequest = {
	firstEntityId: string;
	firstEntityType: 'CATALOG_ITEM' | 'CLASSIFIER_ITEM' | 'CATALOG' | 'CLASSIFIER';
	secondEntityId: string;
	secondEntityType: 'CATALOG_ITEM' | 'CLASSIFIER_ITEM' | 'CATALOG' | 'CLASSIFIER';
	contextNameId: string;
};
export type UpdateMeasurementGroupRequest = {
	displayName: string;
	description?: string;
	baseMeasurementId?: string;
};
export type SliceCatalogResponse = {
	data: CatalogResponse[];
	meta: SliceMeta;
};
export type TransactionDto = {
	id: string;
	transactionStatus?:
		| 'ACTIVE'
		| 'PAUSED'
		| 'COMMITED'
		| 'TO_COMMIT'
		| 'PROCESSING_COMMIT'
		| 'COMMIT_ERROR';
	userId: string;
	displayName?: string;
	description?: string;
	createdAt?: string;
	updatedAt?: string;
	committedAt?: string;
	lastCommitAttempt?: string;
};
export type StateChangeEventDto = {
	id: string;
	entityId: string;
	entityType:
		| 'CATALOG'
		| 'ATTRIBUTE'
		| 'ATTRIBUTE_GROUP'
		| 'ATTRIBUTE_DECLARATION'
		| 'EXCHANGE_CLASS'
		| 'EXCHANGE_KEY'
		| 'ITEM'
		| 'MEASUREMENT'
		| 'MEASUREMENT_GROUP'
		| 'CLASSIFIER'
		| 'CLASSIFIER_ATTRIBUTE_DECLARATION'
		| 'CLASSIFIER_ITEM'
		| 'RESTRICTION_TABLE_DECLARATION'
		| 'RESTRICTION_TABLE'
		| 'RESTRICTION_TABLE_ITEM'
		| 'FORMULA'
		| 'COMPATIBILITY'
		| 'COMPATIBILITY_CONDITION'
		| 'COMPATIBILITY_ITEM'
		| 'CONTEXT_NAME'
		| 'UNKNOWN';
	eventType:
		| 'CREATE'
		| 'UPDATE'
		| 'DELETE'
		| 'ROLLBACK_CREATE'
		| 'ROLLBACK_UPDATE'
		| 'ROLLBACK_DELETE'
		| 'BEGIN_TRANSACTION'
		| 'END_TRANSACTION';
	userId: string;
	parentEventId?: string;
	transactionId: string;
	data: {
		[key: string]: object;
	};
	createdAt: string;
	committedAt?: string;
};
export type TransactionDetailsResponse = {
	transaction: TransactionDto;
	events?: StateChangeEventDto[];
};
export type SliceTransactionDetailsResponse = {
	data: TransactionDetailsResponse[];
	meta: SliceMeta;
};
export type ClassifierItemTreeNodeResponse = {
	id: string;
	displayName?: string;
	parent: boolean;
	children?: ClassifierItemTreeNodeResponse[];
};
export type SliceClassifierItemResponse = {
	data: ClassifierItemResponse[];
	meta: SliceMeta;
};
export type CatalogTreeNodeResponse = {
	id: string;
	displayName: string;
	description?: string;
	parentId?: string;
	createFormulaId?: string;
	updateFormulaId?: string;
	parent: boolean;
	displayNameAttributeDeclarationId?: string;
	createdAt: string;
	updatedAt: string;
	children?: CatalogTreeNodeResponse[];
};
export type SliceCatalogItemResponse = {
	data: CatalogItemResponse[];
	meta: SliceMeta;
};
export type SliceAttributeResponse = {
	data: AttributeResponse[];
	meta: SliceMeta;
};
export type AttributeGroupTreeNodeResponse = {
	id: string;
	displayName: string;
	description?: string;
	parentId?: string;
	parent: boolean;
	createdAt: string;
	updatedAt: string;
	children?: AttributeGroupTreeNodeResponse[];
};
export type SliceTransactionDto = {
	data: TransactionDto[];
	meta: SliceMeta;
};
export type SliceStateChangeEventDto = {
	data: StateChangeEventDto[];
	meta: SliceMeta;
};
export type EntityChangeDto = {
	oldData: {
		[key: string]: object;
	};
	newData: {
		[key: string]: object;
	};
};
export type StateChangeSplitEventResponse = {
	entityId: string;
	entityType:
		| 'CATALOG'
		| 'ATTRIBUTE'
		| 'ATTRIBUTE_GROUP'
		| 'ATTRIBUTE_DECLARATION'
		| 'EXCHANGE_CLASS'
		| 'EXCHANGE_KEY'
		| 'ITEM'
		| 'MEASUREMENT'
		| 'MEASUREMENT_GROUP'
		| 'CLASSIFIER'
		| 'CLASSIFIER_ATTRIBUTE_DECLARATION'
		| 'CLASSIFIER_ITEM'
		| 'RESTRICTION_TABLE_DECLARATION'
		| 'RESTRICTION_TABLE'
		| 'RESTRICTION_TABLE_ITEM'
		| 'FORMULA'
		| 'COMPATIBILITY'
		| 'COMPATIBILITY_CONDITION'
		| 'COMPATIBILITY_ITEM'
		| 'CONTEXT_NAME'
		| 'UNKNOWN';
	eventType:
		| 'CREATE'
		| 'UPDATE'
		| 'DELETE'
		| 'ROLLBACK_CREATE'
		| 'ROLLBACK_UPDATE'
		| 'ROLLBACK_DELETE'
		| 'BEGIN_TRANSACTION'
		| 'END_TRANSACTION';
	propertyName: string;
	value?: object;
	createdAt: string;
	userId: string;
	userName: string;
	transactionId: string;
	transactionDisplayName: string;
};
export type SliceStateChangeSplitEventResponse = {
	data: StateChangeSplitEventResponse[];
	meta: SliceMeta;
};
export const {
	useUpdateClassifierAsyncMutation,
	useUpdateClassifierAttributeDeclarationAsyncMutation,
	useUpdateCatalogAsyncMutation,
	useUpdateCatalogAttributeDeclarationAsyncMutation,
	useUpdateRestrictionTableItemMutation,
	useGetRestrictionTableQuery,
	useLazyGetRestrictionTableQuery,
	useUpdateRestrictionTableMutation,
	useUpdateMeasurementMutation,
	useGetClassifierQuery,
	useLazyGetClassifierQuery,
	useUpdateClassifierMutation,
	useRollbackClassifierMutation,
	useUpdateClassifierItemMutation,
	useRollbackClassifierItemMutation,
	useGetAttributeDeclarationQuery,
	useLazyGetAttributeDeclarationQuery,
	useUpdateClassifierAttributeDeclarationMutation,
	useGetCatalogQuery,
	useLazyGetCatalogQuery,
	useUpdateCatalogMutation,
	useRollbackCatalogMutation,
	useUpdateCatalogItemMutation,
	useGetCatalogRestrictionTableDeclarationQuery,
	useLazyGetCatalogRestrictionTableDeclarationQuery,
	useUpdateCatalogRestrictionTableDeclarationMutation,
	useRollbackCatalogItemMutation,
	useGetCatalogAttributeDeclarationQuery,
	useLazyGetCatalogAttributeDeclarationQuery,
	useUpdateCatalogAttributeDeclarationMutation,
	useRollbackAttributeMutation,
	useGetAttributeGroup1Query,
	useLazyGetAttributeGroup1Query,
	useUpdateAttributeGroupMutation,
	useUpdateAttributeMutation,
	useGetContextNameQuery,
	useLazyGetContextNameQuery,
	useUpdateContextNameMutation,
	useGetCompatibilityQuery,
	useLazyGetCompatibilityQuery,
	useUpdateCompatibilitiesMutation,
	useCreateClassifierAttributeDeclarationsAsyncMutation,
	useImportXlsxAsyncMutation,
	useCreateCatalogAttributeDeclarationsAsyncMutation,
	useRollbackMutation,
	useCommitMutation,
	useGetRestrictionTablesQuery,
	useLazyGetRestrictionTablesQuery,
	useCreateRestrictionTableMutation,
	useDeleteRestrictionTableMutation,
	useGetRestrictionTableItemsQuery,
	useLazyGetRestrictionTableItemsQuery,
	useCreateRestrictionTableItemMutation,
	useGetMeasurementGroupsQuery,
	useLazyGetMeasurementGroupsQuery,
	useCreateMeasurementGroupMutation,
	useDeleteMeasurementGroupMutation,
	useGetMeasurementsQuery,
	useLazyGetMeasurementsQuery,
	useCreateMeasurementMutation,
	useGetClassifiersQuery,
	useLazyGetClassifiersQuery,
	useCreateClassifierMutation,
	useDeleteClassifierMutation,
	useCreateClassifierItemMutation,
	useGetClassifierAttributeDeclarationsQuery,
	useLazyGetClassifierAttributeDeclarationsQuery,
	useCreateClassifierAttributeDeclarationsMutation,
	useGetCatalogsQuery,
	useLazyGetCatalogsQuery,
	useCreateCatalogMutation,
	useDeleteCatalogsMutation,
	useGetCatalogRestrictionTableDeclarationsQuery,
	useLazyGetCatalogRestrictionTableDeclarationsQuery,
	useCreateCatalogRestrictionTableDeclarationsMutation,
	useCreateCatalogItemMutation,
	useImportXlsxMutation,
	useGetCatalogAttributeDeclarationsQuery,
	useLazyGetCatalogAttributeDeclarationsQuery,
	useCreateCatalogAttributeDeclarationsMutation,
	useGetAttributeGroupQuery,
	useLazyGetAttributeGroupQuery,
	useCreateAttributeGroupMutation,
	useDeleteAttributeGroupMutation,
	useCreateAttributeMutation,
	useUploadFileMutation,
	useGetContextNamesQuery,
	useLazyGetContextNamesQuery,
	useCreateContextNameMutation,
	useDeleteContextNamesMutation,
	useGetCompatibilityItemsQuery,
	useLazyGetCompatibilityItemsQuery,
	useCreateCompatibilityItemsMutation,
	useDeleteCompatibilityItemsMutation,
	useGetCompatibilitiesQuery,
	useLazyGetCompatibilitiesQuery,
	useCreateCompatibilityMutation,
	useDeleteCompatibilitiesMutation,
	useSearchCompatibilitiesMutation,
	useUpdateMeasurementAsyncMutation,
	useGetMeasurementGroupQuery,
	useLazyGetMeasurementGroupQuery,
	useUpdateMeasurementGroupMutation,
	useGetCatalogsV3Query,
	useLazyGetCatalogsV3Query,
	useDeleteCatalogsAsyncMutation,
	useGetTransactionsDetailsQuery,
	useLazyGetTransactionsDetailsQuery,
	useGetTransactionDetailsQuery,
	useLazyGetTransactionDetailsQuery,
	useGetRestrictionTableItemQuery,
	useLazyGetRestrictionTableItemQuery,
	useGetMeasurementQuery,
	useLazyGetMeasurementQuery,
	useDeleteMeasurementMutation,
	useGetMeasurement1Query,
	useLazyGetMeasurement1Query,
	useConvertQuery,
	useLazyConvertQuery,
	useGetClassifierItemTreeQuery,
	useLazyGetClassifierItemTreeQuery,
	useGetClassifierItemsQuery,
	useLazyGetClassifierItemsQuery,
	useDeleteClassifierItemMutation,
	useGetClassifierItemQuery,
	useLazyGetClassifierItemQuery,
	useGetItemsByClassifierItemIdQuery,
	useLazyGetItemsByClassifierItemIdQuery,
	useGetCatalogTreeQuery,
	useLazyGetCatalogTreeQuery,
	useGetCatalogItemsQuery,
	useLazyGetCatalogItemsQuery,
	useDeleteCatalogItemMutation,
	useGetCatalogItemQuery,
	useLazyGetCatalogItemQuery,
	useGetAttributesQuery,
	useLazyGetAttributesQuery,
	useDeleteAttributeMutation,
	useGetAttributeQuery,
	useLazyGetAttributeQuery,
	useGetCatalogsByAttributeQuery,
	useLazyGetCatalogsByAttributeQuery,
	useGetClassifierByItemIdQuery,
	useLazyGetClassifierByItemIdQuery,
	useGetAttributeGroupTreeQuery,
	useLazyGetAttributeGroupTreeQuery,
	useGetPresignedFileUrlQuery,
	useLazyGetPresignedFileUrlQuery,
	useDownloadFileQuery,
	useLazyDownloadFileQuery,
	useGetEntityVersionsQuery,
	useLazyGetEntityVersionsQuery,
	useGetEntityTransactionsQuery,
	useLazyGetEntityTransactionsQuery,
	useGetEventsQuery,
	useLazyGetEventsQuery,
	useGetEntityDifferenceQuery,
	useLazyGetEntityDifferenceQuery,
	useGetEntityChangesQuery,
	useLazyGetEntityChangesQuery,
	useDeleteClassifierAsyncMutation,
	useDeleteClassifierAttributeDeclarationAsyncMutation,
	useDeleteCatalogAttributeDeclarationAsyncMutation,
	useDeleteAttributeAsyncMutation,
	useDeleteAttributeGroupAsyncMutation,
	useDeleteRestrictionTableItemsMutation,
	useDeleteClassifierAttributeDeclarationMutation,
	useDeleteCatalogRestrictionTableDeclarationMutation,
	useDeleteCatalogAttributeDeclarationMutation,
} = injectedRtkApi;
