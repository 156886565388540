import { Flex, Form, Input } from 'antd';
import React, { forwardRef, memo, useEffect, useImperativeHandle } from 'react';
import { ContextNameResponse } from 'shared/api/generatedApi/mdmgApi';
import { useTypedTranslation } from 'shared/hooks';

interface ContextNameForm {
	leftEntityName: string | null;
	contextDisplayName: string | null;
	rightEntityName: string | null;
	inverseContextDisplayName: string | null;
}

interface EditContextNameFormPublicApi {
	getFormData: () => ContextNameForm;
}

interface IEditContextNameFormProps {
	item?: ContextNameResponse;
	onBlur?: () => void;
}

const EditContextNameFormComponent = forwardRef((
	{ item, onBlur }: IEditContextNameFormProps,
	ref,
) => {
	const { t } = useTypedTranslation();

	const [ form ] = Form.useForm<ContextNameForm>();

	useImperativeHandle<unknown, EditContextNameFormPublicApi>(ref, () => ({
		getFormData: () => form.getFieldsValue(),
	}));

	const resetForm = () => {
		form.setFieldsValue({
			leftEntityName: item?.leftEntityName,
			contextDisplayName: item?.contextDisplayName,
			rightEntityName: item?.rightEntityName,
			inverseContextDisplayName: item?.inverseContextDisplayName,
		});
	};

	useEffect(() => {
		resetForm();
	}, [ item ]);

	return (
		<Flex vertical gap={12} style={{ maxWidth: 920 }}>
			<Form layout="vertical" form={form}>
				<Form.Item label={t((l) => l.compatibilities.contextNames.leftEntityName)}
						   name="leftEntityName"
				>
					<Input allowClear
						   onBlur={onBlur}
					/>
				</Form.Item>
				<Form.Item label={t((l) => l.compatibilities.contextNames.contextDisplayName)}
						   name="contextDisplayName"
				>
					<Input allowClear
						   onBlur={onBlur}
					/>
				</Form.Item>
				<Form.Item label={t((l) => l.compatibilities.contextNames.rightEntityName)}
						   name="rightEntityName"
				>
					<Input allowClear
						   onBlur={onBlur}
					/>
				</Form.Item>
				<Form.Item label={t((l) => l.compatibilities.contextNames.inverseContextDisplayName)}
						   name="inverseContextDisplayName"
				>
					<Input allowClear
						   onBlur={onBlur}
					/>
				</Form.Item>

			</Form>
		</Flex>
	);
});

const EditContextNameForm = memo(EditContextNameFormComponent);

export {
	EditContextNameForm,
	type EditContextNameFormPublicApi,
	type IEditContextNameFormProps,
};
