import { RiAddCircleLine } from '@remixicon/react';
import { Button } from 'antd';
import React, { memo, useCallback } from 'react';
import { useNavigate } from 'react-router';
import { routes } from 'shared/config';

interface CreateExchangeConfigProps {
	integrationId: string;
}

const CreateExchangeConfigButton = memo((
	{
		integrationId,
	}: CreateExchangeConfigProps) => {

	const navigate = useNavigate();

	const createExchangeConfig = useCallback(() => {
		navigate(`/${routes.integrations.main}/${routes.integrations.detail}/${integrationId}/${routes.integrations.exchangeConfigs}`);
	}, []);

	return (
		<Button icon={<RiAddCircleLine/>}
				style={{ maxWidth: 'fit-content' }}
				onClick={createExchangeConfig}
		>
			Создать новую конфигурацию обмена
		</Button>
	);
});

export {
	CreateExchangeConfigButton,
};
