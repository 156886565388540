import {
	RiArrowDownSLine,
	RiArrowRightLine,
	RiArrowUpSLine,
	RiLink,
	RiSettings4Line,
} from '@remixicon/react';
import { Collapse, Flex, List, PaginationProps, Result } from 'antd';
import React, { memo, ReactNode, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { CompatibilityUrl, StartSearchCompatibilitiesButton } from 'features/compatibilities';
import { DeleteCompatibilities } from 'features/compatibilities/DeleteCompatibilitiesOption';
import { DeleteCompatibilityItems } from 'features/compatibilities/DeleteCompatibilityItems';
import { useGetCompatibilityItemsQuery } from 'shared/api/generatedApi/mdmgApi';
import { routes } from 'shared/config';
import { useHandleQueryError, useTypedTranslation } from 'shared/hooks';
import { colors } from 'shared/styles';
import { DropdownLink, ItemActions } from 'shared/ui';
import { CompatibilitiesItemsCreationWidget } from '../CompatibilitiesItemsCreationWidget';
import s from './CompatibilitiesOption.module.scss';

type ICompatibilitiesEntityType = 'CATALOG_ITEM' | 'CLASSIFIER_ITEM' | 'CATALOG' | 'CLASSIFIER';

interface ICompatibilitiesOption {
	id: string;
	direction: 'REVERSE' | 'STRAIGHT';
	firstEntity: {
		id: string;
		type: ICompatibilitiesEntityType;
		displayName?: string;
	};
	secondEntity: {
		id: string;
		type: ICompatibilitiesEntityType;
		displayName?: string;
	};
	contextName: {
		id: string;
		contextDisplayName: string;
		inverseContextDisplayName: string;
		leftEntityName: string;
		rightEntityName: string;
	};
}

const panelStyle: React.CSSProperties = {
	borderRadius: 6,
	border: 'solid 1px #e0e0e0',
	alignItems: 'center',
};

const CompatibilitiesOption = memo((
	{
		id,
		direction,
		firstEntity,
		secondEntity,
		contextName,
	}: ICompatibilitiesOption) => {
	const { t } = useTypedTranslation();
	const navigate = useNavigate();

	const [ isCollapsible, setIsCollapsible ] = useState<boolean>(false);
	const [ isActive, setIsActive ] = useState<boolean>(false);

	const labelComposer = (
		contextDisplayName: string,
		oppositeEntityName: string,
		leftEntity: any,
		rightEntity: any,
	) => {
		setIsCollapsible(
			leftEntity.type === 'CLASSIFIER_ITEM' || leftEntity.type === 'CATALOG_ITEM',
		);
		return (
			<Flex gap={11} align="center" style={{ width: '100%' }}>
				<Flex gap={6} align="center">
					<strong style={{ color: 'var(--text)' }}>
						{contextDisplayName}
					</strong>
					<RiArrowRightLine size={16} color={colors.warning}/>
					<strong style={{ color: 'var(--text)' }}>
						{oppositeEntityName}
					</strong>
				</Flex>
				<div onClick={(e) => e.stopPropagation()}>
					<CompatibilityUrl id={rightEntity.id}
									  title={rightEntity.displayName}
									  type={rightEntity.type}
					/>
				</div>
			</Flex>
		);
	};

	const label = useMemo(() => {
		return direction === 'STRAIGHT'
			? labelComposer(
				contextName.contextDisplayName,
				contextName.rightEntityName,
				firstEntity,
				secondEntity,
			)
			: direction === 'REVERSE'
				? labelComposer(
					contextName.inverseContextDisplayName,
					contextName.leftEntityName,
					secondEntity,
					firstEntity,
				)
				: '';
	}, [ direction, firstEntity, secondEntity, contextName ]);

	const dropDownItems = useMemo(() => [
		{
			key: 'search',
			label: (
				<StartSearchCompatibilitiesButton compatibilityId={id}
												  isDropdown
				/>
			),
		},
		{
			label: (
				<CompatibilitiesItemsCreationWidget
					compatibilityId={id}
					currentEntityId={direction === 'STRAIGHT' ? firstEntity.id : secondEntity.id}
				/>
			),
			key: '0',
		},
		{
			label: (
				<DropdownLink
					title={t((l) => l.compatibilities.actions.setup)}
					icon={<RiSettings4Line size={18} color={colors.primary}/>}
					onClick={() => navigate(`/${routes.compatibilities.main}/${id}`)}
				/>
			),
			key: '1',
		},
		{
			label: <DeleteCompatibilities ids={[ id ]}/>,
			key: '2',
		},
	], [ id, direction, firstEntity, secondEntity ]);

	const extra = (
		<Flex align="center" gap={6} onClick={(e) => e.stopPropagation()}>
			<ItemActions items={dropDownItems} placement="bottomRight"/>
		</Flex>
	);

	// Список

	const [ paginationArg, setPaginationArg ] = useState<{ current: number; pageSize: number }>({
		current: 1,
		pageSize: 5,
	});

	const { data, error, isFetching } = useHandleQueryError(
		useGetCompatibilityItemsQuery(
			{
				page: paginationArg?.current,
				limit: paginationArg?.pageSize,
				itemId: direction === 'STRAIGHT'
					? firstEntity.id
					: secondEntity.id,
				contextNameId: contextName.id,
				contextNameDirection: direction,
			},
			{ skip: !isActive },
		),
		(l) => l.compatibilities.error.gettingItems,
	);

	const pagination: PaginationProps = {
		onChange: (current, pageSize) => {
			setPaginationArg({ current, pageSize });
		},
		size: 'small',
		current: paginationArg.current,
		pageSize: paginationArg.pageSize,
		pageSizeOptions: [ 5, 10, 20, 50 ],
		total: data?.meta?.total,
		showTotal: (total) => `${t((l) => l.common.tables.total)}: ${total}`,
	};

	const children = useMemo(() => {
		return error ? (
			<Result
				status="error"
				title={t((l) => l.compatibilities.error.gettingItems)}
				subTitle={`${error}`}
			/>
		) : (
			<List
				dataSource={data?.data}
				renderItem={(x) => (
					<List.Item key={x.id}>
						<Flex align="center" gap={10} style={{ paddingLeft: 20 }}>
							<Flex align="cwnter" gap={4}>
								<RiLink size={16} color={colors.primary}/>
								<span style={{ color: 'var(--primary)', fontWeight: 700 }}>
									{x.item.displayName}
								</span>
							</Flex>
							<DeleteCompatibilityItems ids={[ x.item.id ]}/>
						</Flex>
					</List.Item>
				)}
				loading={isFetching}
				pagination={pagination}
			/>
		);
	}, [ error, data, isFetching ]);

	const expandIcon = useCallback((props: { isActive?: boolean }): ReactNode => {
		if (!isCollapsible) {
			return;
		}

		return props.isActive ? (
			<RiArrowUpSLine size={24} color={colors.grayIcon}/>
		) : (
			<RiArrowDownSLine size={24} color={colors.grayIcon}/>
		);
	}, [ isCollapsible ]);

	return (
		<Collapse
			items={[
				{
					key: id,
					label: label,
					children: isCollapsible ? children : null,
					style: panelStyle,
					extra: extra,
				},
			]}
			ghost
			size="small"
			className={s.collapse}
			onChange={() => setIsActive((prev) => !prev)}
			expandIcon={expandIcon}
			collapsible={isCollapsible ? 'header' : 'icon'}
		/>
	);
});

export {
	CompatibilitiesOption,
};