import { RiAddCircleLine } from '@remixicon/react';
import { Button, Flex, Form, Input, Modal } from 'antd';
import React, { useState,ReactElement } from 'react';
import { useTypedTranslation } from 'shared/hooks';
import { DropdownLink } from 'shared/ui';
import { useAddNewCatalog } from './AddNewCatalog.model';

interface IAddNewCatalogProps {
	parentId?: string;
	isNested?: boolean;
	isDropdown?: boolean;
	icon?: ReactElement;
	title?: string;
	onCreated?: (id: string) => void
}

const AddNewCatalogUi: React.FC<IAddNewCatalogProps> = ({ parentId, isNested, isDropdown, icon, title, onCreated }) => {
	const { t } = useTypedTranslation();

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [form] = Form.useForm();
	const nameValue = Form.useWatch('displayName', form);
	const { loading, error, addNewCatalog } = useAddNewCatalog();

	const onFinish = async ({ displayName, desc }) => {
		await addNewCatalog(displayName, desc, isNested ? parentId : null, onCreated);
		if (!error) {
			form.resetFields();
			setIsModalOpen(false);
		}
	};

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleOk = () => {
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	return (
		<>
			{isDropdown ? (
				<DropdownLink
					icon={icon}
					title={title}
					onClick={showModal}
				/>
			) : (
				<Button
					icon={<RiAddCircleLine />}
					style={{ maxWidth: 'fit-content' }}
					onClick={showModal}
				>
					{t(l => l.catalogs.groups.addNewCatalog)}
				</Button>
			)}

			<Modal
				title="Добавить новый справочник"
				open={isModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={null}
			>
				<Form layout="vertical" form={form} onFinish={onFinish}>
					<Form.Item name="displayName" label="Наименование справочника" required>
						<Input />
					</Form.Item>
					<Form.Item name="desc" label="Описание">
						<Input />
					</Form.Item>

					<Form.Item>
						<Flex gap={4} justify="flex-end">
							<Button type="text" onClick={handleCancel}>
								{t(l => l.common.buttons.cancel)}
							</Button>
							<Button
								htmlType="submit"
								type="primary"
								loading={loading}
								disabled={!nameValue}
							>
								{t(l => l.common.buttons.create)}
							</Button>
						</Flex>
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
};

export const AddNewCatalog = React.memo(AddNewCatalogUi);
