import { Col, Flex, Spin, Tabs, TabsProps, Typography } from 'antd';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { CatalogItemHistoryWidget, EditCatalogRecord } from 'widgets/Catalogs';
import { CompatibilitiesWidget } from 'widgets/compatibilities';
import { Transactions } from 'widgets/Transactions';
import { clearBreadcrumbs, setBreadcrumbs } from 'entities/breadcrumbs';
import { useCatalogGroups } from 'entities/catalogs/catalogGroups/catalog.model';
import { routes } from 'shared/config';
import { useAppDispatch, useEnumStateViaSearchParam, useTypedTranslation } from 'shared/hooks';
import { DetailFooter } from 'shared/ui';

enum CatalogItemDetailPageTab {
	Values = 'Values',
	Compatibility = 'Compatibility',
	History = 'History',
}

export const RecordDetailPageUi: React.FC = () => {
	const { t } = useTypedTranslation();

	const dispatch = useAppDispatch();
	const { selectedCatalog } = useCatalogGroups();
	const navigate = useNavigate();

	const { recordId, catalogGroupId } = useParams();

	const [ activeTab, setActiveTab ] = useEnumStateViaSearchParam(
		CatalogItemDetailPageTab,
		'tab',
		CatalogItemDetailPageTab.Values,
	);

	const items: TabsProps['items'] = [
		{
			key: CatalogItemDetailPageTab.Values,
			label: t((l) => l.catalogs.records.recordsValues),
			children: <EditCatalogRecord/>,
		},
		{
			key: CatalogItemDetailPageTab.Compatibility,
			label: t((l) => l.compatibilities.tabTitle),
			children: (
				<CompatibilitiesWidget entityId={recordId}
									   entityType="CATALOG_ITEM"
				/>
			),
		},
		{
			key: CatalogItemDetailPageTab.History,
			label: t((l) => l.catalogs.records.historyTabTitle),
			children: (
				<CatalogItemHistoryWidget catalogId={catalogGroupId} catalogItemId={recordId}/>
			),
		},
	];

	useEffect(() => {
		if (selectedCatalog) {
			dispatch(
				setBreadcrumbs({
					displayName: t((l) => l.catalogs.catalogs),
					url: `${routes.catalogs.main}`,
					child: {
						displayName: `${selectedCatalog.displayName}`,
						url: `${routes.catalogs.main}/${selectedCatalog.id}`,
						child: {
							displayName: t((l) => l.catalogs.records.editRecord),
							url: `${routes.catalogs.main}/${selectedCatalog.id}/record/${recordId}`,
						},
					},
				}),
			);
		}
	}, [ selectedCatalog, recordId ]);

	useEffect(() => {
		return () => {
			dispatch(clearBreadcrumbs());
		};
	}, []);

	const handleBackClick = () => {
		navigate(`/${routes.catalogs.main}/${catalogGroupId}`);
	};

	if (!activeTab) {
		return <Spin spinning={true}/>;
	}

	return (
		<>
			<Flex vertical gap={12}>
				<Flex justify="space-between">
					<Flex vertical gap={12} justify="start">
						<Typography.Title level={1}>
							{t((l) => l.catalogs.records.recordCard)}
						</Typography.Title>
					</Flex>

					<Transactions/>
				</Flex>
				<Col xl={24} xs={24} sm={24} md={24} lg={24}>
					<Tabs
						defaultActiveKey={activeTab}
						items={items}
						onChange={(key) => setActiveTab(key as CatalogItemDetailPageTab)}
						destroyInactiveTabPane
					/>
				</Col>
			</Flex>

			<DetailFooter customHandleBack={handleBackClick}/>
		</>
	);
};

export const RecordDetailPage = React.memo(RecordDetailPageUi);
