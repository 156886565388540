import { RiCheckboxCircleLine, RiCircleFill } from '@remixicon/react';
import { Button, Flex, Form, Input, Typography } from 'antd';
import React, { memo, useEffect, useState } from 'react';
import {
	ConfigurationGetResponse,
	ConfigurationStatus,
	ExecutionType, RelationalMetadata, S3Metadata, TopicMetadata,
	useGetMetadataQuery,
} from 'shared/api/generatedApi/integrationsApi';
import { SelectCatalogInput, SelectMultipleCatalogInput } from 'shared/components/SelectCatalogInput';
import { useTypedTranslation } from 'shared/hooks/useTypedTranslation';
import { DropdownSelect } from 'shared/ui';

const StatusLabel = ({ title, color }: { title: string, color: string }) => {
	return (
		<Flex align={'center'} gap={8}>
			<RiCircleFill size={10} color={color}/>
			<span>{title}</span>
		</Flex>
	);
};

interface IEditExchangeConfigInfoProps {
	integrationId: string;
	data?: ConfigurationGetResponse;

	onSubmit?: (state: any) => void;
}

const ExchangeConfigForm = memo((
	{
		integrationId,
		data,
		onSubmit,
	}: IEditExchangeConfigInfoProps,
) => {
	const { t } = useTypedTranslation();
	const [ form ] = Form.useForm();

	const executionType = Form.useWatch('execution_type', form);
	const replicationDirection = Form.useWatch('replication_direction', form);

	const [ extractionTargetOptions, setExtractionTargetOptions ] = useState<any[] | null>();

	const { data: metadata } = useGetMetadataQuery({
		connectionId: integrationId,
	});

	useEffect(() => {
		if (data) {
			const { execution_type, schedule_interval } = data.activation_mode;
			form.setFieldsValue({ ...data, execution_type, schedule_interval });
		}
	}, [ data ]);

	useEffect(() => {
		if (!metadata) {
			return;
		}
		if (metadata.source_type === 'postgres') {
			const { tables } = metadata.metadata as RelationalMetadata;
			setExtractionTargetOptions(Object.keys(tables).map((key) => ({
				label: key,
				value: key,
			})));
		} else if (metadata.source_type === 'kafka') {
			const { topics } = metadata.metadata as TopicMetadata;
			setExtractionTargetOptions(topics.map((key) => ({
				label: key,
				value: key,
			})));
		} else if (metadata.source_type === 's3') {
			const { buckets } = metadata.metadata as S3Metadata;
			setExtractionTargetOptions(buckets.map((key) => ({
				label: key,
				value: key,
			})));
		}
	}, [ metadata ]);

	const executionTypeOpts: Array<{ label: string; value: ExecutionType }> = [
		{
			label: t(l => l.integrations.exchangeConfig.form.executionTypes.scheduled),
			value: 'scheduled',
		},
		{
			label: t(l => l.integrations.exchangeConfig.form.executionTypes.manual),
			value: 'manual',
		},
		{
			label: t(l => l.integrations.exchangeConfig.form.executionTypes.manualImmediate),
			value: 'manual_immediate',
		},
	];

	const statusOpts: Array<{ label: React.ReactElement; value: ConfigurationStatus }> = [
		{
			label: (
				<StatusLabel title={t(l => l.integrations.exchangeConfig.form.statuses.inactive)}
							 color={'var(--error)'}
				/>
			),
			value: 'inactive',
		},
		{
			label: (
				<StatusLabel title={t(l => l.integrations.exchangeConfig.form.statuses.active)}
							 color={'var(--success)'}/>
			),
			value: 'active',
		},
	];

	const onDeleteActionOptions = [
		{
			label: t(l => l.integrations.exchangeConfig.form.onDeleteActions.foo),
			value: 'foo',
		},
		{
			label: t(l => l.integrations.exchangeConfig.form.onDeleteActions.bar),
			value: 'bar',
		},
		{
			label: t(l => l.integrations.exchangeConfig.form.onDeleteActions.baz),
			value: 'baz',
		},
	];

	const replicationDirectionOptions = [
		{
			label: t(l => l.integrations.exchangeConfig.form.replicationDirections.import),
			value: 'import',
		},
		{
			label: t(l => l.integrations.exchangeConfig.form.replicationDirections.export),
			value: 'export',
		},
	];

	return (
		<Form layout="vertical" form={form} onFinish={onSubmit}>

			<Flex vertical gap={24}>
				<div>
					<Typography.Title level={2} style={{ marginBottom: 12 }}>
						Настройки конфигурации обмена
					</Typography.Title>


					<Form.Item name="name"
							   label={t(l => l.integrations.exchangeConfig.form.name)}
							   required
					>
						<Input/>
					</Form.Item>
					<Form.Item name="description"
							   label={t(l => l.integrations.exchangeConfig.form.description)}
					>
						<Input/>
					</Form.Item>
					<Form.Item name="on_delete_action"
							   label={t(l => l.integrations.exchangeConfig.form.onDeleteAction)}
					>
						<DropdownSelect options={onDeleteActionOptions}/>
					</Form.Item>
					<Form.Item name="status"
							   label={t(l => l.integrations.exchangeConfig.form.status)}
					>
						<DropdownSelect options={statusOpts}/>
					</Form.Item>
					<Form.Item name="replication_direction"
							   label={t(l => l.integrations.exchangeConfig.form.replicationDirection)}
					>
						<DropdownSelect disabled={!!data}
										options={replicationDirectionOptions}
						/>
					</Form.Item>
					<Form.Item name="execution_type"
							   label={t(l => l.integrations.exchangeConfig.form.executionType)}
					>
						<DropdownSelect options={executionTypeOpts}/>
					</Form.Item>
					{executionType === 'scheduled' && (
						<Form.Item name="schedule_interval"
								   label={t(l => l.integrations.exchangeConfig.form.scheduleInterval)}
						>
							<Input
								placeholder={t(l => l.integrations.exchangeConfig.form.scheduleIntervalPlaceholder)}/>
						</Form.Item>
					)}
				</div>

				{!!replicationDirection && (
					<div>
						<Typography.Title level={2}
										  style={{ marginBottom: 12 }}
						>
							{t(l => l.integrations.exchangeConfig.form.mappingSettings)}
						</Typography.Title>

						{replicationDirection === 'import' && (
							<>
								<Form.Item name="destination"
										   label={t(l => l.integrations.exchangeConfig.form.importDestination)}
								>
									<SelectCatalogInput/>
								</Form.Item>
								<Form.Item name="extraction_targets"
										   label={t(l => l.integrations.exchangeConfig.form.importSource)}
										   required
								>
									<DropdownSelect mode="multiple"
													options={extractionTargetOptions}
									/>
								</Form.Item>
							</>
						)}

						{replicationDirection === 'export' && (
							<>
								<Form.Item name="extraction_targets"
										   label={t(l => l.integrations.exchangeConfig.form.exportDestination)}
								>
									<SelectMultipleCatalogInput/>
								</Form.Item>
								<Form.Item name="destination"
										   label={t(l => l.integrations.exchangeConfig.form.exportSource)}
										   required
								>
									<DropdownSelect options={extractionTargetOptions}
									/>
								</Form.Item>
							</>
						)}
					</div>
				)}

				<Button icon={<RiCheckboxCircleLine/>}
						style={{ width: '100%' }}
						htmlType="submit"
				>
					{t(l => l.integrations.exchangeConfig.form.confirmButton)}
				</Button>
			</Flex>
		</Form>
	);
});

export {
	ExchangeConfigForm,
};
