import { RiAddCircleLine } from '@remixicon/react';
import { Col, Flex, Tabs, TabsProps, Typography } from 'antd';
import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { AttributesWidget } from 'widgets/Catalogs/AttributesListWidget';
import { CatalogDeduplicationWidget } from 'widgets/Catalogs/CatalogDeduplicationWidget';
import { CatalogRestrictionsWidget } from 'widgets/Catalogs/CatalogRestrictionsWidget';
import { CompatibilitiesWidget } from 'widgets/compatibilities';
import { Transactions } from 'widgets/Transactions';
import { AddNewCatalog } from 'features/catalogs/AddNewCatalog';
import { DeleteCatalog } from 'features/catalogs/DeleteCatalog';
import { EditCatalogMainInfo } from 'features/catalogs/editCatalogMainInfo';
import { clearBreadcrumbs, setBreadcrumbs } from 'entities/breadcrumbs';
import { useCatalogGroups } from 'entities/catalogs/catalogGroups/catalog.model';
import { routes } from 'shared/config';
import { useAppDispatch, useEnumStateViaSearchParam, useTypedTranslation } from 'shared/hooks';
import { DetailFooter, setSubMenuCollapsed } from 'shared/ui';

enum CatalogGroupPageTab {
	MainInfo = 'MainInfo',
	Attributes = 'Attributes',
	Restrictions = 'Restrictions',
	Deduplication = 'Deduplication',
	Compatibilities = 'Compatibilities',
}

export const CatalogGroupPage: React.FC = () => {
	const { t } = useTypedTranslation();

	const [ activeKey, setActiveKey ] = useEnumStateViaSearchParam(
		CatalogGroupPageTab,
		'tab',
		CatalogGroupPageTab.MainInfo,
	);

	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const { selectedCatalog, loading, error } = useCatalogGroups();
	const { catalogGroupId } = useParams();

	const items: TabsProps['items'] = [
		{
			key: CatalogGroupPageTab.MainInfo,
			label: t((l) => l.catalogs.mainInfo),
			children: (
				<EditCatalogMainInfo catalogId={catalogGroupId} loading={loading} error={error}/>
			),
		},
		{
			key: CatalogGroupPageTab.Attributes,
			label: t((l) => l.attributes.attributesSetup),
			children: <AttributesWidget/>,
		},
		{
			key: CatalogGroupPageTab.Restrictions,
			label: t((l) => l.catalogs.restrictions.restrictions),
			children: <CatalogRestrictionsWidget catalogId={catalogGroupId}/>,
		},
		{
			key: CatalogGroupPageTab.Deduplication,
			label: t((l) => l.catalogs.deduplication.main),
			children: <CatalogDeduplicationWidget/>,
		},
		{
			key: CatalogGroupPageTab.Compatibilities,
			label: t((l) => l.compatibilities.tabTitle),
			children: (
				<CompatibilitiesWidget entityId={catalogGroupId}
									   entityType="CATALOG"
				/>
			),
		},
	];

	// TODO переделать на роут
	const { defaultTabKey } = location.state?.defaultTabKey
		? location.state
		: { defaultTabKey: null };

	useEffect(() => {
		if (selectedCatalog) {
			dispatch(
				setBreadcrumbs({
					displayName: t((l) => l.catalogs.catalogs),
					url: `${routes.catalogs.main}`,
					child: {
						displayName: `${selectedCatalog.displayName}`,
						url: `${routes.catalogs.main}/${selectedCatalog.id}`,
						child: {
							displayName: t((l) => l.catalogs.catalogInfo),
							url: `${routes.catalogs.main}/${selectedCatalog.id}/edit`,
						},
					},
				}),
			);
		}
	}, [ selectedCatalog ]);

	useEffect(() => {
		return () => {
			dispatch(clearBreadcrumbs());
			dispatch(setSubMenuCollapsed(false));
		};
	}, []);

	const handleBackClick = () => {
		navigate(`/${routes.catalogs.main}/${catalogGroupId}`);
	};

	const onCreated = (id) => {
		navigate(`/${routes.catalogs.main}/${id}`);
	};

	return (
		<>
			<Flex vertical gap={12} style={{ marginBottom: 65 }}>
				<Flex justify="space-between">
					<Flex vertical gap={12} justify="start">
						<Typography.Title level={1}>
							{selectedCatalog && selectedCatalog.displayName}
						</Typography.Title>
					</Flex>

					<Transactions/>
				</Flex>
				<Col xl={18} xs={24} sm={24} md={24} lg={18}>
					<Tabs
						items={items}
						destroyInactiveTabPane
						defaultActiveKey={defaultTabKey}
						activeKey={activeKey}
						onChange={(key) => setActiveKey(key as CatalogGroupPageTab)}
					/>
				</Col>
			</Flex>

			<DetailFooter customHandleBack={handleBackClick}>
				<Flex>
					<DeleteCatalog id={catalogGroupId}/>
					<AddNewCatalog isDropdown
								   icon={<RiAddCircleLine/>}
								   title={t(l => l.catalogs.groups.addNewCatalog)}
								   onCreated={onCreated}/>
				</Flex>
			</DetailFooter>
		</>
	);
};
