import { App, Flex, Spin, Typography } from 'antd';
import React, { useCallback, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router';
import { Transactions } from 'widgets/Transactions';
import { UsingContextName, DeleteContextNames, EditContextNameForm } from 'features/compatibilities';
import { EditContextNameFormPublicApi } from 'features/compatibilities/EditContextNameForm';
import { setBreadcrumbs } from 'entities/breadcrumbs';
import { useGetContextNameQuery, useUpdateContextNameMutation } from 'shared/api/generatedApi/mdmgApi';
import { transactionServiceApi } from 'shared/api/generatedApi/transactionServiceApi';
import { routes } from 'shared/config';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch, useTypedTranslation } from 'shared/hooks';
import { DetailFooter } from 'shared/ui';

export const ContextNameCardPage = () => {

	const { t } = useTypedTranslation();
	const dispatch = useAppDispatch();
	const { notification } = App.useApp();

	const { contextNameId } = useParams();
	const navigate = useNavigate();

	const formRef = useRef<EditContextNameFormPublicApi>();

	const {
		data: contextName,
		isFetching: isFetchingContextName,
	} = useGetContextNameQuery({
		id: contextNameId,
	});

	const [ updateContextName ] = useUpdateContextNameMutation();

	const editCompatibilityItem = useCallback(async () => {
		try {
			const form = formRef.current.getFormData();

			await updateContextName({
				id: contextName.id,
				updateContextNameRequest: {
					...form,
				},
			});
		} catch (error) {
			errorHelper(
				t((l) => l.compatibilities.error.editingCompatibilityItem),
				error,
				notification,
			);
		} finally {
			dispatch(transactionServiceApi.util.invalidateTags([ 'Transaction' ]));
		}
	}, [ contextName ]);

	const handleNavigate = () =>
		navigate(-1);

	useEffect(() => {
		if (contextName) {
			dispatch(
				setBreadcrumbs({
					displayName: t((l) => l.breadcrumbs.metadata),
					url: `${routes.metadata.main}/${routes.metadata.compatibilities}`,
					child: {
						displayName: `${t((l) => l.compatibilities.contextNames.cardTitle)}`,
						url: `${routes.metadata.main}/${routes.metadata.compatibilities}/${contextName.id}`,
					},
				}),
			);
		}
	}, [ contextName ]);

	return (
		<Spin spinning={isFetchingContextName}>
			<Flex vertical gap={24}>
				<Flex justify="space-between">
					<Typography.Title level={1}>
						{t((l) => l.compatibilities.contextNames.cardTitle)}
					</Typography.Title>

					<Transactions/>
				</Flex>

				<Flex gap={12} vertical>
					<Typography.Title level={2}>
						{t((l) => l.compatibilities.mainInfo)}
					</Typography.Title>

					<EditContextNameForm ref={formRef}
										 item={contextName}
										 onBlur={editCompatibilityItem}
					/>
				</Flex>

				<UsingContextName contextNameId={contextNameId}/>

				<DetailFooter customHandleBack={handleNavigate}>
					<DeleteContextNames detailItemPage itemId={[ contextNameId ]}/>
				</DetailFooter>
			</Flex>
		</Spin>
	);
};
